import React, { useState } from "react";
import { Filter, cloneFilter } from "../../Filters";
import {
  ButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { useTranslation } from "react-i18next";

export interface AddFilterProps {
  possibleFilters: Filter[];
  onChange: (filter: Filter) => void;
}

export default function AddFilter(props: AddFilterProps) {
  const { t } = useTranslation();
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  const onAdd = (index: number) => (ev: any) => {
    ev.preventDefault();
    props.onChange(cloneFilter(props.possibleFilters[index]));
  };

  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle color="none" className="text-primary">
        {t("Add Filter")}
      </DropdownToggle>
      <DropdownMenu>
        {props.possibleFilters.map((filter, index) => (
          <DropdownItem key={index} onClick={onAdd(index)}>
            {filter.display}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </ButtonDropdown>
  );
}
