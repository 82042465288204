import React from "react";
import styles from "./Thumbnail.module.css";

export enum ThumbnailSize {
  xsmall = "xsmall",
  small = "small",
  smallmedium = "smallmedium",
  medium = "medium",
}

export const SizeMap: Record<ThumbnailSize, number> = {
  xsmall: 40,
  small: 80,
  smallmedium: 120,
  medium: 180,
};

export interface ThumbnailProps {
  size?: ThumbnailSize;
  src?: string | null;
}

export default function Thumbnail(props: ThumbnailProps) {
  const size = props.size ? SizeMap[props.size] : 400;
  return (
    <div
      style={{
        height: size,
        width: size,
        minHeight: size,
        minWidth: size,
        maxHeight: size,
        maxWidth: size,
      }}
    >
      {props.src && (
        <img alt="thumbnail" src={props.src} className={styles.thumbnail} />
      )}
    </div>
  );
}
