import { Group } from "../../../Config/datamodels/interfaces";

export default function carcheckListHeaders(
  t: (s: string) => string,
  groups: string[]
): string[] {
  const headers = [
    t("Type of Carcheck"),
    t("Date"),
    t("Vehicle Plate"),
    t("Vehicle Vin"),
    t("Mileage"),
    t("Fuel Level"),
    t("Damages present"),
    t("Budget"),
    t("tyres"),
  ];
  if (groups.includes(Group.BACKOFFICE_USERS)) {
    headers.splice(0, 0, "id");
  }
  return headers;
}
