import { useTranslation } from "react-i18next";
import { TextField } from "../../Common/Store/FormReducer/Fields";

const useFormFields = () => {
  const { t } = useTranslation();

  return {
    name: new TextField("name", t("department name"), t("department's name")),
  };
};

export default useFormFields;
