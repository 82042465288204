import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Path } from "../../../Config/Router/utils";
import useAuthViewModel from "../../viewModel/authViewModel";
import { Button, Label, Input } from "reactstrap";
import ErrorHandler from "../../components/ErrorHandler";

// *---- Styles ----
import styles from "./LoginScreen.module.css";

// ---- Constant ----

export default function LoginScreen() {
  /**
   * Main react login component.
   *
   */

  // *---- Setup ----
  const { error, loading, login, setActivate } = useAuthViewModel();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function onChangeEmail(event: any) {
    setEmail(event.target.value);
  }

  function onChangePassword(event: any) {
    setPassword(event.target.value);
  }

  useEffect(() => {
    setActivate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.screen_wrapper}>
      <div className={styles.container}>
        <div className={styles.left_form_container}>
          <img
            className={styles.logo}
            src="/images/checkly-logo.png"
            alt="company logo"
          />
          <p className={styles.title}>{t("Log in.")}</p>
          <p className={styles.description}>
            {t("Log in to acess your Checkly dashboard")}
          </p>
          <Label for="exampleEmail">Email</Label>
          <Input
            disabled={loading}
            onChange={onChangeEmail}
            className={styles.input}
            type="email"
            name="email"
            placeholder={t("name@domain.be")}
            value={email}
          />
          <Label for="examplePassword">Password</Label>
          <Input
            disabled={loading}
            onChange={onChangePassword}
            className={styles.input}
            type="password"
            name="password"
            id="examplePassword"
            value={password}
            placeholder={t("your secret key")}
          />
          <Button
            onClick={async () => await login({ email, password })}
            disabled={loading}
            className={styles.button}
          >
            {t("Log in")}
          </Button>
          <ErrorHandler>{error}</ErrorHandler>
          <a className={styles.anchor_tag} href={Path.RESET}>
            <p className={styles.forgot_password}>{t("Forgot password ?")}</p>
          </a>
        </div>
        <div className={styles.right_image_container}>
          <div className={styles.linear_overlay}></div>
        </div>
      </div>
    </div>
  );
}
