// *---- Imports ----
import React from "react";
import { ThumbnailProps, SizeMap } from "../Thumbnail";
import styles from "./SquareThumbnail.module.css";

export interface SquareThumbnailProps extends ThumbnailProps {}

export default function SquareThumbnail(props: SquareThumbnailProps) {
  const size = props.size ? SizeMap[props.size] : 400;
  return (
    <div
      style={{
        height: size,
        width: size,
        minHeight: size,
        minWidth: size,
        maxHeight: size,
        maxWidth: size,
      }}
    >
      {props.src && (
        <img alt="thumbnail" src={props.src} className={styles.thumbnail} />
      )}
    </div>
  );
}
