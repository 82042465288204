import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import FormView from "../../../Common/Components/FormView";
import useFormViewModel, {
  requestInterceptor,
  responseInterceptor,
} from "../../viewModel/formViewModel";
import { User } from "@sentry/react";
import { UserFormFieldsName } from "../../viewModel/types";
import useValidation from "../FormScreen/useValidation";

export default function () {
  const { t } = useTranslation();
  const { id } = useParams<any>();
  const formData = useFormViewModel(
    id,
    requestInterceptor,
    responseInterceptor
  );
  const validation = useValidation();
  return (
    <FormView<User, UserFormFieldsName>
      validation={validation}
      //@ts-ignore
      formData={formData}
      description=""
      title={t("Update User")}
      validateButtonText={t("Update User")}
      shouldHideReset
    />
  );
}
