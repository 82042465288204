import _ from "lodash";
import { Carcheck } from "../../Config/datamodels/interfaces";

function updateDamageInCarcheck(
  carcheck: Carcheck,
  damageId: number,
  partialDamageBody: any,
  partId: number
) {
  if (!carcheck.damage_per_part)
    throw new Error("carcheck has no damage per part");
  const partIndex = _.findIndex(
    carcheck.damage_per_part,
    (part) => part.id === partId
  );
  if (partIndex === -1) throw new Error("Part could not be found");
  const parts = carcheck.damage_per_part;
  const part = parts[partIndex];
  const damages = part.damages;
  const damageIndex = _.findIndex(damages, (damage) => damage.id === damageId);
  if (damageIndex === -1) throw new Error("Damage could not be found");
  // @ts-ignore
  const newDamagesPerPart = listDeepUpdate(
    damages,
    damageIndex,
    partialDamageBody
  );
  // @ts-ignore
  const newParts = listDeepUpdate(parts, partIndex, {
    damages: newDamagesPerPart,
  });
  return { ...carcheck, damage_per_part: newParts };
}

function listDeepUpdate(listElements: any[], position: number, value: any) {
  const newElement = { ...listElements[position], ...value };
  listElements[position] = newElement;
  return [...listElements];
}

export default updateDamageInCarcheck;
