import Axios from "axios";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";
import makeRESTUpdateService from "../../Common/Services/UpdateService";
import { Rules } from "../../Config/datamodels/interfaces";

const updateService = makeRESTUpdateService<Rules>(
  "rules",
  buildRESTUrl,
  Axios
);
export default updateService;
