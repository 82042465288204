import Axios from "axios";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";
import makeRESTCreateService from "../../Common/Services/CreateService";
import { CarcheckRequest } from "../../Config/datamodels/interfaces";

const createService = makeRESTCreateService<CarcheckRequest>(
  "requests",
  buildRESTUrl,
  Axios
);
export default createService;
