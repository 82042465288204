import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import styles from "./DeleteButton.module.css";

export interface DeleteButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

export function DeleteButton(props: DeleteButtonProps) {
  const { t } = useTranslation();
  return (
    <Button
      disabled={props.disabled}
      color="danger"
      onClick={props.onClick}
      className={styles.button}
    >
      {t("delete")}
    </Button>
  );
}
