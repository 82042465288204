import React from "react";
import FormView from "../../../Common/Components/FormView";
import { useTranslation } from "react-i18next";
import useFormViewModel from "../../viewModel/formViewModel";
import useValidation from "./useValidation";
import { CarcheckRequest } from "../../../Config/datamodels/interfaces";
import { CarcheckRequestFields } from "../../viewModel/types";
import { FormRequestInterceptor } from "../../../Common/ViewModels/makeUseFormViewModel";
import _ from "lodash";

export interface FormViewProps {
  vehicleId?: number;
  afterSubmit?: () => Promise<void>;
}

const makeRequestInterceptor = (
  vehicleId: number | string
): FormRequestInterceptor<CarcheckRequest> => async (body: any) => {
  return { ...body, vehicle: vehicleId, user: _.get(body, "user.value") };
};

export default function CarcheckRequestForm(props: FormViewProps) {
  const { t } = useTranslation();
  const interceptor = props.vehicleId
    ? makeRequestInterceptor(props.vehicleId)
    : undefined;
  const formData = useFormViewModel(undefined, interceptor);
  const validation = useValidation();

  return (
    <FormView<CarcheckRequest, CarcheckRequestFields>
      formData={formData}
      validateButtonText={t("Create new request")}
      title={t("New Carcheck Request")}
      description={t(
        "Please specify when and which carcheck should be performed on the vehicle"
      )}
      afterSubmit={props.afterSubmit}
      validation={validation}
      modelName="requests"
    />
  );
}
