import React from "react";
import ImportView from "../../../Common/Components/ImportView";
import { Vehicle } from "../../../Config/datamodels/interfaces";
import { useTranslation } from "react-i18next";
import importViewmodel from "../../viewModel/importViewModel";

export default function () {
  const importData = importViewmodel();
  const { t } = useTranslation();
  return (
    <ImportView<Vehicle>
      title={t("Vehicles")}
      description={t(
        "Upload your excel file to import multiple vehicle at once"
      )}
      importData={importData}
      exampleFilePath="/examples/vehicles.xlsx"
    />
  );
}
