import React from "react";
import { useState } from "react";
import { createContext, PropsWithChildren } from "react";
import ConfirmationModal from "../../Common/Components/ConfirmationModal";

export const ConfirmationModalContext = createContext({
  open: (message: string, confirmCallback: () => void) => {},
});

export default function ConfirmationModalGate(props: PropsWithChildren<any>) {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  // confirmation callback needs to be wrapped
  // otherwise function is called during setState
  const [onConfirm, setOnConfirm] = useState<any>({});

  const close = () => setIsOpen(false);

  const open = (msg: string, confirmCallback: () => void) => {
    setIsOpen(true);
    setMessage(msg);
    setOnConfirm({
      cb: () => {
        confirmCallback();
        close();
      },
    });
  };

  return (
    <ConfirmationModalContext.Provider
      value={{
        open,
      }}
    >
      {props.children}
      <ConfirmationModal
        message={message}
        isOpen={isOpen}
        onConfirm={onConfirm.cb}
        close={close}
      />
    </ConfirmationModalContext.Provider>
  );
}
