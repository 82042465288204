import React from "react";
import { Partner, Group } from "../../../Config/datamodels/interfaces";
import ListView from "../../../Common/Components/ListView";
import { Path } from "../../../Config/Router/utils";
import ListItem from "./ListItem";
import { useTranslation } from "react-i18next";
import useAuthViewModel from "../../../auth/viewModel/authViewModel";
import useFields from "./useFields";
import listViewModel from "../../viewModel/listViewModel";

export default function ListScreen() {
  const listData = listViewModel();
  const { filterableFields, orderableFields } = useFields();
  const { t } = useTranslation();
  const { groups } = useAuthViewModel();
  const headers = [t("Name"), ""];
  if (groups.includes(Group.BACKOFFICE_USERS)) {
    headers.splice(0, 0, "id");
  }
  return (
    <ListView<Partner>
      title={t("Partners")}
      basePath={Path.PARTNERS}
      emptyListTitle={t("Add you first partner")}
      emptyListDescription={t(
        "Partners are leasing companies you are working with. Once created you can filter vehicles per partner on other ressources like Vehicles or Requests."
      )}
      navigationFormButtonLabel={t("Create New Partner")}
      listData={listData}
      headers={headers}
      renderLine={(partner: Partner) => (
        <ListItem partner={partner} onDelete={listData.deleteById} />
      )}
      availableFilters={filterableFields}
      orderableFields={orderableFields}
    />
  );
}
