// *---- Imports ----
import React from "react";
import {
  Carcheck,
  CarcheckType,
} from "../../../../../Config/datamodels/interfaces";
import { useTranslation } from "react-i18next";
// *---- Style ----
import styles from "./Courtoise.module.css";

// *---- Components ----
import Images from "../../../../../Common/Components/Images";
import Card from "../../../../../Common/Components/Card";
import KeyValueText from "../../../../../Common/Components/KeyValueText";
import { isUndefined } from "lodash";
import { noopIfUndefined } from "../../../../../Utils/noopIfUndefined";
import { TyreTypeSection } from "../TyreTypeSection";
import { TyresStateSection } from "../TyresStateSection";
import { InteriorCleanSection } from "../InteriorCleanSection";
import { ExteriorCleanSection } from "../ExteriorCleanSection";
import { KeysSection } from "../KeysSection";
import { FuelLevelSection } from "../FuelLevelSection";
import { IfCarcheck } from "../../../../../Common/Components/IfCarcheck";

export interface CourtoisieProps {
  carcheck: Carcheck;
  disable?: boolean;
  updateCarcheck?: (tyreFieldName: string, value: any) => void;
}

export default function Courtoisie({
  carcheck,
  updateCarcheck,
  disable,
}: CourtoisieProps) {
  const { t } = useTranslation();
  const update = noopIfUndefined(updateCarcheck);
  return (
    <Card>
      <div className={styles.container}>
        <Images
          title={t("Vehicle documents and state")}
          images={[
            carcheck.legal_document_picture,
            carcheck.dashboard_picture,
            carcheck.exterior_cleanliness_picture,
            carcheck.interior_cleanliness_picture,
          ]}
        />
        <div className={styles.keyValueContainer}>
          <InteriorCleanSection
            disable={disable}
            carcheck={carcheck}
            onChange={update}
          />
          <ExteriorCleanSection
            disable={disable}
            carcheck={carcheck}
            onChange={update}
          />
          <IfCarcheck
            types={[
              CarcheckType.EXHAUSTIVE,
              CarcheckType.PERIODIC,
              CarcheckType.DAMAGE_CHECK,
              CarcheckType.TYRES_CHECK,
              CarcheckType.RENTA,
              CarcheckType.POOL_CHECK,
            ]}
            currentType={carcheck.carcheck_type}
          >
            <KeyValueText
              shouldWrap
              title={t("Vehicle Mileage")}
              hide={isUndefined(carcheck?.mileage)}
            >
              {carcheck.mileage?.toString()}
            </KeyValueText>
          </IfCarcheck>
          <KeysSection
            disable={disable}
            carcheck={carcheck}
            onChange={update}
          />
          <FuelLevelSection
            disable={disable}
            carcheck={carcheck}
            onChange={update}
          />
          <TyreTypeSection
            disable={disable}
            onChange={update}
            carcheck={carcheck}
          />
          <KeyValueText shouldWrap title={t("Day published")}>
            {new Date(carcheck.done_datetime).toLocaleDateString()}
          </KeyValueText>
        </div>
        <TyresStateSection
          disable={disable}
          onChange={update}
          carcheck={carcheck}
        />
      </div>
    </Card>
  );
}
