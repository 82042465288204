import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { Path } from "../../../Config/Router/utils";
import {
  Group,
  CarcheckListEntry,
} from "../../../Config/datamodels/interfaces";
import Highlight from "../../../Common/Components/Highlight";
import Card from "../../../Common/Components/Card";
import KeyValueText from "../../../Common/Components/KeyValueText";
import ElementWrapper from "../../../Common/Components/ElementWrapper";
import DocumentLink from "../../../Common/Components/DocumentLink";
import Header from "../../../Common/Components/Header";
import ListView from "../../../Common/Components/ListView";
import RequestFormModal from "./RequestFormModal";
import IfGroup from "../../../auth/components/IfGroup";
import carcheckListHeaders from "../../../carchecks/screens/ListScreen/listHeaders";
import renderCarcheck from "../../../carchecks/screens/ListScreen/ListItem";
import { Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import DocumentScreenBody from "../../../Common/Components/DocumentScreenBody";
import VehiclePictureInfoCard from "../../../Common/Components/VehiclePictureInfoCard";
import useFields from "../../../carchecks/screens/ListScreen/useFields";

// *---- Style ----
import styles from "./DocumentScreen.module.css";
import detailViewModel from "../../viewModel/detailViewModel";
import useAuthViewModel from "../../../auth/viewModel/authViewModel";
import useVehicleCarcheckListViewModel from "../../viewModel/vehicleCarcheckViewModel";
import useSelectorViewModel from "../../../reporting/viewModel/selectorViewModel";
import useDeltaViewModel from "../../../reporting/viewModel/deltaViewModel";

interface Params {
  id: string;
}

function CarcheckListView(props: { vehicleId: number }) {
  const listData = useVehicleCarcheckListViewModel(props.vehicleId);
  const { filterableFields, orderableFields } = useFields();
  const { groups } = useAuthViewModel();
  const { t } = useTranslation();
  return (
    <ListView<CarcheckListEntry>
      title={t("Carchecks")}
      listData={listData}
      headers={carcheckListHeaders(t, groups)}
      renderLine={renderCarcheck}
      availableFilters={filterableFields}
      orderableFields={orderableFields}
    />
  );
}

export default function DocumentScreen() {
  const { t } = useTranslation();
  let params: Params = useParams();
  const { data, get, isLoading } = detailViewModel(params.id);
  const reportingViewModel = useSelectorViewModel(params.id, "");
  const deltaViewModel = useDeltaViewModel();
  const history = useHistory();
  useEffect(() => {
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  if (isLoading || !data) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner type="grow" color="primary" />
      </div>
    );
  }

  return (
    <div className={styles.screenContainer}>
      <Header title={t("Vehicle")}>
        <>
          <IfGroup groups={[Group.BACKOFFICE_USERS, Group.FLEET_MANAGERS]}>
            <RequestFormModal vehicle={data.id} />
          </IfGroup>
          <IfGroup
            groups={[
              Group.BACKOFFICE_USERS,
              Group.FLEET_MANAGERS,
              Group.HR_OFFICERS,
            ]}
          >
            <Button
              className={styles.reportingButton}
              onClick={() => {
                reportingViewModel.clearData();
                deltaViewModel.clear();
                history.push(`${Path.REPORTING}/${data.id}/`);
              }}
              color="secondary"
            >
              {t("Vehicle Report")}
            </Button>
          </IfGroup>
        </>
      </Header>
      <DocumentScreenBody>
        <VehiclePictureInfoCard vehicle={data} />
        <ElementWrapper title={"Leasing"}>
          <Card className={styles.content}>
            <KeyValueText title={t("Contract Mileage")}>
              {"" + data.km_contract || t("N/A")}
            </KeyValueText>
            <KeyValueText title={t("Start")}>
              {data.leasing_start_date}
            </KeyValueText>
            <KeyValueText title={t("End")}>
              {data.leasing_end_date}
            </KeyValueText>
          </Card>
        </ElementWrapper>
        <ElementWrapper title={""}>
          <Card className={styles.content}>
            <KeyValueText title={t("Type")}>{data?.type?.name}</KeyValueText>
            <KeyValueText title={t("Driver")}>
              {data.driver ? (
                <DocumentLink resource="users" id={data.driver.id}>
                  <Highlight>{data.driver.email}</Highlight>
                </DocumentLink>
              ) : (
                <p>{t("N/A")}</p>
              )}
            </KeyValueText>
            <KeyValueText title={t("Creation Date")}>
              {new Date(data.created).toLocaleDateString()}
            </KeyValueText>
            <KeyValueText title={t("Last Update")}>
              {new Date(data.last_updated).toLocaleDateString()}
            </KeyValueText>
            <KeyValueText title={t("Department")}>
              {data.department ? (
                <p>{data.department.name}</p>
              ) : (
                <p>{t("N/A")}</p>
              )}
            </KeyValueText>
            <KeyValueText title={t("Year of circulation")}>
              {data.year ? data.year : t("N/A")}
            </KeyValueText>
          </Card>
        </ElementWrapper>
        <CarcheckListView vehicleId={parseInt(params.id)} />
      </DocumentScreenBody>
    </div>
  );
}
