// *---- Imports ----
import _ from "lodash";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DamageInPart,
  DamageType,
  Group,
  Part,
  PartWithDamage,
} from "../../../../../Config/datamodels/interfaces";
import { isDamageRepairedInGivenCarcheck } from "../../../../../Common/Converters/isDamageRepaired";
import { LanguageContext } from "../../../../../Config/i18n/LanguageGate";
import { hasPermission } from "../../../../../Utils/utils";

// *---- Style ----
import styles from "./Damages.module.css";

// *---- Components ----
import { Badge, Button } from "reactstrap";
import Card from "../../../../../Common/Components/Card";
import Images from "../../../../../Common/Components/Images";
import Selectors from "../../../../../Common/Components/Selectors";
import CardSeparatorTitle from "../../../../../Common/Components/CardSeparatorTitle";
import CardSeparator from "../../../../../Common/Components/CardSeparator";
import KeyValueText from "../../../../../Common/Components/KeyValueText";
import DocumentLink from "../../../../../Common/Components/DocumentLink";
import IfGroup from "../../../../../auth/components/IfGroup";
import useAuthViewModel from "../../../../../auth/viewModel/authViewModel";
import Emphasize from "../../../../../Common/Components/Emphasize";
import { DeleteButton } from "../../../../../Common/Components/DeleteButton/DeleteButton";
import { TransferDamageModal } from "../TransferDamageModal";

const MAX_PRICE_VALUE = 10000000;

export interface DamagesProps {
  parts: PartWithDamage[];
  onChange?: (key: string, value: any, id: number, partId: number) => void;
  carcheckId: number;
  getTotalAcceptableCost: (partId: number) => number;
  getTotalNonAcceptableCost: (partId: number) => number;
  deleteDamage?: (id: number) => void;
  availableParts: Part[];
  transferDamage?: (
    damageId: number,
    partId: number,
    damageType: string
  ) => void;
  disable?: boolean;
  loading?: boolean;
}
export default function PartDamages({
  parts,
  carcheckId,
  onChange,
  getTotalNonAcceptableCost,
  getTotalAcceptableCost,
  deleteDamage,
  availableParts,
  transferDamage,
  disable,
  loading,
}: DamagesProps) {
  const { lang } = useContext(LanguageContext);
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      {parts.map((part: PartWithDamage, partIndex: number) => {
        return (
          <Card key={partIndex}>
            <div className={styles.container}>
              <CardSeparatorTitle>
                {`${lang.getSecureTranslation(part)} / ${t(
                  "Number of damages"
                )}: ${part.damages.length}`}
              </CardSeparatorTitle>
              <CardSeparator />
              <Emphasize>
                <h3>{`${t(
                  "Parts total acceptable cost"
                )} : ${getTotalAcceptableCost(part.id)}`}</h3>
                <h3>{`${t(
                  "Parts total non acceptable cost"
                )} : ${getTotalNonAcceptableCost(part.id)}`}</h3>
              </Emphasize>
              {part.damages.map((damage, damageIndex) => {
                return (
                  <div key={damageIndex}>
                    <CardSeparator />
                    <DamageComponent
                      loading={loading}
                      disable={disable}
                      transferDamage={transferDamage}
                      availableParts={availableParts}
                      onChange={onChange}
                      damage={damage}
                      carcheckId={carcheckId}
                      damageTypes={part.damagetype_set}
                      partId={part.id}
                      deleteDamage={deleteDamage}
                    />
                  </div>
                );
              })}
            </div>
          </Card>
        );
      })}
    </div>
  );
}

export interface DamageProps {
  damage: DamageInPart;
  damageTypes: DamageType[];
  onChange?: (key: string, value: any, id: number, partId: number) => void;
  carcheckId: number;
  partId: number;
  deleteDamage?: (id: number) => void;
  availableParts: Part[];
  transferDamage?: (
    damageId: number,
    partId: number,
    damageType: string
  ) => void;
  disable?: boolean;
  loading?: boolean;
}
export function DamageComponent({
  damage,
  damageTypes,
  carcheckId,
  partId,
  onChange,
  deleteDamage,
  availableParts,
  transferDamage,
  disable,
  loading,
}: DamageProps) {
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  const { groups } = useAuthViewModel();
  const { t } = useTranslation();
  const { lang } = useContext(LanguageContext);

  function getDamageImages(damage: DamageInPart) {
    return _.map(_.get(damage, "pictures"));
  }

  return (
    <>
      <div className={styles.damageHeader}>
        <div className={styles.damageImages}>
          <Images images={getDamageImages(damage)} />
        </div>
        {!isDamageRepairedInGivenCarcheck(damage, carcheckId) &&
          !damage.freeze_update &&
          !disable && (
            <div className={styles.damageActionsWrapper}>
              <Button
                disabled={loading}
                type={"submit"}
                className={styles.changePartButton}
                onClick={() => setIsTransferModalOpen(true)}
              >
                {t("Change part")}
              </Button>
              <DeleteButton
                disabled={loading}
                onClick={() => {
                  if (deleteDamage) deleteDamage(damage.id);
                }}
              />
            </div>
          )}
        {isDamageRepairedInGivenCarcheck(damage, carcheckId) && (
          <Badge color="success">
            <p className={styles.badgeText}>{t("Repaired")}</p>
          </Badge>
        )}
      </div>
      <div className={styles.keyValueContainer}>
        <KeyValueText shouldWrap title={t("Created Date")}>
          {new Date(damage.created).toLocaleDateString()}
        </KeyValueText>
        {damage.participation.map((participant, index) => (
          <KeyValueText
            key={index}
            shouldWrap
            title={`${t("Created by")} ${participant.user.first_name} ${
              participant.user.last_name
            }`}
          >
            <DocumentLink resource={"users"} id={participant.user.id}>
              {participant.user.email}
            </DocumentLink>
          </KeyValueText>
        ))}
      </div>
      <Selectors
        disabled={
          !hasPermission(groups, [
            Group.BACKOFFICE_USERS,
            Group.FLEET_MANAGERS,
            Group.QUOTERS,
          ]) ||
          !onChange ||
          damage?.freeze_update
        }
        title={t("Damage")}
        onChange={
          onChange
            ? (event: any) =>
                onChange(
                  event.target.name,
                  event.target.value,
                  damage.id,
                  partId
                )
            : (event: any) => {}
        }
        selectors={[
          {
            name: "type",
            label: t("Type of Damage"),
            value: damage.type,
            options: damageTypes.map((damageType) => {
              return {
                value: damageType?.type,
                label: lang.getSecureTranslation(damageType),
              };
            }),
          },
          {
            name: "is_acceptable",
            label: t("Is Acceptable Damage"),
            value: damage.is_acceptable,
            options: [
              { value: "", label: t("I don't know") },
              { value: true, label: t("Yes") },
              { value: false, label: t("No") },
            ],
          },
        ]}
      />
      <IfGroup
        groups={[Group.BACKOFFICE_USERS, Group.FLEET_MANAGERS, Group.QUOTERS]}
      >
        <Selectors
          disabled={
            !hasPermission(groups, [
              Group.BACKOFFICE_USERS,
              Group.FLEET_MANAGERS,
              Group.QUOTERS,
            ]) ||
            !onChange ||
            damage?.freeze_update
          }
          onChange={
            onChange
              ? (event: any) => {
                  let value = event.target.value;
                  if (value === "") {
                    value = null;
                  } else {
                    value = parseFloat(value).toFixed(2);
                  }
                  if (!value || value < MAX_PRICE_VALUE) {
                    onChange(event.target.name, value, damage.id, partId);
                  }
                }
              : (event: any) => {}
          }
          title={t("Repairing Cost")}
          selectors={[
            {
              name: "checkly_cost_estimate",
              label: t("Budget"),
              value: parseFloat(damage.checkly_cost_estimate),
              inputType: "number",
            },
          ]}
        />
      </IfGroup>
      <TransferDamageModal
        transferDamage={transferDamage}
        availableParts={availableParts}
        originalDamage={damage}
        title={t("Transfer damage")}
        isOpen={isTransferModalOpen}
        close={() => setIsTransferModalOpen(false)}
        submit={() => {}}
      />
    </>
  );
}
