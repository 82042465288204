import React from "react";
import { useTranslation } from "react-i18next";
import IfGroup from "../../../../auth/components/IfGroup";
import CloseTabButton from "../../../../Common/Components/CloseTabButton";
import HideOnPrint from "../../../../Common/Components/HideOnPrint";
import PrintButton from "../../../../Common/Components/PrintButton";
import { Group } from "../../../../Config/datamodels/interfaces";
import styles from "./PrintControl.module.css";

export interface PrintControlProps {
  displayPrices: boolean;
  setDisplayPrices: (value: boolean) => void;
  documentTitle: string;
}

export default function PrintControl(props: PrintControlProps) {
  const { t } = useTranslation();

  return (
    <HideOnPrint>
      <div className={styles.wrapper}>
        <div className={styles.left}>
          <CloseTabButton />
          <IfGroup
            groups={[
              Group.BACKOFFICE_USERS,
              Group.HR_OFFICERS,
              Group.FLEET_MANAGERS,
              Group.QUOTERS,
            ]}
          >
            <div className={styles.checkbox}>
              <input
                type="checkbox"
                name="display-prices"
                checked={props.displayPrices}
                onChange={() => props.setDisplayPrices(!props.displayPrices)}
              />
              <p className={styles.label}>{t("Display prices")}</p>
            </div>
          </IfGroup>
        </div>
        <div className={styles.title}>{props.documentTitle}</div>
        <div className={styles.printer}>
          <PrintButton />
        </div>
      </div>
    </HideOnPrint>
  );
}
