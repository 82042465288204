import React, { PropsWithChildren } from "react";
import styles from "../Section/Section.module.css";
import { useTranslation } from "react-i18next";
export interface SectionProps extends PropsWithChildren<{}> {
  title: string;
  displayPrices: boolean;
  acceptableCost?: number;
  nonAcceptableCost?: number;
}

export default function PartSection(props: SectionProps) {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.pagebreak} />
      <div className={styles.section}>
        <div className={styles.header}>
          <div className={styles.title}>{props.title}</div>
          {props.displayPrices && (
            <div className={styles.costs}>
              <p>
                {t("Parts acceptable costs")}: {props.acceptableCost}€
              </p>
              <p>
                {t("Parts non acceptable costs")}: {props.nonAcceptableCost}€
              </p>
            </div>
          )}
        </div>
        {props.children}
      </div>
    </>
  );
}
