import { Validators } from "@lemoncode/fonk";
import { createFinalFormValidation } from "@lemoncode/fonk-final-form";
import { useTranslation } from "react-i18next";

const useValidation = () => {
  const { t } = useTranslation();
  const validation = {
    name: [
      {
        validator: Validators.required.validator,
        message: t("Please fill in this mandatory field"),
      },
    ],
  };
  return createFinalFormValidation({ field: validation });
};

export default useValidation;
