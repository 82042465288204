// *---- Imports ----
import React, { useState } from "react";

// *---- Style ----
import styles from "./SwitchSreen.module.css";

// *---- Components ----
import ToggleButton from "../ToggleButton";
export interface ComponentProps {
  children: JSX.Element[];
  titles: { label: string }[];
}
export default function SwitchScreen<Props extends ComponentProps>({
  children,
  titles,
}: Props) {
  const [activeScreenIndex, setActiveScreen] = useState(0);

  function onChange(index: number) {
    setActiveScreen(index);
  }

  return (
    <div className={styles.container}>
      <div className={styles.toggleButtonWrapper}>
        <ToggleButton
          activeButton={activeScreenIndex}
          switchButtons={titles}
          onChange={onChange}
        />
      </div>
      <div className={styles.screensContainer}>
        {React.Children.map(children, (Child, index) => (
          <>{activeScreenIndex === index && <>{Child}</>}</>
        ))}
      </div>
    </div>
  );
}
