import React from "react";
import { useTranslation } from "react-i18next";
import Highlight from "../../../../Common/Components/Highlight";
import { Carcheck } from "../../../../Config/datamodels/interfaces";
import { getTyreTypes } from "../../../../Utils/utils";
import TyreCarWidget from "../TyreCarWidget";
import style from "./TyreSectionContent.module.css";

export interface TyreSectionContentProps {
  carcheck: Carcheck;
}

export default function TyreSectionContent(props: TyreSectionContentProps) {
  const { t } = useTranslation();
  return (
    <div className={style.wrapper}>
      <div className={style.header}>
        <div className={style.title}>{t("Tyres")}</div>
        <div className={style.type}>
          <span className={style.typelabel}>{t("Type") + " : "}</span>
          <Highlight>
            {getTyreTypes(t, props.carcheck.front_tyres_type)}
          </Highlight>
        </div>
      </div>
      <TyreCarWidget
        frontLeftState={props.carcheck.is_front_left_state_good}
        frontRightState={props.carcheck.is_front_right_state_good}
        backLeftState={props.carcheck.is_back_left_state_good}
        backRightState={props.carcheck.is_back_right_state_good}
      />
    </div>
  );
}
