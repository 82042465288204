import React from "react";
import styles from "./ErrorField.module.css";

export interface FieldErrorProps {
  finalFormProps: any;
  fieldError: string | undefined;
}

export default function FieldError<Props extends FieldErrorProps>({
  finalFormProps,
  fieldError,
}: Props) {
  if (finalFormProps.meta.error && finalFormProps.meta.touched) {
    return (
      <span className={styles.errorMessage}>{finalFormProps.meta.error}</span>
    );
  } else if (fieldError) {
    return <span className={styles.errorMessage}>{fieldError}</span>;
  } else {
    return <div></div>;
  }
}
