// *---- Imports ----
import React, { useCallback, useContext, useEffect, useState } from "react";
import { InventoryPresence } from "../../../../../Config/datamodels/interfaces";
import { LanguageContext } from "../../../../../Config/i18n/LanguageGate";
import clusterify, { ClusteredInventoryPresence } from "../../Utils/clusterify";
import _ from "lodash";
// *---- Style ----
import styles from "./InventoryPresence.module.css";
// *---- Components ----
import Card from "../../../../../Common/Components/Card";
import CardSeparator from "../../../../../Common/Components/CardSeparator";
import CardSeparatorTitle from "../../../../../Common/Components/CardSeparatorTitle";
import { HorizontalDrawer } from "../../../../../Common/Components/HorizontalDrawer";
import {
  BadgeActionBar,
  IsPresentBadge,
  MissingBadge,
  NotKnownBadge,
} from "../PresenceBadge";

export interface InventoryPresenceProps {
  inventoryPresence: InventoryPresence[] | undefined;
  updateIsDisabled?: boolean;
  update?: (id: number, state: boolean | null) => void;
}

export default function InventoryPresenceComponent<
  Props extends InventoryPresenceProps
>({ inventoryPresence, updateIsDisabled, update }: Props) {
  const [cluster, setCluster] = useState<ClusteredInventoryPresence>({});
  useEffect(() => {
    if (inventoryPresence) {
      setCluster(clusterify(inventoryPresence));
    }
  }, [inventoryPresence]);

  return (
    <>
      {cluster && (
        <Card>
          <div className={styles.container}>
            {_.keys(cluster).map((category, index) => (
              <InventoryPresenceCategory
                key={index}
                items={cluster[category]}
                updateDisabled={updateIsDisabled}
                update={update}
              />
            ))}
          </div>
        </Card>
      )}
    </>
  );
}

export interface InventoryPresenceCategoryProps {
  items: InventoryPresence[];
  updateDisabled?: boolean;
  update?: (id: number, state: boolean | null) => void;
}

export function InventoryPresenceCategory(
  props: InventoryPresenceCategoryProps
) {
  const { lang } = useContext(LanguageContext);

  return (
    <div className={styles.category}>
      <CardSeparatorTitle>
        {lang.getCustomTranslation("category", props.items[0].inventory_item)}
      </CardSeparatorTitle>
      {props.items.map((item, index) => (
        <div key={index} className={styles.items}>
          <p className={styles.text}>
            {lang.getSecureTranslation(item.inventory_item)}
          </p>
          <Present
            update={(newState) =>
              props.update && props.update(item.id, newState)
            }
            isPresent={item.presence}
            isDisabled={props.updateDisabled}
          />
        </div>
      ))}
      <CardSeparator />
    </div>
  );
}

export interface PresentProps {
  isPresent: boolean | null;
  isDisabled?: boolean;
  update: (state: boolean | null) => void;
}

export function Present(props: PresentProps) {
  const [isOpen, setIsOpen] = useState(false);
  const { update } = props;

  const updateAndClose = useCallback(
    (value: boolean | null) => {
      setIsOpen(false);
      update(value);
    },
    [setIsOpen, update]
  );

  function conditionalRenderingOnPresence() {
    if (props.isPresent === null) {
      return (
        <HorizontalDrawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          disabled={!!props.isDisabled}
          trigger={<NotKnownBadge />}
        >
          <BadgeActionBar onClick={updateAndClose} />
        </HorizontalDrawer>
      );
    } else if (props.isPresent === true) {
      return (
        <HorizontalDrawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          disabled={!!props.isDisabled}
          trigger={<IsPresentBadge />}
        >
          <BadgeActionBar onClick={updateAndClose} />
        </HorizontalDrawer>
      );
    } else {
      return (
        <HorizontalDrawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          disabled={!!props.isDisabled}
          trigger={<MissingBadge />}
        >
          <BadgeActionBar onClick={updateAndClose} />
        </HorizontalDrawer>
      );
    }
  }
  return <>{conditionalRenderingOnPresence()}</>;
}
