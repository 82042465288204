import { IntFilter } from "../../Common/Filters";
import vehicleListViewModel from "../../vehicles/viewModel/listViewModel";
import { useCallback, useMemo } from "react";

const useUserVehicleListViewModel = (userId: number) => {
  const userFilter = useMemo(() => {
    const filter = new IntFilter("driver", "user");
    filter.value = userId;
    return filter;
  }, [userId]);

  const interceptor = useCallback(
    async (args) => ({
      ...args,
      filters: [...args.filters, userFilter],
    }),
    [userFilter]
  );

  return vehicleListViewModel(interceptor);
};

export default useUserVehicleListViewModel;
