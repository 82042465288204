import { Group } from "../../../Config/datamodels/interfaces";

export default function vehicleListHeaders(
  t: (key: string) => string,
  groups: string[]
) {
  const headers = [
    "",
    "Vin",
    t("Driver"),
    t("Plate"),
    t("Brand / model"),
    t("Contract mileage"),
    t("Mileage"),
    t("Year"),
    t("Type"),
    t("Owned by"),
    t("Leasing period"),
    t("Department"),
    "",
  ];
  if (groups.includes(Group.BACKOFFICE_USERS)) {
    headers.splice(1, 0, "id");
    headers.splice(2, 0, t("Company"));
  }
  return headers;
}
