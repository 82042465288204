import { useTranslation } from "react-i18next";
import { StringListFilter } from "../../Common/Filters";
import {
  DateField,
  SearchSelectField,
  SelectField,
} from "../../Common/Store/FormReducer/Fields";
import { Group } from "../../Config/datamodels/interfaces";
import { availableCarcheckTypes } from "../../Config/general";
const useFormFields = () => {
  const { t } = useTranslation();

  return {
    appointment_date: new DateField(
      "appointment_date",
      t("Request date"),
      t("Pick the request date")
    ),
    carcheck_type: new SelectField(
      "carcheck_type",
      t("Type"),
      t("Type of carcheck"),
      availableCarcheckTypes
    ),
    user: new SearchSelectField(
      "user",
      t("Carchecker email"),
      t("Do you need a carchecker for this request ?"),
      "users",
      "email",
      undefined,
      {
        extraFilters: [
          new StringListFilter("groups__name", "", Group.CARCHECKERS),
        ],
      }
    ),
    should_notify: new SelectField(
      "should_notify",
      t("should a notification be send ?"),
      t("should a notification be send ?"),
      [
        {
          label: t("Send Notification"),
          value: "true",
        },
        { label: t("Don't Send Notification"), value: "false" },
      ],
      { default: "true" }
    ),
  };
};

export default useFormFields;
