import Axios from "axios";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";
import { IntListFilter } from "../../Common/Filters";
import makeRESTDetailService from "../../Common/Services/DetailService/RESTDetailService";
import { Carcheck } from "../../Config/datamodels/interfaces";
import damageListService from "../../damages/services/listService";
import addDamagePerPartToCarcheck from "../converters/addDamagePerPartToCarcheck";

const baseDetailService = makeRESTDetailService<Carcheck>(
  "carchecks",
  buildRESTUrl,
  Axios
);

const listDamageRelatedToCarcheckService = async (
  carcheckId: number | string
) => {
  const checkFilter = new IntListFilter("carcheck_presence", "");
  checkFilter.value = carcheckId;
  return await damageListService({
    limit: 200,
    offset: 0,
    orderBy: "id",
    descending: true,
    filters: [checkFilter],
    search: "",
  });
};

const detailService = async (id: number | string) => {
  const [detail, damageList] = await Promise.all([
    baseDetailService(id),
    listDamageRelatedToCarcheckService(id),
  ]);
  return addDamagePerPartToCarcheck(detail, damageList.results);
};

export default detailService;
