import { RESTQueryBuilder } from "../../Common/ApiGateway/buildRESTUrl";
import { HTTPPostService } from "../../Common/Services/types";
import { Credentials } from "../../Config/datamodels/interfaces";

export type LoginService = (credentials: Credentials) => Promise<string>;

const makeLoginService = (
  urlBuilder: RESTQueryBuilder,
  postService: HTTPPostService<{ access: string }>
) => async (credentials: Credentials) => {
  const url = urlBuilder({ resource: "users", action: "login" });
  const reponse = await postService.post(url, credentials);
  return reponse.data.access;
};

export default makeLoginService;
