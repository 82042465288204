import React from "react";
import useAuthViewModel from "../../../auth/viewModel/authViewModel";
import FormView from "../../../Common/Components/FormView";
import { Company } from "../../../Config/datamodels/interfaces";
import useFormViewModel, {
  requestInterceptor,
  responseInterceptor,
} from "../../viewModel/formViewModel";
import { CompanyUpdateFormFields } from "../../viewModel/types";
import useValidation from "./useValidation";
import { useTranslation } from "react-i18next";

export default function () {
  const { user, refreshUser } = useAuthViewModel();
  const { t } = useTranslation();
  const formData = useFormViewModel(
    user.profile.company as string,
    requestInterceptor,
    responseInterceptor
  );
  const validation = useValidation();
  return (
    <FormView<Company, CompanyUpdateFormFields>
      shouldGoBack
      shouldHideReset
      formData={formData}
      validateButtonText={t("Save configuration")}
      title={t("Environment configuration")}
      validation={validation}
      // needs to refresh user to reset local company settings
      afterSubmit={refreshUser}
      description={t(
        "Change the system configuration to adapt Checkly to your needs"
      )}
      modelName="companies"
    />
  );
}
