import { useContext, useEffect, useMemo } from "react";
import { LanguageContext } from "../../Config/i18n/LanguageGate";
import { mapPartDamagesTo2DimensionArray } from "../converters/heatmapConverter";
import heatmapCarcheckService from "../services/heatmapCarcheckService";
import { useHeatmapReducer } from "../store/heatmapReducer";

const useHeatmapViewModel = (
  carcheckIds: number[] | string[] | (number | string)[],
  range: number[]
) => {
  const { lang } = useContext(LanguageContext);
  const { data, isLoading, setData, setError } = useHeatmapReducer();

  const get = async () => {
    try {
      const carchecks = await heatmapCarcheckService(carcheckIds);
      setData(carchecks);
    } catch (err) {
      setError(err);
    }
  };

  const transformedData = useMemo(
    () => mapPartDamagesTo2DimensionArray(data, range, lang),
    [data, range, lang]
  );

  useEffect(() => {
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carcheckIds]);

  return {
    data: transformedData.data,
    xLabels: transformedData.xLabels,
    yLabels: transformedData.yLabels,
    isLoading,
  };
};

export default useHeatmapViewModel;
