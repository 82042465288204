import { useTranslation } from "react-i18next";

import {
  EmailField,
  SelectField,
  ImageField,
} from "../../Common/Store/FormReducer/Fields";

const useFormFields = () => {
  const { t } = useTranslation();
  return {
    notification_email: new EmailField(
      "notification_email",
      t("Email address to send notifications to"),
      t("Notification email")
    ),
    should_send_signup_mail: new SelectField(
      "should_send_signup_mail",
      t("Send signup emails when creating users"),
      t("Send signup emails when creating users"),
      [
        {
          label: t("Send Email"),
          value: "true",
        },
        { label: t("Don't Send Email"), value: "false" },
      ]
    ),
    logo: new ImageField(
      "logo",
      t("Use a 100px by 100px image with no background color"),
      t("drop your company logo here"),
      { singleImage: true }
    ),
  };
};

export default useFormFields;
