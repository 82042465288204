import Axios from "axios";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";
import { IntListFilter } from "../../Common/Filters";
import makeRESTListService from "../../Common/Services/ListService/RESTListService";
import { Carcheck } from "../../Config/datamodels/interfaces";

const baseCarcheckService = makeRESTListService<Carcheck[]>(
  "carchecks",
  buildRESTUrl,
  Axios
);

const heatmapCarcheckService = (
  carcheckIds: number[] | string[] | (number | string)[]
) => {
  const filter = new IntListFilter("id", "");
  filter.value = carcheckIds;
  return baseCarcheckService({
    limit: carcheckIds.length,
    offset: 0,
    filters: [filter],
    orderBy: "id",
    descending: false,
    search: "",
  });
};

export default heatmapCarcheckService;
