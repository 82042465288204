import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ConfirmPassword } from "../../../Config/datamodels/interfaces";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import validation from "./validation";
import useAuthViewModel from "../../viewModel/authViewModel";
import { Path } from "../../../Config/Router/utils";

// *---- Imported Components ----
import DoubleInputForm, { FormField } from "../../components/DoubleInputForm";
import { Button } from "reactstrap";
import ErrorHandler from "../../components/ErrorHandler";

// *---- Styles ----
import styles from "./ActivateScreen.module.css";

// ---- Constant ----

const FIELDS: FormField[] = [
  { name: "password", type: "password", placeholder: "password" },
  {
    name: "confirmPassword",
    type: "password",
    placeholder: "confirm password",
  },
];

export default function ActivateScreen() {
  /**
   * Main react login component.
   *
   */

  // *---- Setup ----
  const { user, error, getMeForActivation, activate } = useAuthViewModel();
  const history = useHistory();
  const { t } = useTranslation();
  let { token } = useParams<any>();

  //  *----Life Cycles ----

  useEffect(() => {
    if (token) {
      getMeForActivation(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // *---- Selectors ----
  const loginError = error
    ? t(
        'Token no longer valid, reset your password by clicking "forgot password"'
      )
    : "";

  // *---- Component ----

  return (
    <div className={styles.container}>
      <div className={styles.title}>{t("Welcome")}</div>
      <div className={styles.formWrapper}>
        <ErrorHandler>{loginError}</ErrorHandler>
        {user?.email && (
          <div className={styles.email}>{t("Account: ") + user?.email}</div>
        )}
        <DoubleInputForm
          validate={(values: any) => validation.validateForm(values)}
          onsubmit={async (inputValues: ConfirmPassword) => {
            await activate(inputValues);
            history.push(Path.LOGIN);
          }}
          fields={FIELDS}
        >
          <div className={styles.buttonsContainer}>
            <Button type="submit" className={styles.button} color="primary">
              {t("Activate Account")}
            </Button>
            <Button color="link" className={styles.button} href="/login">
              {t("Go to login")}
            </Button>
          </div>
        </DoubleInputForm>
      </div>
    </div>
  );
}
