import React, { useState } from "react";
import {
  Label,
  InputGroup,
  InputGroupAddon,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  Filter,
  FilterCondition,
  cloneFilterWithCondition,
} from "../../Filters";

import FieldFactory, { FilterConditionSelectorProps } from "./FilterFactory";

import styles from "./Filters.module.css";
interface FiltersProps {
  filters: Filter[];
  onChangeFilters: (filters: Filter[]) => void;
}

export function FilterConditionSelector(props: FilterConditionSelectorProps) {
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const onClick = (newCondition: FilterCondition) => (ev: any) => {
    ev.preventDefault();
    props.onChange(cloneFilterWithCondition(props.filter, newCondition));
  };

  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} size="sm">
      <DropdownToggle color="none" className="text-primary">
        {props.filter.condition}
      </DropdownToggle>
      <DropdownMenu>
        {props.filter.possibleConditions.map((condition, index) => (
          <DropdownItem key={index} onClick={onClick(condition)}>
            {condition}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </ButtonDropdown>
  );
}

export default function Filters(props: FiltersProps) {
  const onChange = (index: number) => (filter: Filter) => {
    const newArray = [...props.filters];
    newArray.splice(index, 1, filter);
    props.onChangeFilters(newArray);
  };

  const deleteFilter = (index: number) => (ev: any) => {
    ev.preventDefault();
    const newArray = [...props.filters];
    newArray.splice(index, 1);
    props.onChangeFilters(newArray);
  };

  return (
    <div className={styles.filters}>
      {props.filters.map((filter, index) => (
        <div key={index}>
          <div style={{ width: (index % 7) * 10 }} className={styles.spacer} />
          <div className={styles.filterwrapper}>
            <Label>{filter.display}</Label>
            <InputGroup>
              {filter.condition && (
                <InputGroupAddon
                  addonType="prepend"
                  className="input-group-text input-group-prepend"
                >
                  <FilterConditionSelector
                    filter={filter}
                    onChange={onChange(index)}
                  />
                </InputGroupAddon>
              )}
              <FieldFactory filter={filter} onChange={onChange(index)} />
              <InputGroupAddon addonType="append" className="input-group-text">
                <Button
                  close
                  onClick={deleteFilter(index)}
                  data-testid={"close-filter-" + filter.name}
                />
              </InputGroupAddon>
            </InputGroup>
          </div>
        </div>
      ))}
    </div>
  );
}
