import React from "react";
import { useTranslation } from "react-i18next";
import {
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { Search as SearchIcon } from "react-bootstrap-icons";

interface SearchProps {
  value: string;
  onChange: (value: string) => void;
}

export default function Search(props: SearchProps) {
  const { t } = useTranslation();
  const onChange = (ev: any) => {
    ev.preventDefault();
    props.onChange(ev.target.value);
  };
  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <FormGroup style={{ marginBottom: 0 }}>
        <InputGroup>
          <Input
            type="text"
            name="search"
            id="search"
            placeholder={t("search")}
            value={props.value}
            onChange={onChange}
          />
          <InputGroupAddon addonType="append" className="input-group-text">
            <SearchIcon />
          </InputGroupAddon>
        </InputGroup>
      </FormGroup>
    </Form>
  );
}
