import { Validators } from "@lemoncode/fonk";
import {
  createFinalFormValidation,
  FinalFormValidation,
} from "@lemoncode/fonk-final-form";
import { rangeDate } from "@lemoncode/fonk-range-date-validator";
import { useTranslation } from "react-i18next";
import { FormValidationMap } from "../../../Common/Store/FormReducer";
import { UserFormFieldsName } from "../../viewModel/types";

const useValidation = (): FinalFormValidation => {
  const { t } = useTranslation();

  const validations: FormValidationMap<UserFormFieldsName> | null = {
    first_name: [
      {
        validator: Validators.required.validator,
        message: t("Please fill in this mandatory field"),
      },
    ],
    last_name: [
      {
        validator: Validators.required.validator,
        message: t("Please fill in this mandatory field"),
      },
    ],
    email: [
      {
        validator: Validators.required.validator,
        message: t("Please fill in this mandatory field"),
      },
      {
        validator: Validators.email.validator,
        message: "Please enter a valid email address",
      },
    ],
    groups: [
      {
        validator: Validators.required.validator,
        message: t("Please fill in this mandatory field"),
      },
    ],
    // an empty validator array means no validation for the field
    phone: [],
    department: [],
    language: [
      {
        validator: Validators.required.validator,
        message: t("Please fill in this mandatory field"),
      },
    ],
    contract_end_date: [
      {
        validator: rangeDate.validator,
        customArgs: {
          parseStringToDateFn: (value: string) => new Date(value),
          min: {
            value: Date.now(),
          },
          max: {
            // force to have a max date with range date package. We might need custom fields for better approach
            value: new Date("3000-01-01"),
          },
        },
        message: t("The date cannot be before today"),
      },
    ],
  };

  return createFinalFormValidation({ field: validations });
};

export default useValidation;
