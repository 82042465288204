import deltaCarcheckService from "../services/deltaCarcheckService";
import deltaDamageService from "../services/deltaDamageService";
import { useDeltaReducer } from "../store/deltaReducer";
import { createDelta } from "../converters/deltaConverter";
import { Carcheck } from "../../Config/datamodels/interfaces";

type UseDeltaViewModelReturn = {
  get: () => void;
  data: Carcheck;
  isSuccess: boolean;
  isLoading: boolean;
  clear: () => void;
};

const useDeltaViewModel = (
  leftCarcheckId?: number | string,
  rightCarcheckId?: number | string
): UseDeltaViewModelReturn => {
  const {
    data,
    isSuccess,
    isLoading,
    setData,
    setError,
    clear,
  } = useDeltaReducer();

  const get = async () => {
    if (leftCarcheckId && rightCarcheckId) {
      try {
        const [left, right, leftDamages, rightDamages] = await Promise.all([
          deltaCarcheckService(leftCarcheckId),
          deltaCarcheckService(rightCarcheckId),
          deltaDamageService(leftCarcheckId),
          deltaDamageService(rightCarcheckId),
        ]);
        const carcheck = createDelta(left, right, leftDamages, rightDamages);
        setData(carcheck);
      } catch (err) {
        setError(err);
      }
    }
  };

  return {
    get,
    data,
    isSuccess,
    isLoading,
    clear,
  };
};

export default useDeltaViewModel;
