import Axios from "axios";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";
import makeRESTDetailService from "../../Common/Services/DetailService/RESTDetailService";
import { Vehicle } from "../../Config/datamodels/interfaces";

const detailService = makeRESTDetailService<Vehicle>(
  "vehicles",
  buildRESTUrl,
  Axios
);
export default detailService;
