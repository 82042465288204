import makeUseDetailViewModel from "../../Common/ViewModels/makeUseDetailViewModel";
import userdetailService from "../services/DetailService";
import { useUserDetailReducer } from "../store/detailReducer";
import toastService from "../../Common/Services/ToastService";
import { User } from "../../Config/datamodels/interfaces";

const viewModel = makeUseDetailViewModel<User>(
  userdetailService,
  useUserDetailReducer,
  toastService
);
export default viewModel;
