import _ from "lodash";
import { FormFieldMap } from "../Store/FormReducer/Fields";

export const hydrateFields = <FieldsName extends string>(
  fields: FormFieldMap<FieldsName>,
  body: any
) => {
  const hydrateFields = _.cloneDeep(fields);
  for (const key in hydrateFields) {
    hydrateFields[key].value = _.get(body, key);
  }
  return hydrateFields;
};
