import { useTranslation } from "react-i18next";
import {
  DateFilter,
  IntFilter,
  SearchSelectFilter,
  TextFilter,
} from "../../../Common/Filters";
import { Resources } from "../../../Config/datamodels/interfaces";

const useFields = () => {
  const { t } = useTranslation();

  const filterableFields = [
    new TextFilter("vehicle__vin", t("Vehicle Vin")),
    new TextFilter("vehicle__plate", t("Vehicle Plate")),
    new DateFilter("done_datetime", t("Date")),
    new TextFilter("vehicle__driver__email", t("Driver")),
    new IntFilter("mileage", t("Mileage")),
    new SearchSelectFilter(
      "participation__user__email",
      t("Participant"),
      "",
      new TextFilter("email", ""),
      Resources.USERS,
      "email",
      "email"
    ),
  ];

  const orderableFields = [
    { name: "", display: t("None") },
    { name: "created", display: t("Created") },
    { name: "vehicle__vin", display: t("Vehicle") },
    { name: "done_datetime", display: t("Date") },
    { name: "mileage", display: t("Mileage") },
  ];

  return { filterableFields, orderableFields };
};

export default useFields;
