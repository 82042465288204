import listViewModel from "../../carchecks/viewModel/useListViewModel";
import { IntFilter } from "../../Common/Filters";
import { useCallback, useMemo } from "react";

const useVehicleCarcheckListViewModel = (vehicleId: number) => {
  const vehicleFilter = useMemo(() => {
    const filter = new IntFilter("vehicle__id", "vehicle");
    filter.value = vehicleId;
    return filter;
  }, [vehicleId]);

  const interceptor = useCallback(
    async (args) => ({
      ...args,
      filters: [...args.filters, vehicleFilter],
    }),
    [vehicleFilter]
  );

  return listViewModel(interceptor);
};
export default useVehicleCarcheckListViewModel;
