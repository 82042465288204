import { useTranslation } from "react-i18next";
import { StringSelectFilter, TextFilter } from "../../../Common/Filters";
import { Group } from "../../../Config/datamodels/interfaces";
import { groupChoices } from "../../../Utils/utils";

const useListFields = () => {
  const { t } = useTranslation();

  const filterableFields = [
    new TextFilter("email", t("Email")),
    new TextFilter("profile__department__name", t("Department")),
    new StringSelectFilter(
      "groups__name",
      t("Roles"),
      Group.DRIVERS,
      groupChoices()
    ),
  ];

  const orderableFields = [
    { name: "", display: t("None") },
    { name: "email", display: t("Email") },
    { name: "username", display: t("Username") },
    { name: "profile__created", display: t("Created") },
    { name: "profile__last_updated", display: t("Updated") },
  ];

  return { filterableFields, orderableFields };
};

export default useListFields;
