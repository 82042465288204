import React, { useMemo, ReactNode } from "react";
import style from "./HorizontalDrawer.module.css";
import { X } from "react-bootstrap-icons";
import { Badge } from "reactstrap";
import { classes } from "../../../Utils/classes";

export interface HorizontalDrawerProps extends React.PropsWithChildren<{}> {
  disabled: boolean;
  trigger: ReactNode;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

export function CloseIcon(props: { onClick: () => void }) {
  return (
    <Badge
      className={style.closeIcon}
      color="light"
      pill
      onClick={props.onClick}
    >
      <X size={16} color="primary" />
    </Badge>
  );
}

export function HorizontalDrawer(props: HorizontalDrawerProps) {
  const { disabled, isOpen, setIsOpen, trigger, children } = props;

  const wrapperStyle = useMemo(() => {
    if (disabled) {
      return classes(style.wrapper, style.disabled);
    }
    return classes(style.wrapper, [isOpen, style.open, style.readyToOpen]);
  }, [isOpen, disabled]);

  const drawerStyle = useMemo(
    () => classes(style.drawer, [isOpen, style.drawerOpen, style.drawerClosed]),
    [isOpen]
  );

  const triggerStyle = useMemo(
    () =>
      classes(style.triggerWrapper, [isOpen, style.triggerWrapperActive, ""]),
    [isOpen]
  );

  const onClick = useMemo(() => {
    if (disabled) {
      return () => {};
    }
    return isOpen ? () => {} : () => setIsOpen(true);
  }, [isOpen, disabled, setIsOpen]);

  return (
    <div className={wrapperStyle} onClick={onClick}>
      <div className={drawerStyle}>{children}</div>
      <div className={triggerStyle}>
        {isOpen ? <CloseIcon onClick={() => setIsOpen(false)} /> : trigger}
      </div>
    </div>
  );
}
