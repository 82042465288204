import React from "react";
import { EditableKeyValue } from "../../../../../Common/Components/KeyValueTextInput";
import {
  Carcheck,
  CarcheckType,
} from "../../../../../Config/datamodels/interfaces";
import { useTranslation } from "react-i18next";
import { getNumberOfKeysOptions } from "../../../../../Utils/utils";
import { isUndefined } from "lodash";
import { IfCarcheck } from "../../../../../Common/Components/IfCarcheck";

export interface KeysSectionProps {
  carcheck: Carcheck;
  onChange: (fieldName: string, value: any) => void;
  disable?: boolean;
}

export function KeysSection(props: KeysSectionProps) {
  const { t } = useTranslation();
  return (
    <IfCarcheck
      types={[
        CarcheckType.EXHAUSTIVE,
        CarcheckType.RENTA,
        CarcheckType.RECEPTION,
      ]}
      currentType={props.carcheck.carcheck_type}
    >
      <EditableKeyValue
        disabled={props.disable}
        fieldName={"key_count"}
        title={t("Number of keys")}
        onChange={(event) => props.onChange("key_count", event.target.value)}
        value={props.carcheck.key_count}
        options={getNumberOfKeysOptions(t)}
        hide={isUndefined(props.carcheck.key_count)}
        readonly={props.carcheck.freeze_update}
      >
        {props.carcheck.key_count}
      </EditableKeyValue>
    </IfCarcheck>
  );
}
