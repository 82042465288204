import makeUseImportViewModel from "../../Common/ViewModels/makeUseImportViewModel";
import importService from "../services/importService";
import { useImportReducer } from "../store/importReducer";

const importViewmodel = makeUseImportViewModel(
  importService,
  useImportReducer,
  "vehicles"
);
export default importViewmodel;
