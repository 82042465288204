import React from "react";
import { CarcheckRequest, Group } from "../../../Config/datamodels/interfaces";
import ListView from "../../../Common/Components/ListView";
import ListItem from "./ListItem";
import { useTranslation } from "react-i18next";
import useAuthViewModel from "../../../auth/viewModel/authViewModel";
import listViewModel from "../../viewModel/listViewModel";
import useFields from "./useFields";
import { DeletionModal } from "./DeletionModal";

export default function ListScreen() {
  const listData = listViewModel();
  const { filterableFields, orderableFields } = useFields();
  const { t } = useTranslation();
  const { groups } = useAuthViewModel();
  const headers = [
    t("Carcheck"),
    t("Date"),
    t("Vehicle"),
    t("Vehicle Plate"),
    t("User"),
    t("Status"),
    t("Send Reminder"),
    "", // reminder button
  ];
  if (groups.includes(Group.BACKOFFICE_USERS)) {
    headers.splice(0, 0, "id");
    headers.splice(headers.length, 0, "");
  } else if (groups.includes(Group.FLEET_MANAGERS)) {
    headers.splice(headers.length, 0, "");
  }
  return (
    <>
      <ListView<CarcheckRequest>
        title={t("Requests  ")}
        emptyListTitle={t("You haven't any request created yet")}
        emptyListDescription={t(
          "To create a request, you must first creates some rules, or ask a directly a driver to perform a check from his vehicle page"
        )}
        listData={listData}
        headers={headers}
        renderLine={(carcheckRequest: CarcheckRequest) => (
          <ListItem
            onDelete={listData.openDeleteModal}
            sendReminder={listData.sendReminderEmail}
            carcheckRequest={carcheckRequest}
          />
        )}
        availableFilters={filterableFields}
        orderableFields={orderableFields}
      />
      <DeletionModal
        isLoading={listData.deleteIsLoading}
        onDelete={listData.confirmDelete}
        isOpen={listData.isDeleteModalOpen}
        onClose={listData.closeDeleteModal}
        request={listData.requestToDelete}
      />
    </>
  );
}
