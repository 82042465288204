import axios from "axios";
import addAuthorization from "./addAuthorization";
import addLanguage from "./addLanguage";
import logoutOnExpire from "./logoutOnExpire";
import addRequestToSentry from "./addRequestToSentry";

// requests interceptors
addAuthorization(axios);
addLanguage(axios);
// response interceptors
logoutOnExpire(axios);

addRequestToSentry(axios);
