// *---- Imports ----
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { Vehicle } from "../../../Config/datamodels/interfaces";
import Highlight from "../Highlight";

// *---- Style ----
import styles from "./VehiclePictureInfoCard.module.css";

// *---- Components ----
import KeyValueText from "../KeyValueText";
import Card from "../Card";
import Thumbnail, { ThumbnailSize } from "../Thumbnail";
import carPicturePlaceholder from "./car-placeholder.png";
import DocumentLink from "../DocumentLink";

export interface ComponentProps {
  vehicle: Vehicle;
  classname?: string;
}
export default function VehiclePictureInfoCard<Props extends ComponentProps>(
  props: Props
) {
  const { t } = useTranslation();
  function getUriOrPlaceholder(uri: string | undefined) {
    return uri || carPicturePlaceholder;
  }
  return (
    <Card className={props.classname}>
      <div className={styles.content}>
        <div className={styles.imagewrapper}>
          <Thumbnail
            src={getUriOrPlaceholder(props.vehicle?.picture?.medium)}
            size={ThumbnailSize.medium}
          />
          <div className={styles.namewrapper}>
            <Highlight>{props.vehicle.brand}</Highlight>
            <span className="ml-2">{props.vehicle.model}</span>
          </div>
        </div>
        <div className={styles.contentRow}>
          <KeyValueText title={t("Mileage")}>
            {"" + props.vehicle.mileage}
          </KeyValueText>
          <KeyValueText uppercased title={t("Plate")}>
            {props.vehicle?.plate || t("N/A")}
          </KeyValueText>
        </div>
        <div className={styles.contentRow}>
          <KeyValueText title={t("Department") + ":" + t("Owning Company")}>
            {props.vehicle.department
              ? props.vehicle.department.name
              : t("N/A")}
            : {_.get(props.vehicle, "owning_partner.name")}
          </KeyValueText>
          <KeyValueText uppercased title={t("Vin")}>
            <DocumentLink resource="vehicles" id={props.vehicle.id}>
              {props.vehicle.vin.toUpperCase()}
            </DocumentLink>
          </KeyValueText>
        </div>
      </div>
    </Card>
  );
}
