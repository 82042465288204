import React from "react";
import { CarcheckListEntry } from "../../../Config/datamodels/interfaces";
import ListView from "../../../Common/Components/ListView";
import { Path } from "../../../Config/Router/utils";
import ListItem from "./ListItem";
import { useTranslation } from "react-i18next";
import carcheckListHeaders from "./listHeaders";
import useAuthViewModel from "../../../auth/viewModel/authViewModel";
import listViewModel from "../../viewModel/useListViewModel";
import useFields from "./useFields";

export default function ListScreen() {
  const listData = listViewModel();
  const { filterableFields, orderableFields } = useFields();
  const { t } = useTranslation();
  const { groups } = useAuthViewModel();
  const headers = carcheckListHeaders(t, groups);
  return (
    <ListView<CarcheckListEntry>
      title={"Carchecks"}
      emptyListTitle={t("You don't have any carcheck")}
      emptyListDescription={t(
        "Carcheck created by your drivers or carchecker will appear here"
      )}
      basePath={Path.CARCHECKS}
      listData={listData}
      headers={headers}
      renderLine={(carcheck: CarcheckListEntry) => ListItem(carcheck)}
      availableFilters={filterableFields}
      orderableFields={orderableFields}
    />
  );
}
