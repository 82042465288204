import React from "react";
import FormView from "../../../Common/Components/FormView";
import { useTranslation } from "react-i18next";
import { RulesFormFieldsName } from "../../viewModel/types";
import useFormViewModel from "../../viewModel/formViewModel";
import useValidation from "./useValidation";
import { Rules } from "../../../Config/datamodels/interfaces";
import { FormRequestInterceptor } from "../../../Common/ViewModels/makeUseFormViewModel";
import _ from "lodash";
import useAuthViewModel from "../../../auth/viewModel/authViewModel";

function isFilterDefined(filter: { label: string; value: any } | undefined) {
  if (_.get(filter, "value")) {
    return true;
  }
  return false;
}

function getFilterValue(filter: { label: string; value: any }) {
  return _.get(filter, "value");
}

const makeRequestInterceptor = (
  company: string
): FormRequestInterceptor<Rules> => (body: any) => {
  let timeRules = ["*", "*", "*", "*"];
  const timeRulesData = body["timeRule"];
  timeRules[timeRulesData.timeValue] = timeRulesData.unitOf;
  body[timeRulesData.name] = timeRules.join(",");
  body["user"] = _.get(body, "user.value");

  const filter: any = {};

  if (isFilterDefined(body.vehicle_type)) {
    filter.type = getFilterValue(body.vehicle_type);
  }

  if (isFilterDefined(body.owning_partner)) {
    filter.owning_partner = getFilterValue(body.owning_partner);
  }

  body["filter"] = JSON.stringify(filter);

  delete body["timeRule"];
  delete body["vehicle_type"];
  delete body["owning_partner"];

  // Potential issue with getting states like this -> refactor to automatically set it from back end
  body["company"] = company;

  return body;
};

export default function () {
  const { t } = useTranslation();
  const { user } = useAuthViewModel();
  const company = user.profile.company;
  const formData = useFormViewModel(
    undefined,
    makeRequestInterceptor(company as string)
  );
  const validation = useValidation();

  return (
    <FormView<Rules, RulesFormFieldsName>
      shouldGoBack
      formData={formData}
      validateButtonText={t("Register Rules")}
      title={t("New Rule")}
      description={t(
        "Create rules to automatically send Carcheck requests to your drivers. First choose the type of carchecks to perform, then the condition when the requests should be sent. Finally Select specific filters on the vehicles you want to apply this rule to."
      )}
      validation={validation}
      modelName="rules"
    />
  );
}
