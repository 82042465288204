import React from "react";
import { Partner, Group } from "../../../Config/datamodels/interfaces";
import IfGroup from "../../../auth/components/IfGroup";
import _ from "lodash";
import RowOptionButton from "../../../Common/Components/RowOptionButton";
import { useTranslation } from "react-i18next";

export default function (props: {
  partner: Partner;
  onDelete: (id: number | string) => void;
}) {
  const { partner } = props;
  const { t } = useTranslation();

  return (
    <tr key={"" + partner.id}>
      <IfGroup groups={[Group.BACKOFFICE_USERS]}>
        <td>{partner.id}</td>
      </IfGroup>
      <td>{_.capitalize(partner.name)}</td>
      <td className="align-middle">
        <RowOptionButton
          id={partner.id}
          modelName={"partners"}
          extraOptions={[
            {
              label: t("delete"),
              onClick: () => {
                props.onDelete(props.partner.id);
              },
            },
          ]}
        />
      </td>
    </tr>
  );
}
