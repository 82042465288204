// *---- Imports ----
import React from "react";
import useHeatmapViewModel from "../../../../viewModel/heatMapViewModel";
// *---- Style ----
import styles from "./GraphicScreen.module.css";
// *---- Components ----
import HeatMap from "react-light-heatmap";
import { Spinner } from "reactstrap";
export interface ComponentProps {
  carcheckIds: number[];
  range: number[];
}

const MAX_DAMAGE = 5;

export default function GraphicScreen<Props extends ComponentProps>({
  carcheckIds,
  range,
}: Props) {
  const { data, xLabels, yLabels, isLoading } = useHeatmapViewModel(
    carcheckIds,
    range
  );

  if (isLoading) return <Spinner></Spinner>;

  return (
    <div className={styles.scroll}>
      <div className={styles.container}>
        {data && data.length > 0 && (
          <HeatMap
            xLabels={xLabels}
            yLabels={yLabels}
            data={data}
            cellStyle={(_: any, value: number) => ({
              width: 100,
              background:
                value > 0
                  ? `rgb(244, 66, 66, ${
                      Math.min(MAX_DAMAGE, value) / MAX_DAMAGE
                    })`
                  : "rgb(89, 255, 56)",
            })}
          />
        )}
      </div>
    </div>
  );
}
