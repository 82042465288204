import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import saveAs from "save-as";
import moment from "moment";
import {
  MeetingStatus,
  Status,
  TyreState,
  TyreType,
} from "../Config/datamodels/interfaces";
import { Group } from "../Config/datamodels/interfaces";
import i18n, { TFunction } from "i18next";
import _ from "lodash";

export function capitalize(string: string) {
  if (string)
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function hasPermission(userGroups: string[], permittedGroups: string[]) {
  const userGroupSet = new Set(userGroups);
  const groupIntersection = new Set(
    permittedGroups.filter((group) => userGroupSet.has(group))
  );
  return groupIntersection.size || !permittedGroups.length;
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function safeToUppercase(value: string | undefined) {
  if (value) {
    return value.toUpperCase();
  }
  return undefined;
}

export function yesNoOrUnknown(t: (s: string) => string, is?: boolean | null) {
  if (is === true) return t("Yes");
  else if (is === false) return t("No");
  else return t("Unknown");
}

export function goodBadOrUnknown(
  t: (s: string) => string,
  is?: boolean | null
) {
  if (is === true) return t("Good");
  else if (is === false) return t("Bad");
  else return t("Unknown");
}

export function okNokOrUnknown(t: (s: string) => string, is?: boolean | null) {
  if (is === true) return t("OK");
  else if (is === false) return t("NOK");
  else return t("Not Checked");
}

export const POSITIVE_COLOR_FEEDBACK = "#00AA00";
export const NEGATIVE_COLOR_FEEDBACK = "#EE0000";
export const NEUTRAL_COLOR_FEEDBACK = "#373A3C";
export const ATTENTION_COLOR_FEEDBACK = "#FFA500";

export function goodBadOrUnknownColorFeedback(is?: boolean | null) {
  if (is === true) return POSITIVE_COLOR_FEEDBACK;
  else if (is === false) return NEGATIVE_COLOR_FEEDBACK;
  else return NEUTRAL_COLOR_FEEDBACK;
}

export function tyreStateColor(value?: TyreState) {
  switch (value) {
    case undefined:
      return NEUTRAL_COLOR_FEEDBACK;
    case TyreState.GOOD:
      return POSITIVE_COLOR_FEEDBACK;
    case TyreState.BAD:
      return NEGATIVE_COLOR_FEEDBACK;
    case TyreState.TO_MONITOR:
      return ATTENTION_COLOR_FEEDBACK;
  }
}

export function formatDate(date: Date | string) {
  return moment(date).format("DD/MM/YYYY");
}

export const getFuelLevel = (t: (s: string) => string, value?: number) => {
  switch (value) {
    case undefined:
      return t("Not specified");
    case 1:
      return t("very low (-25%)");
    case 2:
      return t("medium (25%-50%)");
    case 3:
      return t("high (50%-75%)");
    default:
      return t("almost full (75%-100%)");
  }
};

export const getFuelLevelOptions = (t: TFunction) => {
  return [
    { label: t("very low (-25%)"), value: 1 },
    { label: t("medium (25%-50%)"), value: 2 },
    { label: t("high (50%-75%)"), value: 3 },
    { label: t("almost full (75%-100%)"), value: 4 },
  ];
};

export const getTyreTypes = (t: (s: string) => string, value?: TyreType) => {
  switch (value) {
    case undefined:
      return t("Not specified");
    case TyreType.FOURSEASONS:
      return t("Four seasons");
    case TyreType.SUMMER:
      return t("Summer");
    case TyreType.WINTER:
      return t("Winter");
    default:
      return value;
  }
};

export const getTyreState = (t: (s: string) => string, value?: TyreState) => {
  switch (value) {
    case undefined:
      return t("Not specified");
    case TyreState.GOOD:
      return t("Good");
    case TyreState.BAD:
      return t("Bad");
    case TyreState.TO_MONITOR:
      return t("Monitor");
  }
};

export const getTyreStateOptions = (t: (s: string) => string) => {
  return [
    {
      value: TyreState.BAD,
      label: t("Bad condition"),
    },
    {
      value: TyreState.GOOD,
      label: t("Good Condition"),
    },
    {
      value: TyreState.TO_MONITOR,
      label: t("To Monitor"),
    },
  ];
};

export const getTyreTypeOptions = (t: TFunction) => {
  return [
    {
      value: TyreType.SUMMER,
      label: t("Summer"),
    },
    {
      value: TyreType.WINTER,
      label: t("Winter"),
    },
    {
      value: TyreType.FOURSEASONS,
      label: t("Four seasons"),
    },
  ];
};

export const getCleanlinessOptions = (t: TFunction) => {
  return [
    {
      value: true,
      label: t("yes"),
    },
    {
      value: false,
      label: t("no"),
    },
  ];
};

export const getCleanliness = (t: TFunction, value?: boolean) => {
  return value ? t("yes") : t("no");
};

export const getNumberOfKeysOptions = (t: TFunction) => {
  return _.range(0, 5).map((count) => ({ label: "" + count, value: count }));
};

export function getCarcheckType(
  t: (s: string) => string,
  value?: string
): string {
  switch (value) {
    case "exhaustive":
      return t("exhaustive");
    case "periodic":
      return t("periodic");
    case "tyres check":
      return t("tyres check");
    case "damage check":
      return t("damage check");
    case "pool check":
      return t("pool check");
    default:
      return t("exhaustive");
  }
}

export function getGroupTranslationMap() {
  let groupTraduction: any = {};
  groupTraduction[Group.DRIVERS] = i18n.t("Driver");
  groupTraduction[Group.HR_OFFICERS] = i18n.t("HrOfficer");
  groupTraduction[Group.QUOTERS] = i18n.t("Quoter");
  groupTraduction[Group.CARCHECKERS] = i18n.t("Inspector");
  groupTraduction[Group.FLEET_MANAGERS] = i18n.t("Fleet Manager");
  return groupTraduction;
}

export function getOneGroupTranslation(group: Group) {
  return getGroupTranslationMap()[group];
}

export function groupChoices() {
  return [
    { label: getOneGroupTranslation(Group.DRIVERS), value: Group.DRIVERS },
    {
      label: getOneGroupTranslation(Group.HR_OFFICERS),
      value: Group.HR_OFFICERS,
    },
    {
      label: getOneGroupTranslation(Group.CARCHECKERS),
      value: Group.CARCHECKERS,
    },
    { label: getOneGroupTranslation(Group.QUOTERS), value: Group.QUOTERS },
    {
      label: getOneGroupTranslation(Group.FLEET_MANAGERS),
      value: Group.FLEET_MANAGERS,
    },
  ];
}

export function toBoolean(o: any) {
  if (null !== o) {
    let t = typeof o;
    if ("undefined" !== typeof o) {
      if ("string" !== t) return !!o;
      o = o.toLowerCase().trim();
      return "true" === o || "1" === o;
    }
  }
  return false;
}

export function statusBadgeColor() {
  const map: any = {};
  map[Status.DONE] = "success";
  map[Status.PENDING] = "primary";
  map[Status.NOT_SENT] = "secondary";
  map[Status.OVERDUE] = "danger";
  return map;
}

export function statusBadgeText() {
  const map: any = {};
  map[Status.DONE] = i18n.t("Carcheck done");
  map[Status.PENDING] = i18n.t("request pending");
  map[Status.NOT_SENT] = i18n.t("Request not sent");
  map[Status.OVERDUE] = i18n.t("Request overdue");
  return map;
}

export function meetingStatusColor() {
  const map: any = {};
  map[MeetingStatus.MEETING_SCHEDULED] = "success";
  map[MeetingStatus.WAITING_VALIDATOR_SCHEDULE] = "primary";
  map[MeetingStatus.NOT_MEETING_REQUIRED] = "secondary";
  map[MeetingStatus.WAITING_DRIVER_DATE_SELECTION] = "danger";

  return map;
}

export function meetingStatusText() {
  const map: any = {};
  map[MeetingStatus.MEETING_SCHEDULED] = i18n.t("Meeting Scheduled");
  map[MeetingStatus.WAITING_VALIDATOR_SCHEDULE] = i18n.t(
    "Waiting for inspector availability"
  );
  map[MeetingStatus.NOT_MEETING_REQUIRED] = i18n.t(
    "Inspection doesn't require a meeting"
  );
  map[MeetingStatus.WAITING_DRIVER_DATE_SELECTION] = i18n.t(
    "waiting driver to select date"
  );

  return map;
}

export async function zipAndDownload(
  urls: string[],
  informProgressPercentage: (percentage: number) => void | undefined
) {
  const zip = new JSZip();
  const zipFilename = "images.zip";
  for (let i = 0; i < urls.length; i++) {
    const url = urls[i];
    try {
      const urlArr = url.split("/");
      const filename = urlArr[urlArr.length - 1];
      const file = await JSZipUtils.getBinaryContent(url);
      zip.file(filename, file, { binary: true });
      if (informProgressPercentage) {
        informProgressPercentage((i + 1) / urls.length);
      }
    } catch (err) {
      console.log(`error while getting and zipping file ${url}`);
      continue;
    }
  }
  const content = await zip.generateAsync({ type: "blob" });
  saveAs(content, zipFilename);
}
