import { useDispatch, useSelector } from "react-redux";
import importReducer from "../../Common/Store/ImportReducer";
import makeUseImportReducer from "../../Common/Store/ImportReducer/useImportReducer";

export default importReducer("vehicles");
const useImportReducer = makeUseImportReducer(
  "vehicles",
  useSelector,
  useDispatch
);
export { useImportReducer };
