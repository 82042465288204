import DetailReducer from "../../Common/Store/DetailReducer";
import { useDispatch, useSelector } from "react-redux";
import { makeUseDetailReducer } from "../../Common/Store/DetailReducer";

export default DetailReducer("carchecks");
const useDetailReducer = makeUseDetailReducer(
  "carchecks",
  useSelector,
  useDispatch
);
export { useDetailReducer };
