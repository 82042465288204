import moment from "moment";
import { Filter, InputType, FilterType } from "../../Common/Filters";

export abstract class FlagFilter extends Filter {
  constructor(display: string) {
    super("", display, display, undefined, []);
    this._value = display;
    this._isEditable = false;
  }
  public get inputType(): InputType {
    return "text";
  }
  protected get queryType(): string {
    return "";
  }
  protected get queryValue(): string {
    return "";
  }
  public get type(): FilterType {
    return FilterType.TEXT;
  }
  public abstract clone(): Filter;
}

export class NotSentFilter extends FlagFilter {
  public get queryString(): string[] {
    return ["date__gt__s", moment(new Date()).format("YYYY-MM-DD")];
  }
  public clone() {
    return new NotSentFilter(this._display);
  }
}

export class PendingFilter extends FlagFilter {
  public get queryString(): string[] {
    /*
      pending means:
        no carchecks
        today >= date
        appointment_date + 7 >= today

        today >= date -> date <= today
          date__lte__s=today
        appointment_date + 7 >= today -> appointment_date >= today - 7
          appoint_date__gte__s=today - 7
    */
    return [
      "date__lte__s",
      moment(new Date()).format("YYYY-MM-DD"),
      "carcheck__isnull__b",
      "True",
      "appointment_date__gte__s",
      moment().subtract(7, "days").format("YYYY-MM-DD"),
    ];
  }
  public clone() {
    return new PendingFilter(this._display);
  }
}

export class OverdueFilter extends FlagFilter {
  /*
  overdue means:
    no carchecks
    today > appointment_date + 7
       appointment_date + 7 < today
       appointment_date < today - 7
       appointment_date__lt__s=today - 7
  */
  public get queryString(): string[] {
    return [
      "carcheck__isnull__b",
      "True",
      "appointment_date__lt__s",
      moment().subtract(7, "days").format("YYYY-MM-DD"),
    ];
  }
  public clone() {
    return new OverdueFilter(this._display);
  }
}

export class DoneFilter extends FlagFilter {
  public get queryString(): string[] {
    return ["carcheck__isnull__b", "False"];
  }
  public clone() {
    return new DoneFilter(this._display);
  }
}
