import React, { useMemo } from "react";
import { Group, Vehicle } from "../../../Config/datamodels/interfaces";
import ListView from "../../../Common/Components/ListView";
import ListItem from "./ListItem";
import { useTranslation } from "react-i18next";
import vehicleListHeader from "./listHeaders";
import { Path } from "../../../Config/Router/utils";
import useAuthViewModel from "../../../auth/viewModel/authViewModel";
import listViewModel from "../../viewModel/listViewModel";
import useFields from "./useFields";
import { ListRequestInterceptor } from "../../../Common/ViewModels/makeUseListViewModel";
import { ListServiceArguments } from "../../../Common/Services/types";
import { hasPermission } from "../../../Utils/utils";
import { IntFilter } from "../../../Common/Filters";
import { useDependenciesDebugger } from "../../../Utils/useDependencyDebugger";

const makeRequestInterceptor = (
  groups: Group[],
  userId: string | number
): ListRequestInterceptor => (args: ListServiceArguments) => {
  const filters = [...args.filters];
  if (
    !hasPermission(groups, [
      Group.BACKOFFICE_USERS,
      Group.FLEET_MANAGERS,
      Group.CARCHECKERS,
      Group.QUOTERS,
      Group.HR_OFFICERS,
    ])
  ) {
    const driverFilter = new IntFilter("driver__id", "");
    driverFilter.value = userId;
    filters.push(driverFilter);
  }
  return Promise.resolve({ ...args, filters });
};

export default function ListScreen() {
  const { groups, user } = useAuthViewModel();
  useDependenciesDebugger([groups, user.id]);
  const requestInterceptor = useMemo(
    () => makeRequestInterceptor(groups, user.id),
    [groups, user.id]
  );
  const listData = listViewModel(requestInterceptor);
  const { filterableFields, orderableFields } = useFields();
  const { t } = useTranslation();
  const headers = vehicleListHeader(t, groups);
  return (
    <ListView<Vehicle>
      hasImport
      title={t("Vehicles")}
      emptyListTitle={t("You haven't create any vehicle yet")}
      emptyListDescription={t(
        "You can create new vehicles one by one or import all your fleet pressing the import button"
      )}
      listData={listData}
      basePath={Path.VEHICLES}
      navigationFormButtonLabel={t("Add New Vehicle")}
      headers={headers}
      renderLine={(vehicle: Vehicle) => (
        <ListItem vehicle={vehicle} modelName="vehicles" />
      )}
      availableFilters={filterableFields}
      orderableFields={orderableFields}
    />
  );
}
