// *---- Imports ----
import React from "react";
import { MeetingStatus, Status } from "../../../Config/datamodels/interfaces";

// *---- Components ----
import { Badge } from "reactstrap";
import {
  meetingStatusColor,
  meetingStatusText,
  statusBadgeColor,
  statusBadgeText,
} from "../../../Utils/utils";

export interface ComponentProps {
  status: Status | MeetingStatus;
}
export function StatusBadge<Props extends ComponentProps>(props: Props) {
  function getColor() {
    const map: any = statusBadgeColor();
    return map[props.status];
  }

  function getStatusText() {
    const map: any = statusBadgeText();
    return map[props.status];
  }

  return (
    <h3>
      <Badge color={getColor()}>{getStatusText()}</Badge>
    </h3>
  );
}

export function MeetingStatusBadge<Props extends ComponentProps>(props: Props) {
  function getColor() {
    const map: any = meetingStatusColor();

    return map[props.status];
  }

  function getStatusText() {
    const map: any = meetingStatusText();

    return map[props.status];
  }

  return (
    <h3>
      <Badge color={getColor()}>{getStatusText()}</Badge>
    </h3>
  );
}
