import { Model } from "../../../Config/datamodels/interfaces";
import { RESTQueryBuilder } from "../../ApiGateway/buildRESTUrl";
import { HTTPPatchService } from "../types";

const makeRESTUpdateService = <ModelType extends Model>(
  modelName: string,
  urlBuilder: RESTQueryBuilder,
  patchService: HTTPPatchService<ModelType>
) => async (body: ModelType, id: number | string) => {
  const url = urlBuilder({ resource: modelName, id });
  const response = await patchService.patch(url, body);
  return response.data;
};

export default makeRESTUpdateService;
