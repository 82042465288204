import Axios from "axios";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";
import makeRESTListService from "../../Common/Services/ListService/RESTListService";
import { CarcheckRequest } from "../../Config/datamodels/interfaces";

const listService = makeRESTListService<CarcheckRequest>(
  "requests",
  buildRESTUrl,
  Axios
);
export default listService;
