import React from "react";
import Paginator from "../Paginator";
import { Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import ListBody from "./ListBody";
import ListOrder from "./ListOrder";
import Search from "../Search";
import Filters from "../Filters";
import AddFilter from "../AddFilter";
import { Model, Group } from "../../../Config/datamodels/interfaces";
import { Filter } from "../../Filters";
import { Path } from "../../../Config/Router/utils";
import { useTranslation } from "react-i18next";
import styles from "./ListView.module.css";
import Header from "../Header";
import IfGroup from "../../../auth/components/IfGroup";
import { ListViewModelReturn } from "../../ViewModels/makeUseListViewModel";

interface ListViewProps<ModelType extends Model> {
  listData: ListViewModelReturn<ModelType>;
  title: string;
  renderLine: (data: ModelType) => JSX.Element;
  headers: string[];
  basePath?: Path;
  navigationFormButtonLabel?: string;
  hasImport?: boolean;
  emptyListTitle?: string;
  emptyListDescription?: string;
  orderableFields: { name: string; display: string }[];
  availableFilters: Filter[];
}

export default function <ModelType extends Model>(
  props: ListViewProps<ModelType>
) {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    data,
    currentPage,
    listPageSize,
    maxPage,
    currentOrderingBy,
    currentDescending,
    currentSearch,
    currentFilters,
    isLoading,
    setOrdering,
    setFilters,
    setPage,
    setSearch,
  } = props.listData;

  const onAddFilter = (filter: Filter) =>
    setFilters([...currentFilters, filter]);

  return (
    <div>
      <Header title={props.title}>
        {props.basePath ? (
          <IfGroup groups={[Group.FLEET_MANAGERS, Group.BACKOFFICE_USERS]}>
            <div className={styles.buttonsContainer}>
              {props.hasImport && (
                <Button
                  className={styles.formNavigationButton}
                  onClick={() => history.push(`${props.basePath}/import`)}
                  color="secondary"
                >
                  {t("Import multiple")}
                </Button>
              )}
              {props.navigationFormButtonLabel && (
                <Button
                  className={styles.formNavigationButton}
                  onClick={() => history.push(`${props.basePath}/form`)}
                  color="primary"
                >
                  {props.navigationFormButtonLabel}
                </Button>
              )}
            </div>
          </IfGroup>
        ) : (
          <></>
        )}
      </Header>
      <div className={styles.options}>
        <div className={styles.quickoptions}>
          <ListOrder
            onOrderBy={setOrdering}
            currentOrdering={currentOrderingBy}
            currentDescending={currentDescending}
            fields={props.orderableFields}
          />
          <div className={styles.rightoptions}>
            <AddFilter
              possibleFilters={props.availableFilters}
              onChange={onAddFilter}
            />
            <Search value={currentSearch} onChange={setSearch} />
          </div>
        </div>
        <Filters filters={currentFilters} onChangeFilters={setFilters} />
      </div>
      <div className={styles.relative}>
        <div className={isLoading ? styles.loadingOverlay : styles.hidden} />
        {data && data.length !== 0 && (
          <ListBody
            data={data}
            headers={props.headers}
            renderLine={props.renderLine}
          />
        )}
      </div>
      <div className={styles.pagination}>
        <div className={styles.spacer} />
        <Paginator
          pageCount={maxPage}
          currentPage={currentPage}
          maxPageAtOnce={listPageSize}
          onPageClicked={setPage}
        />
        <div className={styles.spacer} />
      </div>
    </div>
  );
}
