// *---- Imports ----
import React from "react";
import { InputType } from "../../../Config/datamodels/types";
// *---- Style ----
import styles from "./Selectors.module.css";
// *---- Components ----
import CardSeparatorTitle from "../CardSeparatorTitle";
import HideOnPrint from "../HideOnPrint";
import { Selector, SelectorValues } from "../Selector";

export interface SelectorWrapperProps {
  title: string;
  selectors: SelectorValues[];
  onChange: (event: any) => void;
  shouldWrap?: boolean;
  disabled?: boolean;
}

export interface SelectorAsValueProps {
  inputType?: InputType;
  value: any;
}

export default function SelectorWrapper<Props extends SelectorWrapperProps>({
  title,
  selectors,
  onChange,
  shouldWrap,
  disabled,
}: Props) {
  return (
    <>
      <CardSeparatorTitle>{title}</CardSeparatorTitle>
      <div className={styles.selectorsContainer}>
        {selectors.map((selector, index) => {
          const selectorElement = (
            <Selector
              selector={selector}
              key={index}
              disabled={disabled}
              shouldWrap={shouldWrap}
              onChange={onChange}
            />
          );
          return selector.hideOnPrint ? (
            <HideOnPrint>{selectorElement}</HideOnPrint>
          ) : (
            selectorElement
          );
        })}
      </div>
    </>
  );
}
