import { useDispatch, useSelector } from "react-redux";
import detailReducer, {
  makeUseDetailReducer,
} from "../../Common/Store/DetailReducer";

export default detailReducer("vehicles");
const useDetailReducer = makeUseDetailReducer(
  "vehicles",
  useSelector,
  useDispatch
);
export { useDetailReducer };
