import React, { useEffect, useRef, useState } from "react";
import { Range as VendorRange, Marks } from "rc-slider";
import styles from "./Range.module.css";
import useMousePositionTracker from "../../../../../Common/Hooks/useMousePositionTracker";
import { useDebouncedCallback } from "use-debounce/lib";
import isPointInRect from "./utils";

export interface RangeProps {
  onChange: (numbers: number[]) => void;
  max: number;
  defaultRangePositions: number[];
  marks: Marks;
}

const SCROLL_ZONE_PERCENTAGE = 0.1;
const SCROLL_DISTANCE = 25;
const SCROLL_RATE = 100;

export default function Range(props: RangeProps) {
  const [scrollDown, setScrollDown] = useState<boolean>(false);
  const [scrollUp, setScrollUp] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const debouncedCallback = useDebouncedCallback(props.onChange, 200);

  useEffect(() => {
    if (scrollUp) {
      const interval = setInterval(() => {
        if (wrapperRef.current) {
          const offset = wrapperRef.current.scrollTop - SCROLL_DISTANCE;
          wrapperRef.current.scrollTo(0, offset);
        }
      }, SCROLL_RATE);
      return () => clearInterval(interval);
    }
  }, [scrollUp]);

  useEffect(() => {
    if (scrollDown) {
      const interval = setInterval(() => {
        if (wrapperRef.current) {
          const offset = wrapperRef.current.scrollTop + SCROLL_DISTANCE;
          wrapperRef.current.scrollTo(0, offset);
        }
      }, SCROLL_RATE);
      return () => clearInterval(interval);
    }
  }, [scrollDown]);

  const clear = () => {
    setScrollUp(false);
    setScrollDown(false);
  };

  const onScroll = (ev: any) => {
    if (wrapperRef.current) {
      if (!!ev.button || !!ev.buttons) {
        const scrollZone = wrapperRef.current.getBoundingClientRect();
        const mousePosition = { x: ev.pageX, y: ev.pageY };

        const scrollTopZone = {
          x: scrollZone.left,
          y: scrollZone.top,
          w: scrollZone.width,
          h: scrollZone.height * SCROLL_ZONE_PERCENTAGE,
        };
        const scrollBottomZone = {
          x: scrollZone.left,
          y: scrollZone.bottom,
          w: scrollZone.width,
          h: scrollZone.height * SCROLL_ZONE_PERCENTAGE,
        };
        scrollBottomZone.y -= scrollBottomZone.h;

        if (isPointInRect(mousePosition, scrollTopZone)) {
          if (!scrollUp) {
            setScrollUp(true);
          }
        } else if (isPointInRect(mousePosition, scrollBottomZone)) {
          setScrollDown(true);
        } else {
          clear();
        }
      } else {
        clear();
      }
    }
  };

  useMousePositionTracker(onScroll, [wrapperRef]);

  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      <div
        className={styles.scrollableZone}
        style={{ height: "" + props.max * 20 + "px" }}
      >
        <VendorRange
          onChange={debouncedCallback.callback}
          trackStyle={[{ backgroundColor: "#0275D8" }]}
          handleStyle={[{ borderColor: "#0275D8" }, { borderColor: "#0275D8" }]}
          activeDotStyle={{ borderColor: "#0275D8" }}
          min={0}
          max={props.max}
          allowCross={false}
          defaultValue={[
            props.defaultRangePositions[1],
            props.defaultRangePositions[0],
          ]}
          marks={props.marks}
          vertical={true}
        />
        {/* the defaultRangePositions needs to be inverted to show correct position on range */}
      </div>
    </div>
  );
}
