import _ from "lodash";

import {
  Damage,
  DamageInPart,
  PartWithDamage,
} from "../../Config/datamodels/interfaces";

export interface PartsMap {
  [key: number]: PartWithDamage;
}

export function selectGraphicDamagesPerParts(
  damages: Damage[] | undefined,
  carcheckId: number | undefined = undefined
) {
  if (damages) {
    let partsMap: PartsMap = {};
    for (let index = 0; index < damages.length; index++) {
      if (!isDamageRepairedInGivenCarcheck(damages[index], carcheckId)) {
        _registerOrUpdatePartInMap(partsMap, damages[index], index);
      }
    }
    return _.values(partsMap);
  } else {
    return [];
  }
}

function _registerOrUpdatePartInMap(
  partsMap: PartsMap,
  damage: Damage,
  damagePositionInReducer: number
) {
  const part = damage.part;
  let addedDamage = {
    ...damage,
    position: damagePositionInReducer,
  } as DamageInPart;
  if (part.id in partsMap) {
    partsMap[part.id]["damages"].push(addedDamage);
  } else {
    partsMap[part.id] = { ...part, damages: [addedDamage] };
  }
}

export function selectDamagesPerParts(damages: Damage[] | undefined) {
  if (damages) {
    let partsMap: PartsMap = {};
    for (let index = 0; index < damages.length; index++) {
      _registerOrUpdatePartInMap(partsMap, damages[index], index);
    }
    return _.values(partsMap);
  } else {
    return [];
  }
}

export function isDamageRepairedInGivenCarcheck(
  damage: Damage,
  carcheckId: number | string | undefined
) {
  if (damage.is_repaired) {
    const lastCarcheckPresence = Math.max(...damage.carcheck_presence);
    return lastCarcheckPresence?.toString() === carcheckId?.toString();
  }
  return false;
}
