import { Filter, IntFilter, StringListFilter } from "../../Common/Filters";
import makeListViewModel from "../../Common/ViewModels/makeUseListViewModel";
import { Carcheck } from "../../Config/datamodels/interfaces";
import selectorListService from "../services/selectorListService";
import { useSelectorReducer } from "../store/selectorReducer";

const baseSelectorViewModel = makeListViewModel<Carcheck>(
  selectorListService,
  useSelectorReducer
);

const useSelectorViewModel = (
  vehicleId?: string | number,
  participantEmail?: string | number
) => {
  const listData = baseSelectorViewModel();

  const load = () => {
    const vehicleFilter = new IntFilter("vehicle__id", "");
    vehicleFilter.value = vehicleId;
    const filters: Filter[] = [vehicleFilter];
    if (participantEmail) {
      const participantFilter = new StringListFilter(
        "participation__user__email",
        ""
      );
      participantFilter.value = participantEmail;
      filters.push(participantFilter);
    }
    listData.setFilters(filters);
  };

  return { ...listData, load };
};

export default useSelectorViewModel;
