import _ from "lodash";
import React from "react";
import { Vehicle, Group } from "../../../Config/datamodels/interfaces";
import DocumentLink from "../../../Common/Components/DocumentLink";
import Highlight from "../../../Common/Components/Highlight";
import IfGroup from "../../../auth/components/IfGroup";
import Thumbnail, { ThumbnailSize } from "../../../Common/Components/Thumbnail";
import RowOptionButton from "../../../Common/Components/RowOptionButton";
import { Path } from "../../../Config/Router/utils";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
export function Picture(props: { vehicle: Vehicle }) {
  return props.vehicle.picture ? (
    <DocumentLink resource="vehicles" id={props.vehicle.id}>
      <Thumbnail
        src={props.vehicle.picture.xsmall}
        size={ThumbnailSize.xsmall}
      />
    </DocumentLink>
  ) : null;
}

export function LeasingPeriod(props: { vehicle: Vehicle }) {
  const { vehicle } = props;
  if (!vehicle.leasing_start_date || !vehicle.leasing_end_date) {
    return null;
  }
  return (
    <span>
      {new Date(vehicle.leasing_start_date).toLocaleDateString()} :
      {new Date(vehicle.leasing_end_date).toLocaleDateString()}
    </span>
  );
}

export default function VehicleListItem({
  vehicle,
  modelName,
}: {
  vehicle: Vehicle;
  modelName: string;
}) {
  const history = useHistory();
  const { t } = useTranslation();
  const editLabel = t("Edit");
  const reportingLabel = t("Reporting");
  return (
    <tr key={vehicle.id}>
      <td className="align-middle">
        <Picture vehicle={vehicle} />
      </td>
      <IfGroup groups={[Group.BACKOFFICE_USERS]}>
        <td className="align-middle">{vehicle.id}</td>
      </IfGroup>
      <IfGroup groups={[Group.BACKOFFICE_USERS]}>
        <td className="align-middle">{"" + vehicle.type.company}</td>
      </IfGroup>
      <td className="align-middle">
        <Highlight>
          <DocumentLink resource="vehicles" id={vehicle.id}>
            {vehicle.vin}
          </DocumentLink>
        </Highlight>
      </td>
      <td className="align-middle">
        {vehicle.driver && (
          <DocumentLink resource="users" id={vehicle.driver.id}>
            <Highlight>{vehicle.driver.email}</Highlight>
          </DocumentLink>
        )}
      </td>
      <td className="align-middle">
        {vehicle.plate && <Highlight>{vehicle.plate}</Highlight>}
      </td>
      <td className="align-middle">
        <Highlight>{vehicle.brand}</Highlight> | {vehicle.model}
      </td>
      <td className="align-middle">{vehicle.km_contract}</td>
      <td className="align-middle">{vehicle.mileage}</td>
      <td className="align-middle">{vehicle.year}</td>
      <td className="align-middle">{vehicle.type.name}</td>
      <td className="align-middle">
        {_.get(vehicle, "owning_partner.name", "")}
      </td>
      <td className="align-middle">
        <LeasingPeriod vehicle={vehicle} />
      </td>
      <td className="align-middle">{vehicle.department?.name}</td>
      <td className="align-middle">
        <RowOptionButton
          id={vehicle.id}
          modelName={modelName}
          extraOptions={[
            {
              label: editLabel,
              onClick: () => history.push(`/${modelName}/${vehicle.id}/form`),
            },
            {
              label: reportingLabel,
              onClick: () => history.push(`${Path.REPORTING}/${vehicle.id}/`),
            },
          ]}
        />
      </td>
    </tr>
  );
}
