import React from "react";
import { EditableKeyValue } from "../../../../../Common/Components/KeyValueTextInput";
import {
  Carcheck,
  CarcheckType,
} from "../../../../../Config/datamodels/interfaces";
import { useTranslation } from "react-i18next";
import { getFuelLevel, getFuelLevelOptions } from "../../../../../Utils/utils";
import { isUndefined } from "lodash";
import { carcheck } from "../../../../converters/__fixtures__/carcheck.fixture";
import { IfCarcheck } from "../../../../../Common/Components/IfCarcheck";

export interface FuelLevelSectionProps {
  carcheck: Carcheck;
  onChange: (fieldName: string, value: any) => void;
  disable?: boolean;
}

export function FuelLevelSection(props: FuelLevelSectionProps) {
  const { t } = useTranslation();
  return (
    <IfCarcheck
      types={[
        CarcheckType.EXHAUSTIVE,
        CarcheckType.RENTA,
        CarcheckType.POOL_CHECK,
      ]}
      currentType={props.carcheck.carcheck_type}
    >
      <EditableKeyValue
        disabled={props.disable}
        fieldName={"fuel"}
        title={t("Fuel level")}
        onChange={(event) => props.onChange("fuel_level", event.target.value)}
        value={props.carcheck.fuel_level}
        options={getFuelLevelOptions(t)}
        hide={isUndefined(props.carcheck.fuel_level)}
        readonly={props.carcheck.freeze_update}
      >
        {getFuelLevel(t, carcheck.fuel_level)}
      </EditableKeyValue>
    </IfCarcheck>
  );
}
