import { RuleTypes } from "../../../../../../Config/datamodels/interfaces";

export interface Rule {
  name: RuleTypes;
  timeValue: string;
  unitOf: string;
}

function unitRange(min: number, max: number) {
  let valueList = [];
  for (let i = min; i < max; ++i) {
    valueList.push({ value: i.toString(), label: `${i}` });
  }
  return valueList;
}

export function numberLabelValueChoices(rule: Rule) {
  const { timeValue, name } = rule;
  if (timeValue === "0" && name === RuleTypes.CRON) {
    return unitRange(7, 8);
  }
  switch (timeValue) {
    case "0":
      return unitRange(1, 31);

    case "1":
      return unitRange(1, 5);

    case "2":
      return unitRange(1, 13);

    default:
      return unitRange(1, 2);
  }
}
