import makeListViewModel from "../../Common/ViewModels/makeUseListViewModel";
import { useListReducer } from "../store/listReducer";
import listService from "../services/ListService";
import { User } from "../../Config/datamodels/interfaces";
import deleteService from "../services/DeleteService";

export default makeListViewModel<User>(
  listService,
  useListReducer,
  deleteService
);
