// *---- Imports ----
import React from "react";
import { Image } from "../../../Config/datamodels/interfaces";

// *---- Style ----
import styles from "./Images.module.css";
// *---- Components ----
import CardSeparatorTitle from "../CardSeparatorTitle";
import ModalImage from "../ModalImage";

export interface ImageProps {
  source: string;
}

export interface ImagesWrapperProps {
  title?: string;
  images: (Image | null)[];
}

export default function ImagesWrapper({ title, images }: ImagesWrapperProps) {
  return (
    <>
      {title && <CardSeparatorTitle>{title}</CardSeparatorTitle>}
      <div className={styles.imageWrapper}>
        {images.map((image: Image | null, index: number) => {
          if (image) {
            return <ModalImage key={index} image={image} />;
          }
          return <React.Fragment key={index} />;
        })}
      </div>
    </>
  );
}
