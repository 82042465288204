import React from "react";
import { useTranslation } from "react-i18next";
import KeyValueText from "../../../../Common/Components/KeyValueText";
import style from "./PriceOverview.module.css";

export interface PriceOverviewProps {
  totalAcceptableCosts: number;
  totalNotAcceptableCostst: number;
}

export default function PriceOverview(props: PriceOverviewProps) {
  const { t } = useTranslation();

  return (
    <div className={style.wrapper}>
      <KeyValueText title={t("Total acceptable cost")}>
        {props.totalAcceptableCosts} €
      </KeyValueText>
      <KeyValueText title={t("Total not acceptable cost")}>
        {props.totalNotAcceptableCostst} €
      </KeyValueText>
    </div>
  );
}
