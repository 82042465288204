import React from "react";
import { hasPermission } from "../../../Utils/utils";
import useAuthViewModel from "../../viewModel/authViewModel";

export interface IfGroupProps {
  groups: string[];
  children: JSX.Element[] | JSX.Element | string;
}

export default function IfGroup(props: IfGroupProps) {
  const { groups } = useAuthViewModel();
  if (hasPermission(groups, props.groups)) {
    return <>{props.children}</>;
  } else {
    return null;
  }
}
