import Axios from "axios";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";
import makeRESTListService from "../../Common/Services/ListService/RESTListService";
import { Partner } from "../../Config/datamodels/interfaces";

const listService = makeRESTListService<Partner>(
  "partners",
  buildRESTUrl,
  Axios
);
export default listService;
