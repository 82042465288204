import React from "react";
import {
  CarcheckRequest,
  Group,
  Status,
} from "../../../Config/datamodels/interfaces";
import DocumentLink from "../../../Common/Components/DocumentLink";
import Emphasize from "../../../Common/Components/Emphasize";
import Highlight from "../../../Common/Components/Highlight";
import Thumbnail, { ThumbnailSize } from "../../../Common/Components/Thumbnail";
import IfGroup from "../../../auth/components/IfGroup";
import { StatusBadge, MeetingStatusBadge } from "./StatusBadge";
import ReminderButton from "./ReminderButton";
import RowOptionButton from "../../../Common/Components/RowOptionButton";
import { useTranslation } from "react-i18next";

export default function Request(props: {
  carcheckRequest: CarcheckRequest;
  sendReminder: (id: string | number) => Promise<void>;
  onDelete: (id: number) => void;
}) {
  const { carcheckRequest, sendReminder } = props;
  const { t } = useTranslation();

  return (
    <tr key={"" + carcheckRequest.id}>
      <IfGroup groups={[Group.BACKOFFICE_USERS]}>
        <td>{"" + carcheckRequest.id}</td>
      </IfGroup>
      <td>
        <Highlight>{carcheckRequest.carcheck_type}</Highlight>
      </td>
      <td>{new Date(carcheckRequest.appointment_date).toDateString()}</td>
      <td>
        <DocumentLink resource="vehicles" id={carcheckRequest.vehicle.id}>
          <Highlight>{carcheckRequest.vehicle.vin}</Highlight>
          <Emphasize>
            <span>
              {" " + carcheckRequest.vehicle.brand} |{" "}
              {carcheckRequest.vehicle.model}
            </span>
          </Emphasize>
        </DocumentLink>
      </td>

      <td>
        <DocumentLink resource="vehicles" id={carcheckRequest.vehicle.id}>
          <Highlight>{carcheckRequest.vehicle.plate}</Highlight>
        </DocumentLink>
      </td>

      <td>
        {carcheckRequest.user && (
          <DocumentLink resource="users" id={carcheckRequest.user.id}>
            <div className="d-flex align-items-center">
              {carcheckRequest.user &&
                carcheckRequest.user.profile &&
                carcheckRequest.user.profile.profile_picture && (
                  <Thumbnail
                    src={carcheckRequest.user.profile.profile_picture.xsmall}
                    size={ThumbnailSize.xsmall}
                  />
                )}
              <span className="ml-2">{carcheckRequest.user.email}</span>
            </div>
          </DocumentLink>
        )}
      </td>
      <td>
        <StatusBadge status={carcheckRequest.status} />
      </td>
      <td>
        <MeetingStatusBadge status={carcheckRequest.meeting_status} />
      </td>
      <td>
        {carcheckRequest.status === Status.OVERDUE &&
          !carcheckRequest.is_reminder_sent && (
            <ReminderButton onClick={() => sendReminder(carcheckRequest.id)} />
          )}
      </td>
      <IfGroup groups={[Group.BACKOFFICE_USERS, Group.FLEET_MANAGERS]}>
        <td className={"align-middle"}>
          <RowOptionButton
            extraOptions={[
              {
                label: t("delete"),
                onClick: () => props.onDelete(props.carcheckRequest.id),
                disabled: !!props.carcheckRequest.carcheck,
              },
            ]}
          />
        </td>
      </IfGroup>
    </tr>
  );
}
