import Axios from "axios";
import { Image } from "../../../Config/datamodels/interfaces";
import { RESTQueryBuilder } from "../../ApiGateway/buildRESTUrl";
import { HTTPPostService } from "../types";

export class InvalidImageFileType extends Error {}
export class ImportFailed extends Error {}

export interface InitUploadResponse {
  image: any;
  upload: {
    url: string;
    fields: any;
  };
}

const makeImageUploadService = (
  urlBuilder: RESTQueryBuilder,
  postService: HTTPPostService<any>
) => async (file: any) => {
  if (
    !file.name.endsWith(".png") &&
    !file.name.endsWith(".jpg") &&
    !file.name.endsWith(".jpeg")
  )
    throw new InvalidImageFileType(file.name);
  try {
    const signedUpload: InitUploadResponse = await initUpload(
      file.name,
      urlBuilder,
      postService
    );
    await directUpload(file, signedUpload, postService);
    await finalizeUpload(signedUpload, postService, urlBuilder);
    return signedUpload.image;
  } catch (error) {
    throw new ImportFailed();
  }
};

export const makeInitUploadUrl = (urlBuilder: RESTQueryBuilder) => {
  return urlBuilder({ resource: "images", action: "init_upload" });
};

export const makefinalizeUploadUrl = (
  urlBuilder: RESTQueryBuilder,
  imageId: number
) => {
  return urlBuilder({
    resource: "images",
    action: `${imageId}/finalize_upload`,
  });
};

export const initUpload = async (
  filename: string,
  urlBuilder: RESTQueryBuilder,
  postService: HTTPPostService<InitUploadResponse>
): Promise<InitUploadResponse> => {
  const { data } = await postService.post(makeInitUploadUrl(urlBuilder), {
    filename,
  });
  return data;
};

export const directUpload = async (
  file: any,
  uploadSpec: InitUploadResponse,
  postService: HTTPPostService<void>
) => {
  // @ts-ignore specific case here where we recreate an axios instance
  const newPostService = postService.create();
  const url = uploadSpec.upload.url;
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  const body = new FormData();
  // fields from presigned request
  for (const fieldName in uploadSpec.upload.fields) {
    body.append(fieldName, uploadSpec.upload.fields[fieldName]);
  }
  // add file as well
  body.append("file", file);
  const response = await newPostService.post(url, body, { headers });
  return response.data;
};
export default makeImageUploadService;

export const finalizeUpload = async (
  uploadSpec: InitUploadResponse,
  postService: HTTPPostService<void>,
  urlBuilder: RESTQueryBuilder
) => {
  await postService.post(
    makefinalizeUploadUrl(urlBuilder, uploadSpec.image.id),
    {}
  );
};

export const waitForImageToBeAvailable = async (image: Image) => {
  await headOrRetry(image.medium);
};

export const wait = (time: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, time);
  });

export const headOrRetry = async (
  url: string,
  timeout: number = 20000,
  retryMax: number = 20,
  current: number = 0
): Promise<void> => {
  try {
    const axios = Axios.create();
    await axios.head(url);
  } catch (e) {
    const count = current + 1;
    if (count > retryMax) {
      throw e;
    } else {
      await wait(timeout / retryMax);
      return await headOrRetry(url, timeout, retryMax, count);
    }
  }
};
