import { useDispatch, useSelector } from "react-redux";
import listReducer from "../../Common/Store/ListReducer";
import makeUseListReducer from "../../Common/Store/ListReducer/useListReducer";

const heatmapreducer = listReducer("heatmap");

export default heatmapreducer;
const useHeatmapReducer = makeUseListReducer(
  "heatmap",
  useSelector,
  useDispatch
);
export { useHeatmapReducer };
