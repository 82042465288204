import { RESTQueryBuilder } from "../../Common/ApiGateway/buildRESTUrl";
import { HTTPPostService } from "../../Common/Services/types";
import { ConfirmPassword } from "../../Config/datamodels/interfaces";

export type ActivateService = (passwords: ConfirmPassword) => Promise<void>;

const makeActivateservice = (
  urlBuilder: RESTQueryBuilder,
  postService: HTTPPostService<void>
) => async (passwords: ConfirmPassword) => {
  const url = urlBuilder({ resource: "users", action: "activate" });
  await postService.post(url, passwords);
};

export default makeActivateservice;
