import {
  Carcheck,
  CarcheckType,
} from "../../../../../Config/datamodels/interfaces";
import { useTranslation } from "react-i18next";
import { isUndefined } from "lodash";
import { getTyreTypeOptions, getTyreTypes } from "../../../../../Utils/utils";
import React from "react";
import { EditableKeyValue } from "../../../../../Common/Components/KeyValueTextInput";
import { IfCarcheck } from "../../../../../Common/Components/IfCarcheck";

export interface TyreTypeSectionProps {
  carcheck: Carcheck;
  onChange: (fieldName: string, value: any) => void;
  disable?: boolean;
}

export function TyreTypeSection(props: TyreTypeSectionProps) {
  const { t } = useTranslation();
  return (
    <IfCarcheck
      types={[
        CarcheckType.EXHAUSTIVE,
        CarcheckType.PERIODIC,
        CarcheckType.TYRES_CHECK,
        CarcheckType.RENTA,
      ]}
      currentType={props.carcheck.carcheck_type}
    >
      <EditableKeyValue
        disabled={props.disable}
        fieldName={"back_tyres_type"}
        title={t("Tyre types")}
        onChange={(event) =>
          props.onChange("back_tyres_type", event.target.value)
        }
        value={props.carcheck.back_tyres_type}
        options={getTyreTypeOptions(t)}
        hide={isUndefined(props.carcheck.back_tyres_type)}
        readonly={props.carcheck.freeze_update}
      >
        {getTyreTypes(t, props.carcheck.back_tyres_type)}
      </EditableKeyValue>
    </IfCarcheck>
  );
}
