import React, { PropsWithChildren } from "react";
import styles from "./Section.module.css";

export interface SectionProps extends PropsWithChildren<{}> {
  title: string;
}

export default function Section(props: SectionProps) {
  return (
    <>
      <div className={styles.pagebreak} />
      <div className={styles.section}>
        <div className={styles.header}>
          <div className={styles.title}>{props.title}</div>
        </div>
        {props.children}
      </div>
    </>
  );
}
