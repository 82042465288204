import { useDispatch, useSelector } from "react-redux";
import importReducer from "../../Common/Store/ImportReducer";
import makeUseImportReducer from "../../Common/Store/ImportReducer/useImportReducer";

export default importReducer("users");
const useImportReducer = makeUseImportReducer(
  "users",
  useSelector,
  useDispatch
);
export { useImportReducer };
