// @ts-nocheck
import React from "react";

// *---- Imported Components ----
import { Form, Field } from "react-final-form";
import { Input, FormGroup } from "reactstrap";

// *---- Styles ----
import styles from "./DoubleInputForm.module.css";

// *---- Interface and Types ----
import { InputType } from "../../../Config/datamodels/types";

interface DoubleFormProps {
  onsubmit: (inputValues: any, history: any) => void;
  validate: (value: any) => void;
  fields: FormField[];
  children: React.ReactNode;
}

export interface FormField {
  name: string;
  type: InputType;
  placeholder: string;
}

export default function DoubleInputForm<Props extends DoubleFormProps>({
  onsubmit,
  validate,
  fields,
  children,
}: Props) {
  /**
   * Main react login component.
   *
   */

  // *---- Component ----

  return (
    <Form
      onSubmit={onsubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form id="doubleFieldForm" onSubmit={handleSubmit}>
          {fields.map((field, index) => {
            return (
              <Field key={index} name={field.name}>
                {(props) => (
                  <FormGroup>
                    <Input
                      type={field.type}
                      placeholder={field.placeholder}
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                    />
                    {props.meta.error && props.meta.touched && (
                      <span className={styles.errorMessage}>
                        {props.meta.error}
                      </span>
                    )}
                  </FormGroup>
                )}
              </Field>
            );
          })}
          {children}
        </form>
      )}
    />
  );
}
