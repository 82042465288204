import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import SquareThumbnail from "../../../../Common/Components/SquareThumbnail";
import { ThumbnailSize } from "../../../../Common/Components/Thumbnail";
import { isDamageRepairedInGivenCarcheck } from "../../../../Common/Converters/isDamageRepaired";
import { Damage, DamageType } from "../../../../Config/datamodels/interfaces";
import { LanguageContext } from "../../../../Config/i18n/LanguageGate";
import { formatDate, yesNoOrUnknown } from "../../../../Utils/utils";
import styles from "./SingleDamageSection.module.css";

export interface SingleDamageSectionContentProps {
  damage: Damage;
  damageType: DamageType;
  displayPrices: boolean;
  carcheckId: number;
}

export function FixedBadge() {
  const { t } = useTranslation();
  return (
    <div className={styles.badgewrapper}>
      <div className={styles.fixedbadge}>{t("Fixed")}</div>
    </div>
  );
}

export default function SingleDamageSectionContent(
  props: SingleDamageSectionContentProps
) {
  const { t } = useTranslation();
  const { lang } = useContext(LanguageContext);

  return (
    <div className={styles.wrapper}>
      {isDamageRepairedInGivenCarcheck(props.damage, props.carcheckId) && (
        <FixedBadge />
      )}
      <div className={styles.contentwrapper}>
        <div className={styles.pictures}>
          {props.damage.pictures.map((picture, index) => (
            <div className={styles.picture} key={index}>
              <SquareThumbnail
                src={picture.xsmall}
                size={ThumbnailSize.smallmedium}
              />
            </div>
          ))}
        </div>
        <div className={styles.data}>
          <div className={styles.datawrapper}>
            <div className={styles.dataunit}>
              <div className={styles.datalabel}>{t("Created")}</div>
              <div className={styles.datavalue}>
                {formatDate(props.damage.created)}
              </div>
            </div>
            <div className={styles.dataunit}>
              <div className={styles.datalabel}>{t("Type")}</div>
              <div className={styles.datavalue}>
                {lang.getCustomTranslation("name", props.damageType)}
              </div>
            </div>
            <div className={styles.dataunit}>
              <div className={styles.datalabel}>{t("is Acceptable")}</div>
              <div className={styles.datavalue}>
                {yesNoOrUnknown(t, props.damage.is_acceptable)}
              </div>
            </div>
            {props.displayPrices && (
              <>
                <div className={styles.dataunit}>
                  <div className={styles.datalabel}>{t("Checkly Price")}</div>
                  <div className={styles.datavalue}>
                    {props.damage.checkly_cost_estimate === null
                      ? t("N/A")
                      : "" + props.damage.checkly_cost_estimate + " €"}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
