import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { InventoryPresence } from "../../../../Config/datamodels/interfaces";
import clusterify, {
  ClusteredInventoryPresence,
} from "../../DocumentScreen/Utils/clusterify";
import styles from "./InventorySectionContent.module.css";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../../../Config/i18n/LanguageGate";
import SubSection from "../SubSection";
import {
  goodBadOrUnknownColorFeedback,
  okNokOrUnknown,
} from "../../../../Utils/utils";

export interface InventorySectionContentProps {
  inventory?: InventoryPresence[];
}

export default function InventorySectionContent(
  props: InventorySectionContentProps
) {
  const [inventory, setInventory] = useState<ClusteredInventoryPresence>();
  const { t } = useTranslation();
  const { lang } = useContext(LanguageContext);

  useEffect(() => {
    if (props.inventory) setInventory(clusterify(props.inventory));
  }, [props.inventory]);

  if (!inventory) return null;

  return (
    <>
      {_.chunk(_.keys(inventory), 2).map((categoryNames, index) => (
        <SubSection key={index}>
          <div className={styles.wrapper}>
            {categoryNames.map((categoryName) => (
              <div className={styles.category} key={categoryName}>
                <div className={styles.categorytitle}>
                  {lang.getCustomTranslation(
                    "category",
                    inventory[categoryName][0].inventory_item
                  )}
                </div>
                <div className={styles.items}>
                  {inventory[categoryName].map((item) => (
                    <div className={styles.item} key={item.id}>
                      <p>
                        {lang.getCustomTranslation("name", item.inventory_item)}
                      </p>
                      <p
                        style={{
                          color: goodBadOrUnknownColorFeedback(item.presence),
                        }}
                      >
                        {okNokOrUnknown(t, item.presence)}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </SubSection>
      ))}
    </>
  );
}
