import _ from "lodash";

import React from "react";
import styles from "./FormBody.module.css";
import { Form } from "react-final-form";
import { FormFieldMap } from "../../../Store/FormReducer/Fields";
import { FieldValueType } from "../../../../Config/datamodels/types";
import FieldSelector from "../Fields";

export interface FormBodyProps<FieldsName extends string> {
  modelName: string;
  description: string;
  fields: FormFieldMap<FieldsName>;
  errors: Error | null;
  onSubmit: (formValues: any) => void;
  onValidate: (
    values: any
  ) => Promise<
    Record<string, string> | { recordErrors: Record<string, string> }
  >;
  onChangeField: (fieldName: string, fieldValue: FieldValueType) => void;
  onClear: () => void;
}

interface RehydrateMap {
  [key: string]: any;
}

function reHydrateFormValues<FieldsName extends string>(
  fields: FormFieldMap<FieldsName>
) {
  if (fields) {
    let map: RehydrateMap = {};
    Object.keys(fields).forEach(function (name: string) {
      map[name] = _.get(fields, `${name}.value`);
    });
    return map;
  }
}

export default function FormBody<
  FieldsName extends string,
  Props extends FormBodyProps<FieldsName>
>(props: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.separator} />
      <div className={styles.formwrapper}>
        <div className={styles.descriptionContainer}>
          <p className={styles.description}>{props.description}</p>
        </div>
        <div className={styles.formContainer}>
          <Form
            onSubmit={props.onSubmit}
            validate={props.onValidate}
            initialValues={reHydrateFormValues(props.fields)}
            render={(fieldRenderProps) => (
              <form
                className={styles.form}
                id={`${props.modelName}Form`}
                onSubmit={async (event: any) => {
                  try {
                    const fieldRenderPropsValue = await fieldRenderProps.handleSubmit(
                      event
                    );
                    return fieldRenderPropsValue?.form.reset;
                  } catch (err) {
                    return err;
                  }
                }}
              >
                {_.map(props.fields, (field, name) => {
                  return (
                    <FieldSelector
                      key={name}
                      errors={props.errors}
                      field={field}
                      onChange={props.onChangeField}
                    />
                  );
                })}
              </form>
            )}
          ></Form>
        </div>
      </div>
    </div>
  );
}
