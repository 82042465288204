import React, { useState } from "react";
import { InputGroup, Input } from "reactstrap";
import {
  ChoiceField,
  FormField,
} from "../../../../../Store/FormReducer/Fields";
import { RuleTypes } from "../../../../../../Config/datamodels/interfaces";
import { numberLabelValueChoices, Rule as RuleInterface } from "./helpers";
import fieldGeneralStyle from "../../Fields.module.css";
export interface TimeFieldProps {
  field?: FormField;
  rule: RuleInterface;
  onChange: (key: keyof Rule, value: any) => void;
}

export interface DropDownInputProps extends TimeFieldProps {
  selector: keyof Rule;
  options: ChoiceField[] | undefined;
  name: string;
}

export interface Rule extends Object {
  name: string;
  timeValue: number | undefined;
  unitOf: number;
}

export interface TimeRuleFieldProps {
  field: FormField;
  onChange: (value: Rule) => void;
}

const TIMES = [
  { value: 0, label: "day(s)" },
  { value: 1, label: "week(s)" },
  { value: 2, label: "month(s)" },
  { value: 3, label: "year(s)" },
];

const initialeState: any = {
  name: RuleTypes.END_EMPLOYEE_CONTRACT,
  timeValue: "0",
  unitOf: "7",
};

export default function TimeRuleField<Props extends TimeRuleFieldProps>(
  props: Props
) {
  const onInputChange = props.onChange;
  const [ruleState, setRule] = useState(initialeState);

  function onChange(key: string, value: any) {
    let newRulesState: any = { ...ruleState };
    if (key === "name") {
      newRulesState = { ...initialeState };
    }
    newRulesState[key] = value;
    onInputChange(newRulesState);
    setRule(newRulesState);
  }

  return (
    <>
      <RuleField field={props.field} rule={ruleState} onChange={onChange} />
    </>
  );
}

function RuleField<Props extends TimeFieldProps>({
  rule,
  onChange,
  field,
}: Props) {
  return (
    <InputGroup>
      <DropDownInput
        name="qty"
        rule={rule}
        selector={"unitOf"}
        onChange={onChange}
        options={numberLabelValueChoices(rule)}
      />
      <DropDownInput
        name="unit"
        rule={rule}
        selector={"timeValue"}
        onChange={onChange}
        options={TIMES}
      />
      <DropDownInput
        name="type"
        rule={rule}
        selector={"name"}
        onChange={onChange}
        options={field?.choices}
      />
    </InputGroup>
  );
}

function DropDownInput<Props extends DropDownInputProps>({
  rule,
  onChange,
  selector,
  options,
  name,
}: Props) {
  return (
    <Input
      className={fieldGeneralStyle.inputSize}
      style={{ marginRight: 10 }}
      name={name}
      data-testid={"select-" + name}
      type="select"
      value={rule[selector] as any}
      onChange={(event) => {
        event.preventDefault();
        onChange(selector, event.target.value);
      }}
    >
      {options?.map((choice: ChoiceField, index: number) => {
        return (
          <option key={index} value={choice.value}>
            {choice.label}
          </option>
        );
      })}
    </Input>
  );
}
