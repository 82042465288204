import Axios from "axios";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";
import makeRESTDetailService from "../../Common/Services/DetailService/RESTDetailService";
import { Partner } from "../../Config/datamodels/interfaces";

const detailService = makeRESTDetailService<Partner>(
  "partners",
  buildRESTUrl,
  Axios
);
export default detailService;
