import { useDispatch, useSelector } from "react-redux";
import ListReducer, { initialState } from "../../Common/Store/ListReducer";
import makeUseListReducer from "../../Common/Store/ListReducer/useListReducer";

export default ListReducer("users", () => ({
  ...initialState(),
  orderedBy: "profile__created",
}));
export const useListReducer = makeUseListReducer(
  "users",
  useSelector,
  useDispatch
);
