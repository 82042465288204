import _ from "lodash";

export function clusterPartsByPositionAndCategory(parts: any) {
  const defaultLang = "en";
  if (arePartsWithPosition(parts)) {
    return _.sortBy(parts, ["position", `category_${defaultLang}`]);
  }
  return _.sortBy(parts, [`category_${defaultLang}`]);
}

export function arePartsWithPosition(parts: any) {
  return (
    _.reduce(parts, (sum, part) => sum + part.position, parts[0].position) >
    parts[0].position
  );
}
