import _ from "lodash";
import { Model } from "../../../Config/datamodels/interfaces";
import { Filter } from "../../Filters";

export const LOADING_LIST = "loading_list";
export const LIST_SUCCESS = "list_success";
export const LIST_ERROR = "list_error";
export const LIST_SET_ORDER = "list_set_order";
export const LIST_SET_PAGE = "list_set_page";
export const LIST_SET_SEARCH = "list_set_search";
export const LIST_SET_FILTERS = "list_set_filters";
export const LIST_DELETE_SUCCESS = "list_delete_success";
export const LIST_RESET = "list_reset";
export interface ListActionBase {
  modelName: string;
}

export interface ListLoading extends ListActionBase {
  type: typeof LOADING_LIST;
}

export interface ListSuccess extends ListActionBase {
  type: typeof LIST_SUCCESS;
  payload: any;
}

export interface ListError extends ListActionBase {
  type: typeof LIST_ERROR;
  payload: any;
}

export interface ListSetOrder extends ListActionBase {
  type: typeof LIST_SET_ORDER;
  orderBy: string;
  descending: boolean;
}

export interface ListSetPage extends ListActionBase {
  type: typeof LIST_SET_PAGE;
  page: number;
}

export interface ListSetSearch extends ListActionBase {
  type: typeof LIST_SET_SEARCH;
  search: string;
}

export interface ListSetFilters extends ListActionBase {
  type: typeof LIST_SET_FILTERS;
  filters: Filter[];
}

export interface ListDeleteSuccess extends ListActionBase {
  type: typeof LIST_DELETE_SUCCESS;
  payload: string;
}

export interface ListReset extends ListActionBase {
  type: typeof LIST_RESET;
}

export type ListAction =
  | ListLoading
  | ListSuccess
  | ListError
  | ListSetOrder
  | ListSetPage
  | ListSetSearch
  | ListSetFilters
  | ListDeleteSuccess
  | ListReset;

export interface ListState<ModelType extends Model> {
  loading: boolean;
  error: any;
  data: ModelType[];
  currentPage: number;
  maxPage: number;
  pageSize: number;
  orderedBy: string;
  descending: boolean;
  search: string;
  filters: Filter[];
}

export const initialState = <ModelType extends Model>(): ListState<
  ModelType
> => ({
  loading: false,
  error: null,
  data: [],
  currentPage: 1,
  maxPage: 0,
  pageSize: 10,
  orderedBy: "created",
  descending: true,
  search: "",
  filters: [],
});

export default <ModelType extends Model>(
  modelName: string,
  makeInitialState?: () => ListState<ModelType>
) => (
  state: any = makeInitialState
    ? makeInitialState()
    : initialState<ModelType>(),
  action: ListAction
): ListState<ModelType> => {
  if (action.modelName !== modelName) return state;
  switch (action.type) {
    case LOADING_LIST:
      return { ...state, loading: true, error: null };
    case LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload,
        maxPage: Math.ceil(action.payload.count / state.pageSize),
      };
    case LIST_ERROR:
      return { ...state, loading: false, error: action.payload };
    case LIST_SET_ORDER:
      return {
        ...state,
        orderedBy: action.orderBy,
        descending: action.descending,
        currentPage: 1,
      };
    case LIST_SET_PAGE:
      return {
        ...state,
        currentPage: Math.max(1, Math.min(state.maxPage, action.page)),
      };
    case LIST_SET_SEARCH:
      return { ...state, search: action.search };
    case LIST_SET_FILTERS:
      return { ...state, filters: action.filters, currentPage: 1 };
    case LIST_DELETE_SUCCESS:
      const newData = _.reject(state.data.results, { id: action.payload });
      return {
        ...state,
        loading: false,
        error: false,
        data: { ...state.data, count: newData.length, results: newData },
      };

    case LIST_RESET:
      return initialState<ModelType>();
    default:
      return state;
  }
};
