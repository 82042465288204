import store from "../store";
export default function (axios: any) {
  axios.interceptors.request.use(
    function (config: any) {
      // @ts-ignore
      const authorization = store.getState().auth.activationToken
        ? `Token ${store.getState().auth.activationToken}`
        : `Bearer ${store.getState().auth.jwtToken}`;

      const headers = config.headers || {};
      return {
        ...config,
        headers: { ...headers, Authorization: authorization },
      };
    },
    function (error: Error) {
      return Promise.reject(error);
    }
  );
}
