import React from "react";
import { FormField } from "../../../../../Store/FormReducer/Fields";
import ImageUploader from "react-images-upload";
import { makeLogger } from "../../../../../../Utils/logger";
import Thumbnail, { ThumbnailSize } from "../../../../Thumbnail";

import style from "./ImageField.module.css";
import { X } from "react-bootstrap-icons";

const logger = makeLogger("ImageField");

export interface ImageField extends FormField {
  singleImage?: boolean;
}

export interface SelectSelectFieldProps {
  field: ImageField;
  onChange: any;
}

export default function ImageField({
  field,
  onChange,
}: SelectSelectFieldProps) {
  function handleOnChange(event: any) {
    onChange(event);
  }

  logger.info({ field });

  // @ts-ignore
  if (field.value?.id) {
    // @ts-ignore
    return (
      <div>
        <div className={style.removeWrapper}>
          <div
            className={style.removeButton}
            onClick={() => handleOnChange(null)}
          >
            <X />
          </div>
        </div>
        {/* @ts-ignore */}
        <Thumbnail size={ThumbnailSize.medium} src={field.value.medium} />
      </div>
    );
  }

  return (
    <div>
      <ImageUploader
        name={field.name}
        label={field.placeholder}
        singleImage={field.singleImage}
        withIcon={true}
        withPreview={true}
        buttonText="Choose images"
        onChange={handleOnChange}
        imgExtension={[".jpg", ".png", ".jpeg"]}
        maxFileSize={5242880}
      />
    </div>
  );
}
