import React from "react";
import { CarcheckType } from "../../../Config/datamodels/interfaces";

export interface IfCarcheckProps extends React.PropsWithChildren<{}> {
  types: CarcheckType[];
  currentType: string | CarcheckType;
}

export function IfCarcheck(props: IfCarcheckProps) {
  return props.types
    .map((type) => type.toString())
    .includes(props.currentType.toString()) ? (
    <>{props.children}</>
  ) : (
    <></>
  );
}
