import { useDispatch, useSelector } from "react-redux";
import detailReducer, {
  makeUseDetailReducer,
} from "../../Common/Store/DetailReducer";

export default detailReducer("users");
const useUserDetailReducer = makeUseDetailReducer(
  "users",
  useSelector,
  useDispatch
);
export { useUserDetailReducer };
