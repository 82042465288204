import React from "react";
import { useTranslation } from "react-i18next";
import Highlight from "../../../../Common/Components/Highlight";
import SquareThumbnail from "../../../../Common/Components/SquareThumbnail";
import { ThumbnailSize } from "../../../../Common/Components/Thumbnail";
import { Carcheck } from "../../../../Config/datamodels/interfaces";
import styles from "./VehiclePicturesContent.module.css";

export interface VehiclePicturesContentProps {
  carcheck: Carcheck;
}

export default function VehiclePicturesContent(
  props: VehiclePicturesContentProps
) {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.photowithdescwrapper}>
        <div className={styles.title}>
          <Highlight>{t("Dashboard")}</Highlight>
        </div>
        <SquareThumbnail
          src={props.carcheck.dashboard_picture?.xsmall}
          size={ThumbnailSize.smallmedium}
        />
      </div>
      <div className={styles.photowithdescwrapper}>
        <div className={styles.title}>
          <Highlight>{t("Documents")}</Highlight>
        </div>
        <SquareThumbnail
          src={props.carcheck.legal_document_picture?.xsmall}
          size={ThumbnailSize.smallmedium}
        />
      </div>
      <div className={styles.photowithdescwrapper}>
        <div className={styles.title}>
          <Highlight>{t("Interior")}</Highlight>
        </div>
        <SquareThumbnail
          src={props.carcheck.interior_cleanliness_picture?.xsmall}
          size={ThumbnailSize.smallmedium}
        />
      </div>
      <div className={styles.photowithdescwrapper}>
        <div className={styles.title}>
          <Highlight>{t("Exterior")}</Highlight>
        </div>
        <SquareThumbnail
          src={props.carcheck.exterior_cleanliness_picture?.xsmall}
          size={ThumbnailSize.smallmedium}
        />
      </div>
    </>
  );
}
