import { Model } from "../../../Config/datamodels/interfaces";
import { RESTQueryBuilder } from "../../ApiGateway/buildRESTUrl";
import { CreateService, HTTPPostService } from "../types";

const makeRESTCreateService = <ModelType extends Model>(
  modelName: string,
  urlBuilder: RESTQueryBuilder,
  postService: HTTPPostService<ModelType>
): CreateService<ModelType> => async (body: ModelType) => {
  const url = urlBuilder({ resource: modelName });
  const response = await postService.post(url, body);
  return response.data;
};

export default makeRESTCreateService;
