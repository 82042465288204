import makeUseDetailViewModel from "../../Common/ViewModels/makeUseDetailViewModel";
import ToastService from "../../Common/Services/ToastService";
import { Vehicle } from "../../Config/datamodels/interfaces";
import detailService from "../services/detailService";
import { useDetailReducer } from "../store/detailReducer";

const detailViewModel = makeUseDetailViewModel<Vehicle>(
  detailService,
  useDetailReducer,
  ToastService
);
export default detailViewModel;
