import {
  Carcheck,
  TyreState,
  TyreType,
} from "../../../Config/datamodels/interfaces";

export const carcheck: Carcheck = ({
  id: 395,
  carcheck_type: "exhaustive",
  mileage: 200,
  dashboard_picture: {
    id: 3204,
    xsmall:
      "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2022/2/1/253ac620-c888-4b80-be6c-891513b4e2de-xsmall.jpg",
    small:
      "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2022/2/1/253ac620-c888-4b80-be6c-891513b4e2de-small.jpg",
    medium:
      "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2022/2/1/253ac620-c888-4b80-be6c-891513b4e2de-medium.jpg",
    image:
      "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2022/2/1/253ac620-c888-4b80-be6c-891513b4e2de.jpg",
    created: "2022-02-01T10:32:20.748353Z",
    last_updated: "2022-02-01T10:32:20.748376Z",
  },
  plate_picture: null,
  done_datetime: "2022-02-01T10:32:15.327000Z",
  exterior_cleanliness_picture: {
    id: 3206,
    xsmall:
      "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2022/2/1/b7410e93-43f0-4bac-98ee-6688243a6791-xsmall.jpg",
    small:
      "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2022/2/1/b7410e93-43f0-4bac-98ee-6688243a6791-small.jpg",
    medium:
      "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2022/2/1/b7410e93-43f0-4bac-98ee-6688243a6791-medium.jpg",
    image:
      "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2022/2/1/b7410e93-43f0-4bac-98ee-6688243a6791.jpg",
    created: "2022-02-01T10:32:20.882016Z",
    last_updated: "2022-02-01T10:32:20.882039Z",
  },
  is_exterior_clean: true,
  interior_cleanliness_picture: {
    id: 3205,
    xsmall:
      "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2022/2/1/f0703c97-7f7f-4782-b4f9-9ef95a947f7b-xsmall.jpg",
    small:
      "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2022/2/1/f0703c97-7f7f-4782-b4f9-9ef95a947f7b-small.jpg",
    medium:
      "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2022/2/1/f0703c97-7f7f-4782-b4f9-9ef95a947f7b-medium.jpg",
    image:
      "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2022/2/1/f0703c97-7f7f-4782-b4f9-9ef95a947f7b.jpg",
    created: "2022-02-01T10:32:20.766441Z",
    last_updated: "2022-02-01T10:32:20.766463Z",
  },
  legal_document_picture: {
    id: 3207,
    xsmall:
      "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2022/2/1/abea8eaf-7c48-4daa-9917-5d34d7a78e1d-xsmall.jpg",
    small:
      "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2022/2/1/abea8eaf-7c48-4daa-9917-5d34d7a78e1d-small.jpg",
    medium:
      "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2022/2/1/abea8eaf-7c48-4daa-9917-5d34d7a78e1d-medium.jpg",
    image:
      "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2022/2/1/abea8eaf-7c48-4daa-9917-5d34d7a78e1d.jpg",
    created: "2022-02-01T10:32:20.902522Z",
    last_updated: "2022-02-01T10:32:20.902583Z",
  },
  is_interior_clean: true,
  fuel_level: 4,
  key_count: 3,
  vehicle: {
    id: 3004,
    created: "2022-02-01T09:32:24.535217Z",
    last_updated: "2022-02-01T13:15:19.929792Z",
    vin: "AAAZERTYU890765RT",
    plate: "1-CTX-999",
    brand: "AUDI",
    model: "SANDERO",
    year: 2019,
    leasing_start_date: "2022-01-31",
    leasing_end_date: "2022-05-29",
    km_contract: 100200,
    mileage: 200,
    owning_partner: {
      id: 4,
      name: "TRIANGLE DONUTS INC.",
      company: "PETROLEY",
    },
    department: null,
    type: {
      id: 10,
      created: "2020-09-20T12:33:02.839452Z",
      last_updated: "2020-09-20T12:33:02.839461Z",
      name: "LIGHT COMMERCIAL VEHICLE",
      cost_multiplier: 1,
      company: "PETROLEY",
    },
    picture: {
      id: 3206,
      xsmall:
        "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2022/2/1/b7410e93-43f0-4bac-98ee-6688243a6791-xsmall.jpg",
      small:
        "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2022/2/1/b7410e93-43f0-4bac-98ee-6688243a6791-small.jpg",
      medium:
        "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2022/2/1/b7410e93-43f0-4bac-98ee-6688243a6791-medium.jpg",
      image:
        "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2022/2/1/b7410e93-43f0-4bac-98ee-6688243a6791.jpg",
      created: "2022-02-01T10:32:20.882016Z",
      last_updated: "2022-02-01T10:32:20.882039Z",
    },
    driver: null,
  },
  is_front_left_state_good: TyreState.TO_MONITOR,
  is_front_right_state_good: TyreState.TO_MONITOR,
  is_back_left_state_good: TyreState.TO_MONITOR,
  is_back_right_state_good: TyreState.TO_MONITOR,
  front_tyres_type: TyreType.FOURSEASONS,
  back_tyres_type: TyreType.FOURSEASONS,
  damage_set: [],
  inventorypresence_set: [
    {
      id: 5664,
      presence: null,
      created: "2022-02-01T10:37:36.730602Z",
      last_updated: "2022-02-01T10:37:36.730630Z",
      freeze_update: false,
      inventory_item: {
        id: 86297,
        created: "2022-02-01T09:32:25.226141Z",
        last_updated: "2022-02-01T09:32:25.226146Z",
        name_fr: "Accessoires de recharge pour véhicules électriques",
        name_en: "Chargers accessories for electronic vehicles;",
        name_nl: "Laadaccesoires voor elektrische voertuigen",
        category_fr: "Accessoires",
        category_en: "Accessories",
        category_nl: "Toebehoren",
        position: 26000,
        type: "ACCESSORIES",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5665,
      presence: true,
      created: "2022-02-01T10:37:36.798598Z",
      last_updated: "2022-02-01T10:37:36.798627Z",
      freeze_update: false,
      inventory_item: {
        id: 86296,
        created: "2022-02-01T09:32:25.226114Z",
        last_updated: "2022-02-01T09:32:25.226119Z",
        name_fr: "Coffre de toit",
        name_en: "Roof box",
        name_nl: "Dakkoffer",
        category_fr: "Accessoires",
        category_en: "Accessories",
        category_nl: "Toebehoren",
        position: 24000,
        type: "ACCESSORIES",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5666,
      presence: false,
      created: "2022-02-01T10:37:36.876139Z",
      last_updated: "2022-02-01T10:37:36.876170Z",
      freeze_update: false,
      inventory_item: {
        id: 86295,
        created: "2022-02-01T09:32:25.226087Z",
        last_updated: "2022-02-01T09:32:25.226093Z",
        name_fr: "Crochet d’attelage",
        name_en: "Tow bar",
        name_nl: "Trekhaak",
        category_fr: "Accessoires",
        category_en: "Accessories",
        category_nl: "Toebehoren",
        position: 23000,
        type: "ACCESSORIES",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5667,
      presence: null,
      created: "2022-02-01T10:37:36.934206Z",
      last_updated: "2022-02-01T10:37:36.934233Z",
      freeze_update: false,
      inventory_item: {
        id: 86294,
        created: "2022-02-01T09:32:25.226060Z",
        last_updated: "2022-02-01T09:32:25.226065Z",
        name_fr: "Porte-vélos",
        name_en: "Bike carrier",
        name_nl: "Fietsdragers",
        category_fr: "Accessoires",
        category_en: "Accessories",
        category_nl: "Toebehoren",
        position: 22000,
        type: "ACCESSORIES",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5668,
      presence: null,
      created: "2022-02-01T10:37:36.990637Z",
      last_updated: "2022-02-01T10:37:36.990661Z",
      freeze_update: false,
      inventory_item: {
        id: 86293,
        created: "2022-02-01T09:32:25.226033Z",
        last_updated: "2022-02-01T09:32:25.226038Z",
        name_fr: "Cric",
        name_en: "Jack",
        name_nl: "Krik",
        category_fr: "Accessoires",
        category_en: "Accessories",
        category_nl: "Toebehoren",
        position: 20000,
        type: "ACCESSORIES",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5669,
      presence: null,
      created: "2022-02-01T10:37:37.072340Z",
      last_updated: "2022-02-01T10:37:37.072369Z",
      freeze_update: false,
      inventory_item: {
        id: 86292,
        created: "2022-02-01T09:32:25.226006Z",
        last_updated: "2022-02-01T09:32:25.226011Z",
        name_fr: "Roue de secours ou kit de réparation",
        name_en: "Spare wheel or repair kit",
        name_nl: "Reservewiel of reparatiekit",
        category_fr: "Accessoires",
        category_en: "Accessories",
        category_nl: "Toebehoren",
        position: 19000,
        type: "ACCESSORIES",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5670,
      presence: null,
      created: "2022-02-01T10:37:37.155924Z",
      last_updated: "2022-02-01T10:37:37.155945Z",
      freeze_update: false,
      inventory_item: {
        id: 86291,
        created: "2022-02-01T09:32:25.225979Z",
        last_updated: "2022-02-01T09:32:25.225984Z",
        name_fr: "Allumes- cigare",
        name_en: "Cigaret lighter",
        name_nl: "Sigarenaanstekers",
        category_fr: "Accessoires",
        category_en: "Accessories",
        category_nl: "Toebehoren",
        position: 17000,
        type: "ACCESSORIES",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5636,
      presence: true,
      created: "2022-02-01T10:37:34.097963Z",
      last_updated: "2022-02-01T10:37:34.097987Z",
      freeze_update: false,
      inventory_item: {
        id: 86300,
        created: "2022-02-01T09:32:25.226222Z",
        last_updated: "2022-02-01T09:32:25.226227Z",
        name_fr: "Niveau liquide de refroidissement",
        name_en: "Coolant level",
        name_nl: "Niveau koelvloeistof",
        category_fr: "Niveaux",
        category_en: "Levels",
        category_nl: "Levels",
        position: 30000,
        type: "TECHNICAL_ELEMENTS",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5671,
      presence: null,
      created: "2022-02-01T10:37:37.231611Z",
      last_updated: "2022-02-01T10:37:37.231633Z",
      freeze_update: false,
      inventory_item: {
        id: 86290,
        created: "2022-02-01T09:32:25.225952Z",
        last_updated: "2022-02-01T09:32:25.225957Z",
        name_fr: "Protège bagages",
        name_en: "Luggage screen",
        name_nl: "Bagagscherm",
        category_fr: "Accessoires",
        category_en: "Accessories",
        category_nl: "Toebehoren",
        position: 16000,
        type: "ACCESSORIES",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5637,
      presence: true,
      created: "2022-02-01T10:37:34.169770Z",
      last_updated: "2022-02-01T10:37:34.169792Z",
      freeze_update: false,
      inventory_item: {
        id: 86299,
        created: "2022-02-01T09:32:25.226195Z",
        last_updated: "2022-02-01T09:32:25.226200Z",
        name_fr: "Niveau d'huile",
        name_en: "Oil level",
        name_nl: "Olieniveau",
        category_fr: "Niveaux",
        category_en: "Levels",
        category_nl: "Levels",
        position: 29000,
        type: "TECHNICAL_ELEMENTS",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5672,
      presence: null,
      created: "2022-02-01T10:37:37.296419Z",
      last_updated: "2022-02-01T10:37:37.296448Z",
      freeze_update: false,
      inventory_item: {
        id: 86289,
        created: "2022-02-01T09:32:25.225925Z",
        last_updated: "2022-02-01T09:32:25.225930Z",
        name_fr: "Installations audio",
        name_en: "Audio systems",
        name_nl: "Audio-installatie",
        category_fr: "Accessoires",
        category_en: "Accessories",
        category_nl: "Toebehoren",
        position: 13000,
        type: "ACCESSORIES",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5638,
      presence: true,
      created: "2022-02-01T10:37:34.252710Z",
      last_updated: "2022-02-01T10:37:34.252739Z",
      freeze_update: false,
      inventory_item: {
        id: 86315,
        created: "2022-02-01T09:32:25.226629Z",
        last_updated: "2022-02-01T09:32:25.226635Z",
        name_fr: "Veste fluorescente",
        name_en: "Fluorescent jacket",
        name_nl: "Fluorescerende vest",
        category_fr: "Élements légaux",
        category_en: "Legal elements",
        category_nl: "Juridische elementen",
        position: 45000,
        type: "LEGAL",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5673,
      presence: null,
      created: "2022-02-01T10:37:37.391600Z",
      last_updated: "2022-02-01T10:37:37.391646Z",
      freeze_update: false,
      inventory_item: {
        id: 86288,
        created: "2022-02-01T09:32:25.225898Z",
        last_updated: "2022-02-01T09:32:25.225903Z",
        name_fr: "GPS",
        name_en: "GPS systems",
        name_nl: "GPS",
        category_fr: "Accessoires",
        category_en: "Accessories",
        category_nl: "Toebehoren",
        position: 12000,
        type: "ACCESSORIES",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5639,
      presence: true,
      created: "2022-02-01T10:37:34.339142Z",
      last_updated: "2022-02-01T10:37:34.339165Z",
      freeze_update: false,
      inventory_item: {
        id: 86314,
        created: "2022-02-01T09:32:25.226602Z",
        last_updated: "2022-02-01T09:32:25.226607Z",
        name_fr: "Triangle",
        name_en: "Triangle",
        name_nl: "Driehoek",
        category_fr: "Élements légaux",
        category_en: "Legal elements",
        category_nl: "Juridische elementen",
        position: 44000,
        type: "LEGAL",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5640,
      presence: true,
      created: "2022-02-01T10:37:34.413653Z",
      last_updated: "2022-02-01T10:37:34.413677Z",
      freeze_update: false,
      inventory_item: {
        id: 86313,
        created: "2022-02-01T09:32:25.226574Z",
        last_updated: "2022-02-01T09:32:25.226579Z",
        name_fr: "Trousse de secours",
        name_en: "First aid kit",
        name_nl: "Verband kit",
        category_fr: "Élements légaux",
        category_en: "Legal elements",
        category_nl: "Juridische elementen",
        position: 43000,
        type: "LEGAL",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5641,
      presence: true,
      created: "2022-02-01T10:37:34.496286Z",
      last_updated: "2022-02-01T10:37:34.496310Z",
      freeze_update: false,
      inventory_item: {
        id: 86312,
        created: "2022-02-01T09:32:25.226547Z",
        last_updated: "2022-02-01T09:32:25.226552Z",
        name_fr: "Extincteur",
        name_en: "Fire extinguisher",
        name_nl: "Brandblusser",
        category_fr: "Élements légaux",
        category_en: "Legal elements",
        category_nl: "Juridische elementen",
        position: 42000,
        type: "LEGAL",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5642,
      presence: null,
      created: "2022-02-01T10:37:34.583448Z",
      last_updated: "2022-02-01T10:37:34.583478Z",
      freeze_update: false,
      inventory_item: {
        id: 86311,
        created: "2022-02-01T09:32:25.226520Z",
        last_updated: "2022-02-01T09:32:25.226525Z",
        name_fr: "Claxon",
        name_en: "Horn",
        name_nl: "Toeter",
        category_fr: "Fonctionement Electrique",
        category_en: "Electrical Operations",
        category_nl: "Electrische Werking",
        position: 41000,
        type: "ELECTRICAL_OPERATIONS",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5643,
      presence: null,
      created: "2022-02-01T10:37:34.676009Z",
      last_updated: "2022-02-01T10:37:34.676040Z",
      freeze_update: false,
      inventory_item: {
        id: 86310,
        created: "2022-02-01T09:32:25.226493Z",
        last_updated: "2022-02-01T09:32:25.226498Z",
        name_fr: "Essuie-glaces",
        name_en: "Windshield Wipers",
        name_nl: "Ruitenwissers",
        category_fr: "Fonctionement Electrique",
        category_en: "Electrical Operations",
        category_nl: "Electrische Werking",
        position: 40000,
        type: "ELECTRICAL_OPERATIONS",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5644,
      presence: null,
      created: "2022-02-01T10:37:34.749932Z",
      last_updated: "2022-02-01T10:37:34.749954Z",
      freeze_update: false,
      inventory_item: {
        id: 86309,
        created: "2022-02-01T09:32:25.226466Z",
        last_updated: "2022-02-01T09:32:25.226471Z",
        name_fr: "Feu anti-brouillard Avant",
        name_en: "Fog light front",
        name_nl: "Mistlicht Voor",
        category_fr: "Fonctionement Electrique",
        category_en: "Electrical Operations",
        category_nl: "Electrische Werking",
        position: 39000,
        type: "ELECTRICAL_OPERATIONS",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5645,
      presence: null,
      created: "2022-02-01T10:37:34.844923Z",
      last_updated: "2022-02-01T10:37:34.844954Z",
      freeze_update: false,
      inventory_item: {
        id: 86308,
        created: "2022-02-01T09:32:25.226439Z",
        last_updated: "2022-02-01T09:32:25.226444Z",
        name_fr: "Feu Avant",
        name_en: "Head light",
        name_nl: "Koplampen",
        category_fr: "Fonctionement Electrique",
        category_en: "Electrical Operations",
        category_nl: "Electrische Werking",
        position: 38000,
        type: "ELECTRICAL_OPERATIONS",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5646,
      presence: null,
      created: "2022-02-01T10:37:34.934193Z",
      last_updated: "2022-02-01T10:37:34.934221Z",
      freeze_update: false,
      inventory_item: {
        id: 86307,
        created: "2022-02-01T09:32:25.226412Z",
        last_updated: "2022-02-01T09:32:25.226417Z",
        name_fr: "Clignoteur Avant",
        name_en: "Blinkers Front",
        name_nl: "Pinkers Voor",
        category_fr: "Fonctionement Electrique",
        category_en: "Electrical Operations",
        category_nl: "Electrische Werking",
        position: 37000,
        type: "ELECTRICAL_OPERATIONS",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5647,
      presence: null,
      created: "2022-02-01T10:37:35.082905Z",
      last_updated: "2022-02-01T10:37:35.082926Z",
      freeze_update: false,
      inventory_item: {
        id: 86306,
        created: "2022-02-01T09:32:25.226385Z",
        last_updated: "2022-02-01T09:32:25.226390Z",
        name_fr: "Clignoteur Arrière",
        name_en: "Blinkers Rear",
        name_nl: "Pinkers Achter",
        category_fr: "Fonctionement Electrique",
        category_en: "Electrical Operations",
        category_nl: "Electrische Werking",
        position: 36000,
        type: "ELECTRICAL_OPERATIONS",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5648,
      presence: null,
      created: "2022-02-01T10:37:35.172733Z",
      last_updated: "2022-02-01T10:37:35.172759Z",
      freeze_update: false,
      inventory_item: {
        id: 86305,
        created: "2022-02-01T09:32:25.226358Z",
        last_updated: "2022-02-01T09:32:25.226363Z",
        name_fr: "Feu marche-arrière",
        name_en: "Reverse Light",
        name_nl: "Achteruitrijlicht",
        category_fr: "Fonctionement Electrique",
        category_en: "Electrical Operations",
        category_nl: "Electrische Werking",
        position: 35000,
        type: "ELECTRICAL_OPERATIONS",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5649,
      presence: null,
      created: "2022-02-01T10:37:35.230739Z",
      last_updated: "2022-02-01T10:37:35.230761Z",
      freeze_update: false,
      inventory_item: {
        id: 86304,
        created: "2022-02-01T09:32:25.226331Z",
        last_updated: "2022-02-01T09:32:25.226336Z",
        name_fr: "Feu stop",
        name_en: "Brake light",
        name_nl: "Remlichten",
        category_fr: "Fonctionement Electrique",
        category_en: "Electrical Operations",
        category_nl: "Electrische Werking",
        position: 34000,
        type: "ELECTRICAL_OPERATIONS",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5650,
      presence: null,
      created: "2022-02-01T10:37:35.320107Z",
      last_updated: "2022-02-01T10:37:35.320137Z",
      freeze_update: false,
      inventory_item: {
        id: 86303,
        created: "2022-02-01T09:32:25.226304Z",
        last_updated: "2022-02-01T09:32:25.226309Z",
        name_fr: "Fue anti-brouillard Arrière",
        name_en: "Fog light Rear",
        name_nl: "Mistlichten Achter",
        category_fr: "Fonctionement Electrique",
        category_en: "Electrical Operations",
        category_nl: "Electrische Werking",
        position: 33000,
        type: "ELECTRICAL_OPERATIONS",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5651,
      presence: null,
      created: "2022-02-01T10:37:35.409475Z",
      last_updated: "2022-02-01T10:37:35.409496Z",
      freeze_update: false,
      inventory_item: {
        id: 86302,
        created: "2022-02-01T09:32:25.226277Z",
        last_updated: "2022-02-01T09:32:25.226282Z",
        name_fr: "Eclairage plaque Arrière",
        name_en: "Light Licence plate",
        name_nl: "Nummerplaat verlichting",
        category_fr: "Fonctionement Electrique",
        category_en: "Electrical Operations",
        category_nl: "Electrische Werking",
        position: 32000,
        type: "ELECTRICAL_OPERATIONS",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5652,
      presence: null,
      created: "2022-02-01T10:37:35.494306Z",
      last_updated: "2022-02-01T10:37:35.494329Z",
      freeze_update: false,
      inventory_item: {
        id: 86301,
        created: "2022-02-01T09:32:25.226249Z",
        last_updated: "2022-02-01T09:32:25.226255Z",
        name_fr: "Phares Arrières",
        name_en: "Tail light",
        name_nl: "Achterlichten",
        category_fr: "Fonctionement Electrique",
        category_en: "Electrical Operations",
        category_nl: "Electrische Werking",
        position: 31000,
        type: "ELECTRICAL_OPERATIONS",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5653,
      presence: null,
      created: "2022-02-01T10:37:35.552868Z",
      last_updated: "2022-02-01T10:37:35.552896Z",
      freeze_update: false,
      inventory_item: {
        id: 86287,
        created: "2022-02-01T09:32:25.225871Z",
        last_updated: "2022-02-01T09:32:25.225876Z",
        name_fr: "Le certificat d'assurance",
        name_en: "Certificate of Insurance",
        name_nl: "Certificaat van verzekering",
        category_fr: "Documents",
        category_en: "Documents",
        category_nl: "Documenten",
        position: 11200,
        type: "DOCUMENTS",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5654,
      presence: null,
      created: "2022-02-01T10:37:35.631468Z",
      last_updated: "2022-02-01T10:37:35.631500Z",
      freeze_update: false,
      inventory_item: {
        id: 86286,
        created: "2022-02-01T09:32:25.225844Z",
        last_updated: "2022-02-01T09:32:25.225849Z",
        name_fr: "Controle technique",
        name_en: "MOT",
        name_nl: "Technische controle",
        category_fr: "Documents",
        category_en: "Documents",
        category_nl: "Documenten",
        position: 11100,
        type: "DOCUMENTS",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5655,
      presence: null,
      created: "2022-02-01T10:37:35.709544Z",
      last_updated: "2022-02-01T10:37:35.709566Z",
      freeze_update: false,
      inventory_item: {
        id: 86285,
        created: "2022-02-01T09:32:25.225817Z",
        last_updated: "2022-02-01T09:32:25.225822Z",
        name_fr: "La carte essence et code",
        name_en: "Fuel card and code",
        name_nl: "De tankkaart en code",
        category_fr: "Documents",
        category_en: "Documents",
        category_nl: "Documenten",
        position: 11000,
        type: "DOCUMENTS",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5656,
      presence: null,
      created: "2022-02-01T10:37:35.792679Z",
      last_updated: "2022-02-01T10:37:35.792709Z",
      freeze_update: false,
      inventory_item: {
        id: 86284,
        created: "2022-02-01T09:32:25.225789Z",
        last_updated: "2022-02-01T09:32:25.225795Z",
        name_fr: "Le manual d'instruction",
        name_en: "Instruction manual",
        name_nl: "De gebruikshandleiding",
        category_fr: "Documents",
        category_en: "Documents",
        category_nl: "Documenten",
        position: 8000,
        type: "DOCUMENTS",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5657,
      presence: null,
      created: "2022-02-01T10:37:35.873619Z",
      last_updated: "2022-02-01T10:37:35.873648Z",
      freeze_update: false,
      inventory_item: {
        id: 86283,
        created: "2022-02-01T09:32:25.225763Z",
        last_updated: "2022-02-01T09:32:25.225768Z",
        name_fr: "La carte verte",
        name_en: "Green card",
        name_nl: "De groene kaart",
        category_fr: "Documents",
        category_en: "Documents",
        category_nl: "Documenten",
        position: 7000,
        type: "DOCUMENTS",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5658,
      presence: null,
      created: "2022-02-01T10:37:35.949663Z",
      last_updated: "2022-02-01T10:37:35.949684Z",
      freeze_update: false,
      inventory_item: {
        id: 86282,
        created: "2022-02-01T09:32:25.225736Z",
        last_updated: "2022-02-01T09:32:25.225741Z",
        name_fr: "Le passeport de la clé",
        name_en: "Key code",
        name_nl: "Het sleutelpaspoort",
        category_fr: "Documents",
        category_en: "Documents",
        category_nl: "Documenten",
        position: 6000,
        type: "DOCUMENTS",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5659,
      presence: null,
      created: "2022-02-01T10:37:36.041052Z",
      last_updated: "2022-02-01T10:37:36.041081Z",
      freeze_update: false,
      inventory_item: {
        id: 86281,
        created: "2022-02-01T09:32:25.225708Z",
        last_updated: "2022-02-01T09:32:25.225714Z",
        name_fr: "Le code radio et/ou passeport radio",
        name_en: "Radio code and/or radio passport",
        name_nl: "Radiocode en/of radiopaspoort",
        category_fr: "Documents",
        category_en: "Documents",
        category_nl: "Documenten",
        position: 5000,
        type: "DOCUMENTS",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5660,
      presence: null,
      created: "2022-02-01T10:37:36.133336Z",
      last_updated: "2022-02-01T10:37:36.133362Z",
      freeze_update: false,
      inventory_item: {
        id: 86280,
        created: "2022-02-01T09:32:25.225680Z",
        last_updated: "2022-02-01T09:32:25.225686Z",
        name_fr: "Le carnet d’entretien ou l’historique d’entretien",
        name_en: "Service booklet or history",
        name_nl: "Onderhoudsboekje of -historiek",
        category_fr: "Documents",
        category_en: "Documents",
        category_nl: "Documenten",
        position: 4000,
        type: "DOCUMENTS",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5661,
      presence: null,
      created: "2022-02-01T10:37:36.213968Z",
      last_updated: "2022-02-01T10:37:36.214000Z",
      freeze_update: false,
      inventory_item: {
        id: 86279,
        created: "2022-02-01T09:32:25.225651Z",
        last_updated: "2022-02-01T09:32:25.225657Z",
        name_fr: "Le certificat de conformité original",
        name_en: "Original certificate of conformity",
        name_nl: "Origineel gelijkvormigheidsattest",
        category_fr: "Documents",
        category_en: "Documents",
        category_nl: "Documenten",
        position: 3000,
        type: "DOCUMENTS",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5662,
      presence: null,
      created: "2022-02-01T10:37:36.436538Z",
      last_updated: "2022-02-01T10:37:36.436567Z",
      freeze_update: false,
      inventory_item: {
        id: 86278,
        created: "2022-02-01T09:32:25.225591Z",
        last_updated: "2022-02-01T09:32:25.225615Z",
        name_fr: "Le certificat d’immatriculation",
        name_en: "Certificate of registration",
        name_nl: "Inschrijvingsbewijs",
        category_fr: "Documents",
        category_en: "Documents",
        category_nl: "Documenten",
        position: 1000,
        type: "DOCUMENTS",
        vehicle: 3004,
      },
      carcheck: 395,
    },
    {
      id: 5663,
      presence: null,
      created: "2022-02-01T10:37:36.667511Z",
      last_updated: "2022-02-01T10:37:36.667541Z",
      freeze_update: false,
      inventory_item: {
        id: 86298,
        created: "2022-02-01T09:32:25.226168Z",
        last_updated: "2022-02-01T09:32:25.226173Z",
        name_fr: "La douille antivol",
        name_en: "Wheel socket",
        name_nl: "De antidiefstalmoerdop",
        category_fr: "Accessoires",
        category_en: "Accessories",
        category_nl: "Toebehoren",
        position: 26100,
        type: "ACCESSORIES",
        vehicle: 3004,
      },
      carcheck: 395,
    },
  ],
  participation_set: [
    {
      id: 375,
      created: "2022-02-01T10:37:33.976070Z",
      last_updated: "2022-02-01T10:37:33.976103Z",
      freeze_update: false,
      comment: "test",
      user: {
        id: 12,
        username: "spiros-viggo@gmail.com",
        first_name: "viggo",
        last_name: "spiros",
        email: "spiros-viggo@gmail.com",
        groups: ["carcheckers", "fleet_managers"],
        profile: {
          id: 12,
          phone: "+32472301947",
          contract_end_date: null,
          profile_picture: {
            id: 11,
            xsmall:
              "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2020/9/20/21fce9c7-454a-4a8e-86c1-e78531addafc-xsmall.jpg",
            small:
              "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2020/9/20/21fce9c7-454a-4a8e-86c1-e78531addafc-small.jpg",
            medium:
              "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2020/9/20/21fce9c7-454a-4a8e-86c1-e78531addafc-medium.jpg",
            image:
              "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2020/9/20/21fce9c7-454a-4a8e-86c1-e78531addafc.jpg",
            created: "2020-09-20T12:33:12.965594Z",
            last_updated: "2020-09-20T12:33:12.965623Z",
          },
          department: null,
          company: "PETROLEY",
          language: "en",
          company_logo: null,
        },
        is_active: true,
      },
      carcheck: 395,
      signature: {
        id: 3203,
        xsmall:
          "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2022/2/1/beac11b6-0e60-4c87-ad52-9d8dd06273f4-xsmall.png",
        small:
          "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2022/2/1/beac11b6-0e60-4c87-ad52-9d8dd06273f4-small.png",
        medium:
          "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2022/2/1/beac11b6-0e60-4c87-ad52-9d8dd06273f4-medium.png",
        image:
          "https://checkly-backend-dev-media-bucket.s3-eu-west-1.amazonaws.com/2022/2/1/beac11b6-0e60-4c87-ad52-9d8dd06273f4.png",
        created: "2022-02-01T10:32:20.689672Z",
        last_updated: "2022-02-01T10:32:20.689704Z",
      },
    },
  ],
  request: 36204979,
  freeze_update: false,
  damage_per_part: [],
} as unknown) as Carcheck;
