import React from "react";
import _ from "lodash";
import { Input } from "reactstrap";
import debounce from "debounce-promise";
import { AsyncPaginate } from "react-select-async-paginate";

import {
  Filter,
  cloneFilterWithValue,
  SearchSelectFilter,
  InputType,
} from "../../Filters";
import makeRESTListService from "../../Services/ListService/RESTListService";
import buildRESTUrl from "../../ApiGateway/buildRESTUrl";
import axios from "axios";

import styles from "./Filters.module.css";

export interface FilterControl {
  filter: Filter;
  onChange: (filter: Filter) => void;
}

export interface FilterControl {
  filter: Filter;
  onChange: (filter: Filter) => void;
}

export interface SearchAndSelectControl {
  filter: SearchSelectFilter;
  onChange: (filter: Filter) => void;
}

export interface FilterFieldProps extends FilterControl {}
export interface SearchAndSelectFilterFieldProps
  extends SearchAndSelectControl {}

export interface FilterConditionSelectorProps extends FilterControl {}

export function CommonFilterField(props: FilterFieldProps) {
  const onChange = (ev: any) => {
    ev.preventDefault();
    props.onChange(cloneFilterWithValue(props.filter, ev.target.value));
  };

  return (
    <Input
      type={props.filter.inputType as InputType}
      value={props.filter.value}
      onChange={onChange}
      disabled={!props.filter.isEditable}
      data-testid={props.filter.name + "-" + props.filter.type}
      className="unset-h"
    >
      {props.filter.choice &&
        props.filter.choice.map((choice) => (
          <option value={choice.value}>{choice.label}</option>
        ))}
    </Input>
  );
}

export function SearchAndSelectFilterField(
  props: SearchAndSelectFilterFieldProps
) {
  const onChange = (ev: any) => {
    props.onChange(cloneFilterWithValue(props.filter, ev.value));
  };

  function handleOnChange(event: any) {
    if (!event) {
      event = { label: "", value: null };
    }
    onChange(event);
  }

  async function loadOptions(inputValue: string, callback: any) {
    props.filter.searchFilter.value = inputValue;
    const listService = makeRESTListService<any>(
      props.filter.searchOnModel as string,
      buildRESTUrl,
      axios
    );
    const response = await listService({
      orderBy: "id",
      descending: true,
      limit: 5,
      offset: 0,
      filters: [props.filter.searchFilter],
      search: "",
    });
    const options = response.results.map((result: any) => ({
      label: _.get(result, props.filter.selectFieldLabel as string),
      // if there is an id in the field provide the id
      value: _.get(result, props.filter.selectFieldValue as string),
    }));
    return {
      options,
      hasMore: false,
    };
  }

  return (
    <div className={styles.searchSelectFilterInnerContainer}>
      <AsyncPaginate
        isClearable
        onChange={(event: any) => handleOnChange(event)}
        loadOptions={debounce(loadOptions, 500)}
      />
    </div>
  );
}

function FieldFactory(
  props: FilterFieldProps | SearchAndSelectFilterFieldProps
) {
  switch (props.filter.inputType) {
    case "search-and-select":
      return (
        <SearchAndSelectFilterField
          {...(props as SearchAndSelectFilterFieldProps)}
        />
      );

    default:
      return <CommonFilterField {...(props as FilterFieldProps)} />;
  }
}

export default FieldFactory;
