import Axios from "axios";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";
import makeRESTListService from "../../Common/Services/ListService/RESTListService";
import { CarcheckRule } from "../../Config/datamodels/interfaces";

const listService = makeRESTListService<CarcheckRule>(
  "rules",
  buildRESTUrl,
  Axios
);
export default listService;
