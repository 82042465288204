import React from "react";
import { useTranslation } from "react-i18next";
import KeyValueText from "../../../../Common/Components/KeyValueText";
import { Carcheck } from "../../../../Config/datamodels/interfaces";
import { formatDate } from "../../../../Utils/utils";
import style from "./Summary.module.css";

export interface SummaryProps {
  carcheck: Carcheck;
}

export default function Summary(props: SummaryProps) {
  const { t } = useTranslation();

  return (
    <div className={style.wrapper}>
      <KeyValueText title={t("Vin")}>{props.carcheck.vehicle.vin}</KeyValueText>
      <KeyValueText title={t("Plate")}>
        {props.carcheck.vehicle.plate}
      </KeyValueText>
      <KeyValueText title={t("carcheck type")}>
        {props.carcheck.carcheck_type}
      </KeyValueText>
      <KeyValueText title={t("carcheck date")}>
        {formatDate(props.carcheck.done_datetime)}
      </KeyValueText>
      <KeyValueText title={t("number of participants")}>
        {props.carcheck.participation_set.length}
      </KeyValueText>
      <KeyValueText title={t("number of damages")}>
        {props.carcheck.damage_set.length}
      </KeyValueText>
    </div>
  );
}
