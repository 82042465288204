import Axios from "axios";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";
import makeRESTCreateService from "../../Common/Services/CreateService";
import { Vehicle } from "../../Config/datamodels/interfaces";

const createService = makeRESTCreateService<Vehicle>(
  "vehicles",
  buildRESTUrl,
  Axios
);
export default createService;
