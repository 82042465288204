import Axios from "axios";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";
import makeRESTDetailService from "../../Common/Services/DetailService/RESTDetailService";
import { Department } from "../../Config/datamodels/interfaces";

const detailService = makeRESTDetailService<Department>(
  "departments",
  buildRESTUrl,
  Axios
);
export default detailService;
