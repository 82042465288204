import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useUserVehicleListViewModel from "../../viewModel/useUserVehicleListViewModel";
import { Vehicle } from "../../../Config/datamodels/interfaces";
import Highlight from "../../../Common/Components/Highlight";
import Emphasize from "../../../Common/Components/Emphasize";
import Thumbnail, { ThumbnailSize } from "../../../Common/Components/Thumbnail";
import Card from "../../../Common/Components/Card";
import KeyValueText from "../../../Common/Components/KeyValueText";
import ElementWrapper from "../../../Common/Components/ElementWrapper";
import { Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import ListView from "../../../Common/Components/ListView";
import vehicleListHeaders from "../../../vehicles/screens/ListScreen/listHeaders";
import VehicleListItem from "../../../vehicles/screens/ListScreen/ListItem";
import Header from "../../../Common/Components/Header";
import userPicturePlaceholder from "./user-placeholder.jpg";
import DocumentScreenBody from "../../../Common/Components/DocumentScreenBody";
import { getOneGroupTranslation } from "../../../Utils/utils";
import useUserDetailViewModel from "../../viewModel/detailViewModel";
import useAuthViewModel from "../../../auth/viewModel/authViewModel";
import useFields from "../../../vehicles/screens/ListScreen/useFields";
import styles from "./DocumentScreen.module.css";

interface Params {
  id: string;
}

function getUriOrPlaceholder(uri: string | undefined) {
  return uri || userPicturePlaceholder;
}

function VehicleSubView(props: { userId: number }) {
  const listData = useUserVehicleListViewModel(props.userId);
  const { filterableFields, orderableFields } = useFields();
  const { t } = useTranslation();
  const { groups } = useAuthViewModel();
  return (
    <ListView<Vehicle>
      title={t("Vehicles")}
      listData={listData}
      headers={vehicleListHeaders(t, groups)}
      renderLine={(vehicle: Vehicle) => (
        <VehicleListItem vehicle={vehicle} modelName={"vehicles"} />
      )}
      availableFilters={filterableFields}
      orderableFields={orderableFields}
    />
  );
}

export default function DocumentScreen() {
  let params: Params = useParams();
  const { get, data, isLoading } = useUserDetailViewModel(params.id);
  const { t } = useTranslation();

  useEffect(() => {
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  if (isLoading || !data) {
    return <Spinner type="grow" />;
  }

  return (
    <div className={styles.ScreenContainer}>
      <Header title={t("User")}>
        <></>
      </Header>
      <DocumentScreenBody>
        <Card className={styles.content}>
          <div className={styles.imagewrapper}>
            <Thumbnail
              src={getUriOrPlaceholder(data?.profile?.profile_picture?.medium)}
              size={ThumbnailSize.medium}
            />
            <div className={styles.namewrapper}>
              <Highlight>{data.first_name}</Highlight>
              <span className="ml-2">{data.last_name}</span>
            </div>
          </div>
          <div className={styles.contentRow}>
            <KeyValueText title={t("Company")}>
              {"" + data?.profile?.company}
            </KeyValueText>
            <KeyValueText title={t("Email")}>{data.email}</KeyValueText>
          </div>
          <div className={styles.contentRow}>
            <KeyValueText title={t("Department")}>
              {data?.profile?.department?.name || t("N/A")}
            </KeyValueText>
            <KeyValueText title={t("Phone")}>
              {data?.profile?.phone || t("N/A")}
            </KeyValueText>
          </div>
        </Card>
        <ElementWrapper title={""}>
          <Card>
            <div className={styles.content}>
              {data.groups.map((group) => (
                <KeyValueText key={group} title={t("Is a")}>
                  <Emphasize>{getOneGroupTranslation(group)}</Emphasize>
                </KeyValueText>
              ))}
              <KeyValueText title={t("End of contract")}>
                {data?.profile?.contract_end_date || t("N/A")}
              </KeyValueText>
            </div>
          </Card>
        </ElementWrapper>
        <VehicleSubView userId={parseInt(params.id)} />
      </DocumentScreenBody>
    </div>
  );
}
