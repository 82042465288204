import _ from "lodash";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import { Damage, Part } from "../../../../../Config/datamodels/interfaces";
import styles from "./TransferDamageModal.module.css";
import { LanguageContext } from "../../../../../Config/i18n/LanguageGate";
import { EditableKeyValue } from "../../../../../Common/Components/KeyValueTextInput";
import { Arrow } from "./Arrow";

export interface TransferDamageModalProps {
  title: string;
  isOpen: boolean;
  close: () => void;
  submit: () => void;
  originalDamage: Damage;
  availableParts: Part[];
  transferDamage?: (
    damageId: number,
    partId: number,
    damageType: string
  ) => void;
}

export function TransferDamageModal(props: TransferDamageModalProps) {
  const { lang } = useContext(LanguageContext);
  const { t } = useTranslation();
  const [currentPart, setCurrentPart] = useState<Part>(
    props.originalDamage.part
  );
  const [currentDamageType, setCurrentDamageType] = useState<string>(
    "" + props.originalDamage?.type
  );
  const [touched, setTouched] = useState(false);

  const damageType = useMemo(
    () =>
      _.find(
        props.originalDamage.part.damagetype_set,
        (damageType) => damageType.type === props.originalDamage.type
      ),
    [props.originalDamage]
  );

  const onPartChanged = useCallback(
    (event) => {
      setTouched(true);
      const newPart = _.find(
        props.availableParts,
        (part) => part.id === parseInt(event.target.value)
      );
      if (newPart) {
        setCurrentPart(newPart);
        const allowedDamageTypes = newPart.damagetype_set.map(
          (damage) => damage.type
        );
        if (!allowedDamageTypes.includes(currentDamageType)) {
          setCurrentDamageType(allowedDamageTypes[0]);
        }
      }
    },
    [props.availableParts, currentDamageType]
  );

  const onDamageTypeChanged = useCallback((event) => {
    setCurrentDamageType(event.target.value);
  }, []);

  const partOptions = useMemo(
    () =>
      props.availableParts.map((part) => ({
        label: lang.getSecureTranslation(part),
        value: part.id,
      })),
    [props.availableParts, lang]
  );

  const damageTypeOptions = useMemo(
    () =>
      currentPart
        ? currentPart.damagetype_set.map((damageType) => ({
            label: lang.getSecureTranslation(damageType),
            value: damageType.type,
          }))
        : [],
    [currentPart, lang]
  );

  const existingDamageTypeLabel = useMemo(() => {
    const damageType = props.originalDamage.part.damagetype_set.find(
      (damageType) => damageType.type === props.originalDamage.type
    );
    if (damageType) {
      return lang.getSecureTranslation(damageType);
    }
    return "";
  }, [lang, props]);

  const currentDamageTypeLabel = useMemo(() => {
    const damageType = currentPart.damagetype_set.find(
      (damageType) => damageType.type === currentDamageType
    );
    if (damageType) {
      return lang.getSecureTranslation(damageType);
    }
    return "";
  }, [lang, currentDamageType, currentPart]);

  const close = useCallback(() => {
    setTouched(false);
    props.close();
  }, [setTouched, props]);

  const submit = useCallback(() => {
    props.close();
    setTouched(false);
    const damageType = currentDamageType || currentPart.damagetype_set[0].type;
    if (props.transferDamage) {
      props.transferDamage(props.originalDamage.id, currentPart.id, damageType);
    }
  }, [props, currentPart, currentDamageType]);

  return (
    <Modal isOpen={props.isOpen}>
      <ModalHeader>{props.title}</ModalHeader>
      <ModalBody>
        <table className={styles.summary}>
          <tbody>
            <tr>
              <th>{t("From")}</th>
              <th />
              <th>{t("To")}</th>
            </tr>
            <tr>
              <td>
                <EditableKeyValue
                  title={t("Part")}
                  fieldName={"part"}
                  onChange={onPartChanged}
                  options={partOptions}
                  value={props.originalDamage.part?.id}
                  disabled={true}
                >
                  {lang.getSecureTranslation(props.originalDamage.part)}
                </EditableKeyValue>
              </td>
              <td>
                <Arrow className={styles.arrow} />
              </td>
              <td>
                <EditableKeyValue
                  title={t("Part")}
                  fieldName={"part"}
                  onChange={onPartChanged}
                  options={partOptions}
                  value={currentPart?.id}
                >
                  {lang.getSecureTranslation(props.originalDamage.part)}
                </EditableKeyValue>
              </td>
            </tr>
            <tr>
              <td>
                <EditableKeyValue
                  title={t("Damage Type")}
                  fieldName={"type"}
                  onChange={onDamageTypeChanged}
                  options={[
                    {
                      label: existingDamageTypeLabel,
                      value: props.originalDamage.type,
                    },
                  ]}
                  value={props.originalDamage.type}
                  disabled={true}
                >
                  {damageType && lang.getSecureTranslation(damageType)}
                </EditableKeyValue>
              </td>
              <td>
                <Arrow className={styles.arrow} />
              </td>
              <td>
                <EditableKeyValue
                  title={t("Damage Type")}
                  fieldName={"part"}
                  onChange={onDamageTypeChanged}
                  options={damageTypeOptions}
                  value={currentDamageType}
                >
                  {lang.getSecureTranslation(props.originalDamage.part)}
                </EditableKeyValue>
              </td>
            </tr>
          </tbody>
        </table>
        {touched && (
          <p className={styles.text}>
            {t("You are about to change this damage part / type from")}{" "}
            <span className={styles.warn}>
              {lang.getSecureTranslation(props.originalDamage.part)}
              {"/"}
              {existingDamageTypeLabel}{" "}
            </span>
            {t("to")}{" "}
            <span className={styles.warn}>
              {lang.getSecureTranslation(currentPart)}
              {"/"}
              {currentDamageTypeLabel}
            </span>
          </p>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={submit} disabled={!touched}>
          {t("Transfer")}
        </Button>
        <Button onClick={close}>{t("Cancel")}</Button>
      </ModalFooter>
    </Modal>
  );
}
