// *---- Imports ----
import React from "react";
import { useTranslation } from "react-i18next";
import SearchAndSelectField from "../../../../../Common/Components/FormView/Fields/CustomFields/SearchSelectField";

// *---- Style ----
import styles from "./SelectUser.module.css";
// *---- Components ----

export interface ComponentProps {
  onChange: (value: any) => void;
  value: any;
}
export default function SelectUser<Props extends ComponentProps>(props: Props) {
  const { t } = useTranslation();

  const userField = {
    name: "select",
    value: props.value,
    type: "radio",
    placeholder: t("Select Driver"),
    searchOnField: "email",
    modelName: "users",
  };

  function onChange(value: any) {
    props.onChange(value);
  }
  return (
    <div className={styles.selectDriverField}>
      <p className={styles.label}>{t("Driver Filter")}</p>
      <SearchAndSelectField
        onChange={onChange}
        //@ts-ignore
        field={userField}
      />
    </div>
  );
}
