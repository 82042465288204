import { toast, ToastOptions } from "react-toastify";

const toastOptions: ToastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export default {
  error: (message: string) => toast.error(message, toastOptions),
  success: (message: string) => toast.success(message, toastOptions),
};
