import { useDispatch, useSelector } from "react-redux";
import ListReducer from "../../Common/Store/ListReducer";
import makeUseListReducer from "../../Common/Store/ListReducer/useListReducer";

export default ListReducer("carchecks");
const useListReducer = makeUseListReducer(
  "carchecks",
  useSelector,
  useDispatch
);
export { useListReducer };
