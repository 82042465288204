import { useTranslation } from "react-i18next";
import useAuthViewModel from "../../auth/viewModel/authViewModel";
import {
  FilterCondition,
  TextFilter,
  StringListFilter,
} from "../../Common/Filters";
import {
  SearchSelectField,
  SelectField,
  TimeRulesField,
} from "../../Common/Store/FormReducer/Fields";
import { RuleTypes, Group } from "../../Config/datamodels/interfaces";
import { availableCarcheckTypes } from "../../Config/general";

const useFormFields = () => {
  const { t } = useTranslation();
  const { user } = useAuthViewModel();
  const company = user.profile.company;
  const same_company_filter = new TextFilter("company", "");
  same_company_filter.value = company;
  same_company_filter.condition = FilterCondition.EQEQ;

  return {
    carcheck_type: new SelectField(
      "carcheck_type",
      t("Select ..."),
      t("Type of Carcheck to request"),
      availableCarcheckTypes,
      { required: true }
    ),

    timeRule: new TimeRulesField(
      "timeRule",
      t("Select when the rule should send a request"),
      t("Select when the rule should send a request"),
      [
        { value: RuleTypes.CRON, label: t("Frequency") },
        {
          value: RuleTypes.END_VEHICLE_CONTRACT,
          label: t("Before the end of a vehicle leasing contract"),
        },
        {
          value: RuleTypes.END_EMPLOYEE_CONTRACT,
          label: t("Before the end of an employee contract"),
        },
      ],
      { required: true }
    ),

    owning_partner: new SearchSelectField(
      "owning_partner",
      t("Select a partner company"),
      t("Filter by Leasing company"),
      "partners",
      "name",
      undefined,
      { extraFilters: [same_company_filter] }
    ),

    vehicle_type: new SearchSelectField(
      "vehicle_type",
      t("Select a type"),
      t("Filter by Vehicle type"),
      "vehicle_types",
      "name",
      undefined,
      { extraFilters: [same_company_filter] }
    ),
    user: new SearchSelectField(
      "user",
      t("Carchecker email"),
      t("Do you need a carchecker for this request ?"),
      "users",
      "email",
      undefined,
      {
        extraFilters: [
          new StringListFilter("groups__name", "", Group.CARCHECKERS),
        ],
      }
    ),
  };
};

export default useFormFields;
