import React from "react";
import { CarcheckRule, Group } from "../../../Config/datamodels/interfaces";
import ListView from "../../../Common/Components/ListView";
import { Path } from "../../../Config/Router/utils";
import ListItem from "./ListItem";
import { useTranslation } from "react-i18next";
import useAuthViewModel from "../../../auth/viewModel/authViewModel";
import listViewModel from "../../../rules/viewModel/listViewModel";
import useFields from "./useFields";

export default function ListScreen() {
  const listData = listViewModel();
  const { filterableFields, orderableFields } = useFields();
  const { t } = useTranslation();
  const { groups } = useAuthViewModel();
  const headers = [
    t("Type"),
    t("Time"),
    t("Vehicle Type"),
    t("Owning Company"),
    "",
  ];
  if (groups.includes(Group.BACKOFFICE_USERS)) {
    headers.splice(0, 0, "id");
    headers.splice(1, 0, t("Company"));
  }
  return (
    <ListView<CarcheckRule>
      title={t("Rules")}
      emptyListTitle={t("You haven't create any rules")}
      emptyListDescription={t(
        "Once you have created a new rule, requests will be  generated automatically"
      )}
      basePath={Path.RULES}
      navigationFormButtonLabel={t("Create New Rule")}
      listData={listData}
      headers={headers}
      renderLine={(carcheckRule: CarcheckRule) => (
        <ListItem
          carcheckRule={carcheckRule}
          modelName={"rules"}
          onDelete={listData.deleteById}
        />
      )}
      availableFilters={filterableFields}
      orderableFields={orderableFields}
    />
  );
}
