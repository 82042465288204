import React from "react";
import { Selector } from "../Selector";
import KeyValueText from "../KeyValueText";

export interface EditableKeyValueProps extends React.PropsWithChildren<{}> {
  fieldName: string;
  title: string;
  hide?: boolean;
  readonly?: boolean;
  onChange: (event: any) => void;
  value: any;
  options: { label: string; value: any }[];
  disabled?: boolean;
}

export function EditableKeyValue(props: EditableKeyValueProps) {
  return (
    <KeyValueText shouldWrap title={props.title} hide={props.hide}>
      {props.readonly ? (
        props.children
      ) : (
        <Selector
          disabled={props.disabled}
          hideLabel={true}
          selector={{
            name: props.fieldName,
            label: props.title,
            value: props.value,
            options: props.options,
          }}
          onChange={props.onChange}
        />
      )}
    </KeyValueText>
  );
}
