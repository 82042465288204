import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import makeUseDetailViewModel from "../../Common/ViewModels/makeUseDetailViewModel";
import { useDetailReducer } from "../store/useDetailReducer";
import { useDebouncedCallback } from "use-debounce/lib";
import useDetailService from "../services/detailService";
import toastService from "../../Common/Services/ToastService";
import { Carcheck, CarcheckImages } from "../../Config/datamodels/interfaces";
import updateService from "../services/updateService";
import getImagesService from "../services/imageDownloadService";
import damageUpdateService from "../../damages/services/updateService";
import updateDamageInCarcheck from "../converters/updateDamageInCarcheck";
import { Path } from "../../Config/Router/utils";
import _ from "lodash";
import { zipAndDownload } from "../../Utils/utils";
import transferService from "../services/transferCarcheckService";
import { ConfirmationModalContext } from "../../Config/ConfirmationModal/ConfirmationModalGate";
import { useTranslation } from "react-i18next";
import { updatePresenceInCarcheckLocally } from "../converters/updatePresenceInCarcheckLocally";
import { updateInventoryPresenceService } from "../services/updateInventoryPresenceService";
import { deleteDamageService } from "../services/deleteDamageService";

const baseViewModel = makeUseDetailViewModel<Carcheck>(
  useDetailService,
  useDetailReducer,
  toastService
);

const useDetailViewModel = (carcheckId: number | string) => {
  const detailViewModel = baseViewModel(carcheckId);
  const { setData, setError, setIsLoading } = useDetailReducer();
  const [isImageDownloading, setImageDownloading] = useState(false);
  const [imageDownloadPercentage, setImageDownloadPercentage] = useState("0");
  const history = useHistory();
  const { t } = useTranslation();
  const confirmationModal = useContext(ConfirmationModalContext);

  const updateCarcheck = async (fieldName: string, value: string) => {
    setIsLoading();
    try {
      await updateService({ [fieldName]: value }, carcheckId);
      const newValue: any = { ...detailViewModel.data };
      newValue[fieldName] = value;
      setData(newValue);
    } catch (err) {
      setError(err);
    }
  };

  const downloadImageZip = async () => {
    setImageDownloading(true);
    try {
      const response = await getImagesService(carcheckId as number);
      const carcheckImages: CarcheckImages = response.data;
      await zipAndDownload(carcheckImages.image_urls, (percentage: number) =>
        setImageDownloadPercentage(String(Math.round(percentage * 100)))
      );
    } catch (error) {
      setError(error);
    } finally {
      setImageDownloading(false);
      setImageDownloadPercentage("0");
    }
  };

  const updateDamage = useDebouncedCallback(
    (damageId: number, body: any, partId: number) => {
      serverUpdateDamage(damageId, body, partId);
    },
    500
  ).callback;

  const serverUpdateDamage = async (
    damageId: number,
    body: any,
    partId: number
  ) => {
    // pre update redux
    const server_damage = await damageUpdateService(body, damageId);
    const field_to_update = _.pickBy(server_damage, (value, key) =>
      ["is_acceptable", "checkly_cost_estimate", "type"].includes(key)
    );

    //update after server response
    updateDamageLocally(damageId, field_to_update, partId);
  };

  const updateDamageLocally = (damageId: number, body: any, partId: number) => {
    const carcheck = updateDamageInCarcheck(
      detailViewModel.data,
      damageId,
      body,
      partId
    );
    setData(carcheck);
  };

  const updateInventoryPresence = async (
    inventoryPresenceId: number,
    newState: boolean | null
  ) => {
    setData(
      updatePresenceInCarcheckLocally(
        detailViewModel.data,
        inventoryPresenceId,
        newState
      )
    );
    updateInventoryPresenceService({ presence: newState }, inventoryPresenceId);
  };

  const deleteDamage = (id: number) => {
    confirmationModal.open(
      t("This will permanently delete the damage, are you sure ?"),
      async () => {
        detailViewModel.setIsLoading();
        try {
          await deleteDamageService(id);
          await detailViewModel.get();
        } catch (err) {
          detailViewModel.setError(err);
        }
      }
    );
  };

  const transferDamage = async (
    damageId: number,
    partId: number,
    damageType: string
  ) => {
    setIsLoading();
    try {
      await damageUpdateService({ part: partId, type: damageType }, damageId);
      await detailViewModel.get();
    } catch (err) {
      setError(err);
    }
  };

  const performFreezeUpdate = (carcheckId: number | string) => {
    updateService({ freeze_update: true }, carcheckId);
    history.push(`${Path.CARCHECKS}/`);
  };

  const freezeCarcheck = (carcheckId: number | string) => {
    confirmationModal.open(
      t(
        "This will permanently freeze the carcheck, you won't be able to update it anymore."
      ),
      () => performFreezeUpdate(carcheckId)
    );
  };

  const performTransferCarcheck = (carcheckId: number | string) => {
    transferService(carcheckId as number);
    history.push(`${Path.CARCHECKS}/`);
  };

  const transferCarcheck = (carcheckId: number | string) => {
    confirmationModal.open(
      t(
        "You are about to transfer the carcheck into your Repairnet account. This transfer might result into the creation of one to many work orders, depending on the damages."
      ),
      () => performTransferCarcheck(carcheckId)
    );
  };

  return {
    ...baseViewModel(carcheckId),
    updateCarcheck,
    updateDamage,
    updateDamageLocally,
    freezeCarcheck,
    transferCarcheck,
    downloadImageZip,
    isImageDownloading,
    imageDownloadPercentage,
    updateInventoryPresence,
    deleteDamage,
    transferDamage,
  };
};

export default useDetailViewModel;
