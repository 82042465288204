import { RESTQueryBuilder } from "../../Common/ApiGateway/buildRESTUrl";
import { HTTPPostService } from "../../Common/Services/types";
import Axios from "axios";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";

export const makeCarcheckTransferUrl = (
  urlBuilder: RESTQueryBuilder,
  carcheckId: number
) => {
  return urlBuilder({
    resource: "carchecks",
    action: `${carcheckId}/transfer_carcheck`,
  });
};

export const makeCarcheckTransferService = (
  urlBuilder: RESTQueryBuilder,
  postService: HTTPPostService<any>
) => {
  const transferService = async (carcheckId: number) => {
    const url = makeCarcheckTransferUrl(urlBuilder, carcheckId);
    return await postService.post(url, {});
  };

  return transferService;
};

export default makeCarcheckTransferService(buildRESTUrl, Axios);
