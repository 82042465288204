import Axios from "axios";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";
import makeRESTUpdateService from "../../Common/Services/UpdateService";
import { Company } from "../../Config/datamodels/interfaces";

const updateService = makeRESTUpdateService<Company>(
  "companies",
  buildRESTUrl,
  Axios
);
export default updateService;
