import Axios from "axios";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";
import makeRESTUpdateService from "../../Common/Services/UpdateService";
import { Vehicle } from "../../Config/datamodels/interfaces";

const updateService = makeRESTUpdateService<Vehicle>(
  "vehicles",
  buildRESTUrl,
  Axios
);
export default updateService;
