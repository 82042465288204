import React from "react";
import { useHistory } from "react-router";
import styles from "./DocumentLink.module.css";

export interface DocumentLinkProps {
  children: JSX.Element[] | JSX.Element | string;
  resource: string;
  id: number | string;
}

export default function DocumentLink(props: DocumentLinkProps) {
  const history = useHistory();
  return (
    <span
      className={styles.documentlink}
      onClick={() => history.push(`/${props.resource}/${props.id}`)}
    >
      {props.children}
    </span>
  );
}
