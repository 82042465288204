import thunk from "redux-thunk";
import reducers from "./combineReducer";
import storage from "redux-persist/lib/storage";
import { applyMiddleware, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { createLogger } from "redux-logger";
import { createBlacklistFilter } from "redux-persist-transform-filter";

const blackList = createBlacklistFilter("auth", ["error"]);

const customReconciler = (inboundState: any, originalState: any) => {
  if (
    inboundState?._persist?.version < (originalState?._persist?.version || 0)
  ) {
    return originalState;
  } else return inboundState;
};

const persistConfig = {
  key: "root",
  whitelist: ["auth", "usersForm", "vehiclesForm"],
  storage,
  transforms: [blackList],
  version: 2,
  stateReconciler: customReconciler,
};

const logMiddleware = createLogger({
  predicate: () => process.env.NODE_ENV === "development",
});

const persistedReducer = persistReducer(persistConfig, reducers);

let store = createStore(
  persistedReducer,
  applyMiddleware(logMiddleware, thunk)
);

export let persistor = persistStore(store);
export default store;

// @ts-ignore
window.store = store;
