import React from "react";
import Card from "../../../../../Common/Components/Card";
import CardSeparatorTitle from "../../../../../Common/Components/CardSeparatorTitle";
import KeyValueText from "../../../../../Common/Components/KeyValueText";
import { useTranslation } from "react-i18next";

import styles from "./TotalCosts.module.css";

export interface TotalCostProps {
  acceptableCost: number;
  nonAcceptable: number;
}

export default function TotalCosts({
  acceptableCost,
  nonAcceptable,
}: TotalCostProps) {
  const { t } = useTranslation();
  return (
    <Card className={styles.card_column_direction}>
      <CardSeparatorTitle>{t("Total Costs")}</CardSeparatorTitle>

      <KeyValueText shouldWrap title={t("Total acceptable costs")}>
        {acceptableCost}
      </KeyValueText>
      <KeyValueText shouldWrap title={t("Total non acceptable costs")}>
        {nonAcceptable}
      </KeyValueText>
    </Card>
  );
}
