import { useMemo } from "react";
import {
  clusterDamageAndPartsByPage,
  tagRepairedDamageInPageCluster,
} from "../converters/clusterDamageAndPartsByPage";
import useDetailViewModel from "./useDetailViewModel";

const usePrintViewModel = (carcheckId: number | string) => {
  const detail = useDetailViewModel(carcheckId);

  const damage_per_part =
    detail.data && detail.data.damage_per_part
      ? detail.data.damage_per_part
      : [];

  // selectors
  const perPageDamages = useMemo(() => {
    const pageCluster = clusterDamageAndPartsByPage(damage_per_part);
    return tagRepairedDamageInPageCluster(pageCluster, carcheckId);
  }, [damage_per_part, carcheckId]);

  const hasInventory =
    detail.data &&
    detail.data.inventorypresence_set &&
    detail.data.inventorypresence_set.length;
  const damageCount = perPageDamages?.length || 0;
  const inventoryCount = hasInventory ? 1 : 0;
  const totalPageCount = 2 + damageCount + inventoryCount;

  return {
    ...detail,
    perPageDamages,
    totalPageCount,
  };
};

export default usePrintViewModel;
