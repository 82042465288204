import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Path } from "./utils";
import { Menu, MenuFooter, Layout, ContentArea, MenuBody, Link } from "../Menu";
import UserListScreen from "../../users/screens/ListScreen";
import UserFormScreen from "../../users/screens/FormScreen";
import UserUpdateScreen from "../../users/screens/UpdateScreen";
import UserDocumentScreen from "../../users/screens/DocumentScreen";
import UserImportScreen from "../../users/screens/ImportScreen";
import VehicleDocumentScreen from "../../vehicles/screens/DocumentScreen";
import VehicleListScreen from "../../vehicles/screens/ListScreen";
import VehicleFormScreen from "../../vehicles/screens/FormScreen";
import VehicleImportScreen from "../../vehicles/screens/ImportScreen";
import VehicleUpdateScreen from "../../vehicles/screens/UpdateScreen";
import RequestsListScreen from "../../requests/screens/ListScreen";
import RulesFormScreen from "../../rules/screens/FormScreen";
import LoginScreen from "../../auth/screens/LoginScreen";
import ResetPasswordScreen from "../../auth/screens/ResetPasswordScreen";
import ActivateScreen from "../../auth/screens/ActivateScreen";
import ProtectedRoute from "../../auth/components/ProtectedRoute";
import Logout from "../../auth/components/Logout";
import RulesListScreen from "../../rules/screens/ListScreen";
import CarcheckDocumentScreen from "../../carchecks/screens/DocumentScreen";
import CarcheckPrintScreen from "../../carchecks/screens/PrintScreen";
import CarcheckListScreen from "../../carchecks/screens/ListScreen";
import Reporting from "../../reporting/screens/DocumentScreen";
import PartnerListScreen from "../../partners/screens/ListScreen";
import PartnerFormScreen from "../../partners/screens/FormScreen";
import DepartmentFormScreen from "../../departments/screens/FormScreen";
import DepartmentListScreen from "../../departments/screens/ListScreen";
import IfGroup from "../../auth/components/IfGroup";
import PrivacyPolicyScreen from "../../privacyPolicy/screens";
import { Group } from "../datamodels/interfaces";
import ReportingPrintScreen from "../../reporting/screens/PrintScreen";
import CompanyUpdateScreen from "../../company/screens/UpdateScreen";

export function RouterBase() {
  // *---- Setup ----
  const { t } = useTranslation();
  // *---- Selectors ----

  return (
    <Layout>
      <Menu
        hideOnStartsWith={[Path.LOGIN, Path.ACTIVATE, Path.RESET, Path.PRIVACY]}
        hideOnEndsWith={["print"]}
      >
        <MenuBody>
          <IfGroup
            groups={[
              Group.FLEET_MANAGERS,
              Group.BACKOFFICE_USERS,
              Group.HR_OFFICERS,
            ]}
          >
            <Link to={`${Path.USERS}/`}>{t("Users")}</Link>
          </IfGroup>
          <Link to={`${Path.VEHICLES}/`}>{t("Vehicles")}</Link>
          <IfGroup groups={[Group.FLEET_MANAGERS, Group.BACKOFFICE_USERS]}>
            <Link to={`${Path.RULES}/`}>{t("Rules")}</Link>
          </IfGroup>
          <IfGroup groups={[Group.FLEET_MANAGERS, Group.BACKOFFICE_USERS]}>
            <Link to={`${Path.REQUESTS}/`}>{t("Requests")}</Link>
          </IfGroup>
          <Link to={`${Path.CARCHECKS}/`}>{t("Carchecks")}</Link>
          <IfGroup groups={[Group.FLEET_MANAGERS, Group.BACKOFFICE_USERS]}>
            <Link to={`${Path.PARTNERS}/`}>{t("Partners")}</Link>
          </IfGroup>
          <IfGroup
            groups={[
              Group.FLEET_MANAGERS,
              Group.BACKOFFICE_USERS,
              Group.HR_OFFICERS,
            ]}
          >
            <Link to={`${Path.DEPARTMENTS}/`}>{t("Departments")}</Link>
          </IfGroup>
          <IfGroup groups={[Group.BACKOFFICE_USERS]}>
            <Link
              to="no"
              onClick={() => {
                throw new Error("testing sentry (don't ignore please)");
              }}
            >
              {t("Test sentry")}
            </Link>
          </IfGroup>
        </MenuBody>
        <MenuFooter>
          <IfGroup groups={[Group.FLEET_MANAGERS]}>
            <Link to={`${Path.COMPANIES}/`}>{t("Config")}</Link>
          </IfGroup>
          <Link to={`${Path.LOGOUT}/`}>{t("Log out")}</Link>
        </MenuFooter>
      </Menu>
      <ContentArea>
        <Switch>
          <Route exact path={Path.BASE}>
            <ProtectedRoute>
              <Redirect from={Path.BASE} to={Path.CARCHECKS} />
            </ProtectedRoute>
          </Route>
          <Route path={Path.PRIVACY}>
            <PrivacyPolicyScreen />
          </Route>
          <Route path={Path.LOGIN}>
            <LoginScreen />
          </Route>
          <Route path={Path.RESET}>
            <ResetPasswordScreen />
          </Route>
          <Route path={Path.LOGOUT}>
            <Logout />
          </Route>
          <Route path={`${Path.ACTIVATE}/:token`}>
            <ActivateScreen />
          </Route>
          <Route exact path={Path.USERS}>
            <ProtectedRoute>
              <UserListScreen />
            </ProtectedRoute>
          </Route>
          <Route exact path={`${Path.USERS}/form`}>
            <ProtectedRoute>
              <UserFormScreen />
            </ProtectedRoute>
          </Route>
          <Route exact path={`${Path.USERS}/import`}>
            <UserImportScreen />
          </Route>
          <Route path={`${Path.USERS}/:id/form`}>
            <ProtectedRoute>
              <UserUpdateScreen />
            </ProtectedRoute>
          </Route>
          <Route path={`${Path.USERS}/:id`}>
            <ProtectedRoute>
              <UserDocumentScreen />
            </ProtectedRoute>
          </Route>
          <Route exact path={Path.VEHICLES}>
            <ProtectedRoute>
              <VehicleListScreen />
            </ProtectedRoute>
          </Route>
          <Route exact path={`${Path.VEHICLES}/form`}>
            <ProtectedRoute>
              <VehicleFormScreen />
            </ProtectedRoute>
          </Route>
          <Route exact path={`${Path.VEHICLES}/import`}>
            <ProtectedRoute>
              <VehicleImportScreen />
            </ProtectedRoute>
          </Route>
          <Route path={`${Path.VEHICLES}/:id/form`}>
            <ProtectedRoute>
              <VehicleUpdateScreen />
            </ProtectedRoute>
          </Route>
          <Route path={`${Path.VEHICLES}/:id`}>
            <ProtectedRoute>
              <VehicleDocumentScreen />
            </ProtectedRoute>
          </Route>
          <Route path={`${Path.CARCHECKS}/:id/print`}>
            <ProtectedRoute>
              <CarcheckPrintScreen />
            </ProtectedRoute>
          </Route>
          <Route path={`${Path.CARCHECKS}/:id`}>
            <ProtectedRoute>
              <CarcheckDocumentScreen />
            </ProtectedRoute>
          </Route>
          <Route path={Path.CARCHECKS}>
            <ProtectedRoute>
              <CarcheckListScreen />
            </ProtectedRoute>
          </Route>
          <Route exact path={`${Path.RULES}/form`}>
            <ProtectedRoute>
              <RulesFormScreen />
            </ProtectedRoute>
          </Route>
          <Route exact path={Path.RULES}>
            <ProtectedRoute>
              <RulesListScreen />
            </ProtectedRoute>
          </Route>
          <Route exact path={Path.REQUESTS}>
            <ProtectedRoute>
              <RequestsListScreen />
            </ProtectedRoute>
          </Route>
          <Route exact path={`${Path.REPORTING}/:vehicleId`}>
            <ProtectedRoute>
              <Reporting />
            </ProtectedRoute>
          </Route>
          <Route
            exact
            path={`${Path.REPORTING}/:leftCarcheckId/:rightCarcheckId/print`}
          >
            <ProtectedRoute>
              <ReportingPrintScreen />
            </ProtectedRoute>
          </Route>
          <Route exact path={`${Path.PARTNERS}/form`}>
            <ProtectedRoute>
              <PartnerFormScreen />
            </ProtectedRoute>
          </Route>
          <Route exact path={Path.PARTNERS}>
            <ProtectedRoute>
              <PartnerListScreen />
            </ProtectedRoute>
          </Route>
          <Route exact path={`${Path.DEPARTMENTS}/form`}>
            <ProtectedRoute>
              <DepartmentFormScreen />
            </ProtectedRoute>
          </Route>
          <Route exact path={Path.DEPARTMENTS}>
            <ProtectedRoute>
              <DepartmentListScreen />
            </ProtectedRoute>
          </Route>
          <Route exact path={Path.COMPANIES}>
            <ProtectedRoute>
              <CompanyUpdateScreen />
            </ProtectedRoute>
          </Route>
          <Redirect from={Path.BASE} to={Path.BASE} />
        </Switch>
      </ContentArea>
    </Layout>
  );
}

export default function Router() {
  return (
    <BrowserRouter>
      <RouterBase />
    </BrowserRouter>
  );
}
