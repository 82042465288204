// *---- Imports ----
import React from "react";
import { Redirect } from "react-router-dom";
import { Path } from "../../../Config/Router/utils";
import useAuthViewModel from "../../viewModel/authViewModel";
// *---- Style ----

// *---- Components ----

export interface ProtectedRouteProps {
  children: JSX.Element;
}
export default function ProtectedRoute<Props extends ProtectedRouteProps>({
  children,
}: Props) {
  const { user } = useAuthViewModel();

  if (!user) {
    return <Redirect to={Path.LOGIN} />;
  }
  return <>{children}</>;
}
