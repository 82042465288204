import makeListViewModel from "../../Common/ViewModels/makeUseListViewModel";
import { CarcheckListEntry } from "../../Config/datamodels/interfaces";
import listService from "../services/listService";
import { useListReducer } from "../store/useListReducer";

const listViewModel = makeListViewModel<CarcheckListEntry>(
  listService,
  useListReducer
);
export default listViewModel;
