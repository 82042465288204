import { Validators } from "@lemoncode/fonk";
import { createFinalFormValidation } from "@lemoncode/fonk-final-form";
import { useTranslation } from "react-i18next";

const useValidation = () => {
  const { t } = useTranslation();

  const fields = {
    vin: [
      {
        validator: Validators.required.validator,
        message: t("Please fill in this mandatory field"),
      },
    ],
    plate: [],
    brand: [
      {
        validator: Validators.required.validator,
        message: t("Please fill in this mandatory field"),
      },
    ],
    model: [
      {
        validator: Validators.required.validator,
        message: t("Please fill in this mandatory field"),
      },
    ],
    year: [
      {
        validator: Validators.required.validator,
        message: t("Please fill in this mandatory field"),
      },
    ],
    leasing_start_date: [
      {
        validator: Validators.required.validator,
        message: t("Please fill in this mandatory field"),
      },
    ],
    leasing_end_date: [
      {
        validator: Validators.required.validator,
        message: t("Please fill in this mandatory field"),
      },
    ],
    km_contract: [
      {
        validator: Validators.required.validator,
        message: t("Please fill in this mandatory field"),
      },
    ],
    driver: [],
    type: [
      {
        validator: Validators.required.validator,
        message: t("Please fill in this mandatory field"),
      },
    ],
    owning_partner: [
      {
        validator: Validators.required.validator,
        message: t("Please fill in this mandatory field"),
      },
    ],
    department: [],
  };

  return createFinalFormValidation({ field: fields });
};

export default useValidation;
