import { RESTQueryBuilder } from "../../Common/ApiGateway/buildRESTUrl";
import { HTTPGETService } from "../../Common/Services/types";
import { User } from "../../Config/datamodels/interfaces";

export type GetMeService = () => Promise<User>;

const makeGetMeService = (
  urlBuilder: RESTQueryBuilder,
  getService: HTTPGETService<User>
) => async () => {
  const url = urlBuilder({ resource: "users", action: "me" });
  const response = await getService.get(url);
  return response.data;
};

export default makeGetMeService;
