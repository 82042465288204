import { IMPORT_ERROR, IMPORT_SUCCESS, LOADING_IMPORT } from ".";
import { UseDispatch, UseSelector } from "../types";
import _ from "lodash";

export type UseImportReducer = () => {
  importErrors: any;
  success: boolean;
  isLoading: boolean;
  setIsLoading: () => void;
  onSuccess: () => void;
  onError: (err: any) => void;
};

const makeUseImportReducer = (
  modelName: string,
  selectorHook: UseSelector<any>,
  dispatchHook: UseDispatch
) => () => {
  const dispatch = dispatchHook();

  const importErrors = selectorHook((state: any) =>
    _.get(state, `${modelName}Import.error`, null)
  );
  const success = selectorHook((state: any) =>
    _.get(state, `${modelName}Import.success`, [])
  );
  const isLoading = selectorHook((state: any) =>
    _.get(state, `${modelName}Import.loading`, [])
  );

  const setIsLoading = () => dispatch({ modelName, type: LOADING_IMPORT });
  const onSuccess = () => dispatch({ modelName, type: IMPORT_SUCCESS });
  const onError = (err: any) =>
    dispatch({ modelName, type: IMPORT_ERROR, payload: err });

  return {
    importErrors,
    success,
    isLoading,
    setIsLoading,
    onSuccess,
    onError,
  };
};

export default makeUseImportReducer;
