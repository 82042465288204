import deltaCarcheckService from "../services/deltaCarcheckService";
import deltaDamageService from "../services/deltaDamageService";
import { useDeltaReducer } from "../store/deltaReducer";
import { createDelta } from "../converters/deltaConverter";
import {
  clusterDamageAndPartsByPage,
  DamagePerPage,
} from "../../carchecks/converters/clusterDamageAndPartsByPage";
import { Carcheck } from "../../Config/datamodels/interfaces";
import { useEffect } from "react";

type UsePrintViewModelReturn = {
  data: { carcheck: Carcheck; damagePerPage: DamagePerPage };
  isSuccess: boolean;
  isLoading: boolean;
  totalPageCount: number;
};

const usePrintViewModel = (
  leftCarcheckId: number | string,
  rightCarcheckId: number | string
): UsePrintViewModelReturn => {
  const { data, isSuccess, isLoading, setData, setError } = useDeltaReducer();

  const get = async () => {
    try {
      const [left, right, leftDamages, rightDamages] = await Promise.all([
        deltaCarcheckService(leftCarcheckId),
        deltaCarcheckService(rightCarcheckId),
        deltaDamageService(leftCarcheckId),
        deltaDamageService(rightCarcheckId),
      ]);
      const carcheck = createDelta(left, right, leftDamages, rightDamages);
      if (!carcheck.damage_per_part)
        throw new Error("Carcheck should have a damage per page");
      const damagePerPage = clusterDamageAndPartsByPage(
        carcheck.damage_per_part
      );
      // make sure participations wont show
      carcheck.participation_set = [];
      setData({ carcheck, damagePerPage });
    } catch (err) {
      setError(err);
    }
  };

  const hasInventory = !!(
    data &&
    data.carcheck &&
    data.carcheck.inventorypresence_set &&
    data.carcheck.inventorypresence_set.length
  );
  const damageCount = data ? data.damagePerPage?.length || 0 : 0;
  const inventoryCount = hasInventory ? 1 : 0;
  const totalPageCount = 2 + damageCount + inventoryCount;

  useEffect(() => {
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftCarcheckId, rightCarcheckId]);

  return {
    data,
    isSuccess,
    isLoading,
    totalPageCount,
  };
};

export default usePrintViewModel;
