import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./Config/store";
import * as Sentry from "@sentry/react";
import Router from "./Config/Router";
import LanguageGate from "./Config/i18n/LanguageGate";
import "./Config/axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "rc-slider/assets/index.css";
import { ToastContainer } from "react-toastify";
import HeaderMeta from "./Common/Components/HeaderMeta";
//@ts-ignore
import HttpsRedirect from "react-https-redirect";
import ConfirmationModalGate from "./Config/ConfirmationModal/ConfirmationModalGate";

console.log("Initing sentry");
if (process.env.REACT_APP_SENTRY_URL) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_URL });
  console.log("Sentry has initialized");
}
console.log(`Sentry dsn: ${process.env.REACT_APP_SENTRY_URL}`);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HttpsRedirect>
          <LanguageGate>
            <ConfirmationModalGate>
              <HeaderMeta />
              <Router />
              <ToastContainer />
            </ConfirmationModalGate>
          </LanguageGate>
        </HttpsRedirect>
      </PersistGate>
    </Provider>
  );
}

export default App;
