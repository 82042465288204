import Axios from "axios";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";
import makeRESTCreateService from "../../Common/Services/CreateService";
import { Company } from "../../Config/datamodels/interfaces";

const createService = makeRESTCreateService<Company>(
  "companies",
  buildRESTUrl,
  Axios
);
export default createService;
