export enum LogLevel {
  DEBUG = "debug",
  INFO = "info",
  WARN = "warn",
  ERROR = "error",
}

const log = (level: LogLevel, name: string, extra: any, msg: any) =>
  console.log({ level, name, ...extra, msg });

export const makeLogger = (name: string, extra: any = {}) => {
  return {
    [LogLevel.DEBUG]: (msg: any) => log(LogLevel.DEBUG, name, extra, msg),
    [LogLevel.INFO]: (msg: any) => log(LogLevel.INFO, name, extra, msg),
    [LogLevel.WARN]: (msg: any) => log(LogLevel.WARN, name, extra, msg),
    [LogLevel.ERROR]: (msg: any) => log(LogLevel.ERROR, name, extra, msg),
  };
};
