import React, { useCallback } from "react";
import { Model } from "../../../Config/datamodels/interfaces";
import Header from "../Header";
import Emphasize from "../Emphasize";
import {
  Container,
  Row,
  Col,
  Alert,
  UncontrolledTooltip,
  Button,
  Spinner,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import styles from "./ImportView.module.css";
import { FileArrowUp } from "react-bootstrap-icons";
import { useDropzone } from "react-dropzone";
import _ from "lodash";
import { ImportViewModelReturn } from "../../ViewModels/makeUseImportViewModel";

export interface ImportViewProps<ModelType extends Model> {
  importData: ImportViewModelReturn;
  title: string;
  description: string;
  exampleFilePath?: string;
}

export interface FeedbackProps {
  success?: boolean;
  failure?: boolean;
}

export function formatErrorMsg(msg: any): string {
  if (typeof msg === "string") return msg;
  if (Array.isArray(msg)) return msg.map(formatErrorMsg).join(", ");
  const keys = _.keys(msg);
  let stringifiedMsg = "";
  for (const key of keys) {
    stringifiedMsg += key + " : " + formatErrorMsg(msg[key]);
  }
  return stringifiedMsg;
}

export function Feedback(props: FeedbackProps) {
  const { t } = useTranslation();

  if (props.failure) {
    return (
      <Alert color="danger">
        <div className={styles.feedbacktitle}>Error</div>
        <p>{t("There were errors during import")}</p>
      </Alert>
    );
  } else if (props.success) {
    return (
      <Alert color="success">
        <div className={styles.feedbacktitle}>Success</div>
        <p>{t("Successfully uploaded and processed the import")}</p>
      </Alert>
    );
  } else {
    return (
      <Alert color="info">
        <div className={styles.feedbacktitle}>{t("Import")}</div>
        <p>{t("Waiting for an import to begin with")}</p>
      </Alert>
    );
  }
}

export function Report(props: { errors: any }) {
  const { t } = useTranslation();
  if (typeof props.errors === "string") {
    return <p>{formatErrorMsg(props.errors)}</p>;
  }
  if (props.errors.general) {
    return <p>{props.errors.general}</p>;
  }
  return (
    <div className={styles.reportlines}>
      {props.errors.reasons.map((err: any, index: number) => (
        <div key={"" + index}>
          <UncontrolledTooltip
            autohide={false}
            target={`import-error-${index}`}
          >
            {formatErrorMsg(err.data)}
          </UncontrolledTooltip>
          <p className={styles.reportline} id={`import-error-${index}`}>
            <Emphasize>{t("Line ") + err.index}</Emphasize>
            <span className="ml-2">{err.msg + " : "}</span>
            <span className="ml-2">{formatErrorMsg(err.data)}</span>
          </p>
        </div>
      ))}
    </div>
  );
}

export default function ImportView<ModelType extends Model>(
  props: ImportViewProps<ModelType>
) {
  const { t } = useTranslation();
  const { upload, success, importErrors } = props.importData;
  const onDrop = useCallback((acceptedFiles) => {
    upload(acceptedFiles[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const uploadZoneClass =
    styles.zone +
    " " +
    styles.upload +
    (isDragActive ? " " + styles.dndreceiverhover : "");

  return (
    <div className={styles.wrapper}>
      <Header shouldGoBack title={props.title}>
        <div className={styles.buttonWrapper}>
          {props.exampleFilePath && (
            <Button
              color="info"
              href={props.exampleFilePath}
              download="example.xlsx"
            >
              {t("Download template")}
            </Button>
          )}
        </div>
      </Header>
      <Container fluid={false}>
        <Row>
          <Col className="col-6">
            <div className={uploadZoneClass} {...getRootProps()}>
              <input {...getInputProps()} />
              <div>
                <p>{props.description}</p>
              </div>
              <div className={styles.dndreceiver}>
                {props.importData.isLoading ? (
                  <Spinner />
                ) : (
                  <>
                    <FileArrowUp size={48} className={styles.bolder} />
                    <p className={styles.bolder}>{t("Drag and drop")}</p>
                    <p>{t("Upload you spreadsheet here")}</p>
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col className="col-6">
            <div className={styles.zone + " " + styles.feedback}>
              {props.importData.isLoading ? (
                <Spinner />
              ) : (
                <>
                  <Feedback success={success} failure={!!importErrors} />
                  {!!importErrors && <Report errors={importErrors} />}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
