import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Input,
  Label,
  Form,
} from "reactstrap";
import { CarcheckRequest } from "../../../Config/datamodels/interfaces";
import { useTranslation } from "react-i18next";
import styles from "./DeletionModal.module.css";
import Emphasize from "../../../Common/Components/Emphasize";
import KeyValueText from "../../../Common/Components/KeyValueText";
import { If } from "../../../Common/Components/If";

export interface DeletionModalProps {
  isOpen?: boolean;
  onClose: () => void;
  request?: CarcheckRequest;
  onDelete: (notify?: boolean) => void;
  isLoading?: boolean;
}

export function DeletionModal(props: DeletionModalProps) {
  const { t } = useTranslation();
  const [sendMessage, setSendMessage] = useState(false);

  useEffect(() => {
    if (!props.isOpen) {
      setSendMessage(false);
    }
  }, [props.isOpen, setSendMessage]);

  return (
    <Modal
      isOpen={props.isOpen}
      onClosed={props.onClose}
      centered={true}
      size="lg"
    >
      <ModalHeader toggle={props.onClose}>
        <h1>{t("Delete request")}</h1>
      </ModalHeader>
      <ModalBody>
        <div className={styles.body}>
          <h2 className={styles.title}>
            {t("About to delete the following carcheck request")}
          </h2>
          <div className={styles.details}>
            <KeyValueText title={"vehicle"}>
              {props.request?.vehicle?.model || ""}{" "}
              {props.request?.vehicle?.brand || ""}
            </KeyValueText>
            <KeyValueText title={"date"}>{props.request?.date}</KeyValueText>
            <If condition={props.request?.user}>
              <KeyValueText title={"with"}>
                {props.request?.user?.first_name || ""}{" "}
                {props.request?.user?.last_name || ""}
              </KeyValueText>
            </If>
            <If
              condition={
                props.request?.is_contradictory_request &&
                props.request?.vehicle?.driver
              }
            >
              <KeyValueText title={"with"}>
                {props.request?.vehicle?.driver?.first_name || ""}{" "}
                {props.request?.vehicle?.driver?.last_name || ""}
              </KeyValueText>
            </If>
          </div>
          <Emphasize>{t("Are you sure ?")}</Emphasize>
          <Form>
            <FormGroup check inline>
              <Input
                type="checkbox"
                checked={sendMessage}
                onChange={() => setSendMessage(!sendMessage)}
              />
              <Label check>{t("Send notification to participants")}</Label>
            </FormGroup>
          </Form>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={props.isLoading}
          color="danger"
          onClick={() => props.onDelete(sendMessage)}
        >
          {t("Continue")}
        </Button>{" "}
        <Button
          disabled={props.isLoading}
          color="secondary"
          onClick={props.onClose}
        >
          {t("Cancel")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
