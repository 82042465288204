import _ from "lodash";
import React from "react";
import { Field } from "react-final-form";
import { Input, FormGroup, Label } from "reactstrap";
import { FormField, ChoiceField } from "../../../Store/FormReducer/Fields";
import { InputType } from "../../../../Config/datamodels/types";
import FieldError from "./CustomFields/ErrorField";
import ImageField from "./CustomFields/ImageField";
import SearchSelectField from "./CustomFields/SearchSelectField";
import SelectMulti from "./CustomFields/SelectMulti";
import TimeRulesField from "./CustomFields/TimeRulesField";
import styles from "./Fields.module.css";
interface FieldSelectorProps {
  field: FormField;
  onChange: (fieldName: string, value: any) => void;
  errors: Error | null;
}

function getFieldError(errors: Error | null, field: FormField) {
  let errorMessage = _.get(errors, `response.data.${field.errorPath}`);
  if (Array.isArray(errorMessage) && errorMessage.length > 0) {
    errorMessage = errorMessage[0];
  }
  return errorMessage;
}

export default function FieldSelector<Props extends FieldSelectorProps>(
  props: Props
) {
  const { field, onChange, errors } = props;

  switch (field.type) {
    case "select":
      return (
        <Field name={field.name}>
          {(finalFormFieldProps) => (
            <FormGroup className={styles.selectField}>
              {field.label && <Label>{field.label}</Label>}
              <Input
                disabled={field.disable}
                className={styles.inputSize}
                data-testid={`selectField-${field.name}`}
                name={field.name}
                type={field.type as InputType}
                allowNull
                parse={(value: any) => (value === "" ? null : value)}
                value={finalFormFieldProps.input.value}
                onChange={(ev) => {
                  // on change to keep track of the value in the reducer

                  const value = ev.target.value;
                  onChange(field.name, value);
                  // react final form onChange that wrap all the form  + validations
                  finalFormFieldProps.input.onChange(ev);
                }}
              >
                <option></option>
                {field.choices?.map((choice: ChoiceField, index: number) => {
                  return (
                    <option key={index} value={choice.value}>
                      {choice.label}
                    </option>
                  );
                })}
              </Input>
              <FieldError
                finalFormProps={finalFormFieldProps}
                fieldError={getFieldError(errors, field)}
              />
            </FormGroup>
          )}
        </Field>
      );

    case "searchSelect":
      return (
        <Field name={field.name}>
          {(finalFormFieldProps) => (
            <FormGroup className={styles.commonField}>
              {field.label && <Label>{field.label}</Label>}
              <SearchSelectField
                field={field}
                value={finalFormFieldProps.input.value}
                onChange={(ev: any) => {
                  // on change to keep track of the value in the reducer
                  const value = ev.value;
                  //retrieving both the  label and the value in the reducer
                  onChange(field.name, ev);
                  // react final form onChange that wrap all the form  + validations
                  finalFormFieldProps.input.onChange(value);
                }}
              />
              <FieldError
                finalFormProps={finalFormFieldProps}
                fieldError={getFieldError(errors, field)}
              />
            </FormGroup>
          )}
        </Field>
      );

    case "selectMulti":
      return (
        <Field name={field.name}>
          {(finalFormFieldProps) => (
            <FormGroup className={styles.commonField}>
              {field.label && <Label>{field.label}</Label>}
              <SelectMulti
                field={field}
                value={finalFormFieldProps.input.value}
                onChange={(value: any[]) => {
                  onChange(field.name, value);
                  finalFormFieldProps.input.onChange(value);
                }}
              />
              <FieldError
                finalFormProps={finalFormFieldProps}
                fieldError={getFieldError(errors, field)}
              />
            </FormGroup>
          )}
        </Field>
      );

    case "timeRules":
      return (
        <Field name={field.name}>
          {(finalFormFieldProps) => (
            <FormGroup className={styles.timeRuleField}>
              {field.label && <Label>{field.label}</Label>}
              <TimeRulesField
                field={field}
                onChange={(rule) => {
                  onChange(field.name, rule);
                  finalFormFieldProps.input.onChange(rule);
                }}
              />
              <FieldError
                finalFormProps={finalFormFieldProps}
                fieldError={getFieldError(errors, field)}
              />
            </FormGroup>
          )}
        </Field>
      );

    case "image":
      return (
        <Field name={field.name}>
          {(finalFormFieldProps) => (
            <FormGroup className={styles.timeRuleField}>
              {field.label && <Label>{field.label}</Label>}
              <ImageField
                field={field}
                onChange={(file: any) => {
                  onChange(field.name, file);
                  finalFormFieldProps.input.onChange(file);
                }}
              />
              <FieldError
                finalFormProps={finalFormFieldProps}
                fieldError={getFieldError(errors, field)}
              />
            </FormGroup>
          )}
        </Field>
      );

    default:
      return (
        <Field name={field.name}>
          {(finalFormFieldProps) => (
            <FormGroup className={styles.commonField}>
              {field.label && <Label>{field.label}</Label>}
              <Input
                disabled={field.disable}
                className={styles.inputSize}
                type={field.type as InputType}
                placeholder={field.placeholder}
                name={finalFormFieldProps.input.name}
                value={finalFormFieldProps.input.value}
                onChange={(ev) => {
                  // on change to keep track of the value in the reducer

                  const value = ev.target.value;
                  onChange(field.name, value);
                  // react final form onChange that wrap all the form  + validations
                  finalFormFieldProps.input.onChange(ev);
                }}
              />
              <FieldError
                finalFormProps={finalFormFieldProps}
                fieldError={getFieldError(errors, field)}
              />
            </FormGroup>
          )}
        </Field>
      );
  }
}
