import { useTranslation } from "react-i18next";
import { TextFilter } from "../../../Common/Filters";

const useFields = () => {
  const { t } = useTranslation();

  const filterableFields = [new TextFilter("carcheck_type", t("Type"))];

  const orderableFields = [
    { name: "", display: t("None") },
    { name: "created", display: t("Created") },
    { name: "carcheck_type", display: t("Type") },
  ];

  return { filterableFields, orderableFields };
};

export default useFields;
