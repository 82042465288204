import React from "react";
import { EditableKeyValue } from "../../../../../Common/Components/KeyValueTextInput";
import {
  Carcheck,
  CarcheckType,
} from "../../../../../Config/datamodels/interfaces";
import { useTranslation } from "react-i18next";
import {
  getCleanliness,
  getCleanlinessOptions,
  toBoolean,
} from "../../../../../Utils/utils";
import { isUndefined } from "lodash";
import { IfCarcheck } from "../../../../../Common/Components/IfCarcheck";

export interface ExteriorCleanSectionProps {
  carcheck: Carcheck;
  onChange: (fieldName: string, value: any) => void;
  disable?: boolean;
}

export function ExteriorCleanSection(props: ExteriorCleanSectionProps) {
  const { t } = useTranslation();
  return (
    <IfCarcheck
      types={[
        CarcheckType.EXHAUSTIVE,
        CarcheckType.RENTA,
        CarcheckType.POOL_CHECK,
      ]}
      currentType={props.carcheck.carcheck_type}
    >
      <EditableKeyValue
        disabled={props.disable}
        fieldName={"is_exterior_clean"}
        title={t("Exterior clean")}
        onChange={(event) =>
          props.onChange("is_exterior_clean", toBoolean(event.target.value))
        }
        value={!!props.carcheck.is_exterior_clean}
        options={getCleanlinessOptions(t)}
        hide={isUndefined(props.carcheck.is_exterior_clean)}
        readonly={props.carcheck.freeze_update}
      >
        {getCleanliness(t, !!props.carcheck.is_exterior_clean)}
      </EditableKeyValue>
    </IfCarcheck>
  );
}
