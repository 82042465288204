import {
  Damage,
  Part,
  PartWithDamage,
} from "../../Config/datamodels/interfaces";
import _ from "lodash";
import { isDamageRepairedInGivenCarcheck } from "../../Common/Converters/isDamageRepaired";

export interface SingleDamageWithPart {
  part: Part;
  damage: Damage;
  isNewPart?: boolean;
  damageCount: number;
}

export type FlatDamagePage = SingleDamageWithPart[];
export type FlatDamagesPerPage = FlatDamagePage[];

export interface GroupedDamage {
  part: Part;
  damages: Damage[];
  isContinued?: boolean;
  damageCount: number;
}
export type DamagePage = GroupedDamage[];
export type DamagePerPage = DamagePage[];

/**
 *
 * Given a list of parts, in which there is a list of damage.
 * Return a list of "pages", that is a list of sections, with the damages to display
 * The number of damages is linked to the number of picture per damage.
 * From trial and error, we can allow up to 5 row of pictures per page
 * (that is in the case where each part has only one row)
 *
 * @param damagesPerPart
 */
export function clusterDamageAndPartsByPage(damagesPerPart: PartWithDamage[]) {
  const pages: FlatDamagesPerPage = [];
  let currentPage: FlatDamagePage = [];
  let pictureRowCount = 0;
  let currentPartId = -1;
  const flatDamages = getFlatDamages(damagesPerPart);

  flatDamages.forEach((damage) => {
    if (pictureRowCount >= 4) {
      pages.push(currentPage);
      currentPage = [];
      pictureRowCount = 0;
    }
    if (damage.part.id !== currentPartId) {
      currentPage.push({ ...damage, isNewPart: true });
      currentPartId = damage.part.id;
    } else {
      currentPage.push(damage);
    }
    const currentPictureRowCount = Math.ceil(damage.damage.pictures.length / 3);
    pictureRowCount += currentPictureRowCount;
  });

  if (currentPage.length) pages.push(currentPage);

  return markDamagesAsContinued(pages.map(regroupDamagesInParts));
}

/**
 * Given a list of pages which contains flat damages, regroup them by parts
 *
 * @param page
 */
export function regroupDamagesInParts(page: FlatDamagePage): DamagePage {
  let currentGroup: GroupedDamage | null = null;
  const groupedPage: DamagePage = [];

  page.forEach((flatDamage) => {
    if (currentGroup && currentGroup.part.id !== flatDamage.part.id) {
      groupedPage.push(currentGroup);
      currentGroup = null;
    }
    if (!currentGroup) {
      currentGroup = {
        part: flatDamage.part,
        damages: [flatDamage.damage],
        damageCount: flatDamage.damageCount,
      };
    } else {
      currentGroup.damages.push(flatDamage.damage);
    }
  });

  if (currentGroup) groupedPage.push(currentGroup);

  return groupedPage;
}

/**
 * Flatten the damages grouped by part (return a list of new objects with part and damage)
 * @param damagesPerPart
 */
export function getFlatDamages(damagesPerPart: PartWithDamage[]) {
  const flatDamages: SingleDamageWithPart[] = [];

  damagesPerPart.forEach((part) => {
    part.damages.forEach((damage) => {
      flatDamages.push({
        part,
        damage,
        damageCount: part.damages.length,
      });
    });
  });

  return flatDamages;
}

/**
 * Now that we've split parts into multiple pages, some parts
 * might have been split between two or more pages.
 *
 * This function will add a isContinued flag on each first part of a page
 * which is being continued from the previous page
 *
 */
export function markDamagesAsContinued(unflaggedPages: DamagePerPage) {
  const pages: DamagePerPage = [];

  unflaggedPages.forEach((page, index) => {
    if (index === 0) pages.push(page);
    else {
      const previousPage = _.last(pages);
      if (!previousPage?.length || !previousPage[0].damages.length)
        throw new Error("Previous page was empty");
      if (!page.length || !page[0].damages.length)
        throw new Error("Got an empty page");
      if (_.last(previousPage)?.part.id === page[0].part.id) {
        const newPage = [{ ...page[0], isContinued: true }, ...page.slice(1)];
        pages.push(newPage);
      } else {
        pages.push(page);
      }
    }
  });

  return pages;
}

export function tagRepairedDamageInPageCluster(
  damagePerPage: DamagePerPage,
  carcheckId: number | string
) {
  return damagePerPage.map((damagePage) =>
    damagePage.map((parts) => ({
      ...parts,
      damages: parts.damages.map((damage) => ({
        ...damage,
        is_repaired: isDamageRepairedInGivenCarcheck(damage, carcheckId),
      })),
    }))
  );
}
