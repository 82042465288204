import i18n from "../i18n";
import { CarcheckType } from "../datamodels/interfaces";

export const availableCarcheckTypes = [
  { value: CarcheckType.PERIODIC, label: i18n.t("Periodic") },
  { value: CarcheckType.EXHAUSTIVE, label: i18n.t("Exhaustive") },
  { value: CarcheckType.TYRES_CHECK, label: i18n.t("Tyres") },
  { value: CarcheckType.RENTA, label: i18n.t("Renta") },
  { value: CarcheckType.RECEPTION, label: i18n.t("Reception") },
];
