import React from "react";
import FormView from "../../../Common/Components/FormView";
import { useTranslation } from "react-i18next";
import useFormViewModel from "../../viewModel/formViewModel";
import { Department } from "../../../Config/datamodels/interfaces";
import { DepartmentFieldName } from "../../viewModel/types";
import useValidation from "./useValidation";

export default function () {
  const formData = useFormViewModel();
  const { t } = useTranslation();
  const validation = useValidation();
  return (
    <FormView<Department, DepartmentFieldName>
      shouldGoBack
      formData={formData}
      validateButtonText={t("Register Department")}
      title={t("New Department")}
      description={t("Create company department")}
      modelName={"departments"}
      validation={validation}
    />
  );
}
