import React from "react";
import FormView from "../../../Common/Components/FormView";
import { useTranslation } from "react-i18next";
import useValidation from "./useValidation";
import useFormViewModel, {
  requestInterceptor,
  responseInterceptor,
} from "../../viewModel/formViewModel";
import { User } from "../../../Config/datamodels/interfaces";
import { UserFormFieldsName } from "../../viewModel/types";

export default function () {
  const { t } = useTranslation();
  const validation = useValidation();
  const formData = useFormViewModel(
    undefined,
    requestInterceptor,
    responseInterceptor
  );

  return (
    <FormView<User, UserFormFieldsName>
      validation={validation}
      shouldGoBack
      //@ts-ignore
      formData={formData}
      validateButtonText={t("Save User")}
      title={t("New User")}
      description={t(
        "To create a new user, please provide all the required information. Take make sure to select the right group  access to you new user"
      )}
      modelName={"users"}
    />
  );
}
