import Axios from "axios";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";
import makeRESTUpdateService from "../../Common/Services/UpdateService";
import { CarcheckRequest } from "../../Config/datamodels/interfaces";

const updateService = makeRESTUpdateService<CarcheckRequest>(
  "requests",
  buildRESTUrl,
  Axios
);
export default updateService;
