import _ from "lodash";
import { selectDamagesPerParts } from "../../Common/Converters/isDamageRepaired";
import {
  Carcheck,
  Damage,
  DamageInPart,
  InventoryPresence,
} from "../../Config/datamodels/interfaces";

export function createDelta(
  leftCarcheck: Carcheck,
  rightCarcheck: Carcheck,
  leftDamages: Damage[],
  rightDamages: Damage[]
) {
  const damageDifference = damageDifferences(
    leftDamages,
    rightDamages,
    leftCarcheck.id !== rightCarcheck.id
  );
  const itemDifference = itemDifferences(leftCarcheck, rightCarcheck);
  const carcheck: Carcheck = {
    ...rightCarcheck,
    damage_per_part: damageDifference,
    inventorypresence_set: itemDifference,
  };
  return carcheck;
}

export function damageDifferences(
  left: Damage[],
  right: Damage[],
  different: boolean
) {
  const leftHandCarcheckDamagedParts = selectDamagesPerParts(left);
  const rightHandCarcheckDamagedParts = selectDamagesPerParts(right);

  if (different) {
    return newDamagedParts(
      rightHandCarcheckDamagedParts,
      leftHandCarcheckDamagedParts
    );
  } else {
    return rightHandCarcheckDamagedParts;
  }
}

export function carchecksAreDifferent(left: Carcheck, right: Carcheck) {
  return left.id !== right.id;
}

export function newDamagedParts(
  rightHandCarcheckDamagedParts: any,
  leftHandCarcheckDamagedParts: any
) {
  const lefHandSidePartsId = leftHandCarcheckDamagedParts.map(
    (part: any) => part.id
  );

  const deltaArray = [...rightHandCarcheckDamagedParts];
  for (let part of rightHandCarcheckDamagedParts) {
    if (lefHandSidePartsId.indexOf(part.id) > -1) {
      part.damages = removeDuplicates<DamageInPart[]>(
        part.damages,
        leftHandCarcheckDamagedParts[lefHandSidePartsId.indexOf(part.id)]
          .damages
      );
    }
    if (!part.damages.length) {
      deltaArray.splice(
        _.findIndex(deltaArray, (p: any) => p.id === part.id),
        1
      );
    }
  }
  return deltaArray;
}

export function itemDifferences(left: Carcheck, right: Carcheck): any {
  const leftHandInventory = left.inventorypresence_set;
  const rightHandInventory = right.inventorypresence_set;
  if (
    rightHandInventory &&
    leftHandInventory &&
    carchecksAreDifferent(left, right)
  ) {
    return removeDuplicatesItems(rightHandInventory, leftHandInventory);
  }
  return rightHandInventory;
}

export function removeDuplicates<T extends { id: number }[]>(
  set: T,
  comparatorSet: T
) {
  let keep = [];
  let comparatorIds = comparatorSet.map((item) => item.id);
  for (let item of set) {
    if (comparatorIds.indexOf(item.id) === -1) {
      keep.push(item);
    }
  }
  return keep as T;
}

export function removeDuplicatesItems(
  set: InventoryPresence[],
  comparatorSet: InventoryPresence[]
) {
  let keep = [];
  let comparatorIds = comparatorSet.map((item) => item.inventory_item.id);
  for (let item of set) {
    if (comparatorIds.indexOf(item.inventory_item.id) === -1) {
      keep.push(item);
    } else if (
      comparatorSet[comparatorIds.indexOf(item.inventory_item.id)].presence !==
      item.presence
    ) {
      keep.push(item);
    }
  }
  return keep;
}
