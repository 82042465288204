import Axios from "axios";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";
import makeRESTCreateService from "../../Common/Services/CreateService";
import { Department } from "../../Config/datamodels/interfaces";

const updateService = makeRESTCreateService<Department>(
  "departments",
  buildRESTUrl,
  Axios
);
export default updateService;
