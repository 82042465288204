import React from "react";
import styles from "./Menu.module.css";
import {
  Link as ReactRouterLink,
  useHistory,
  useLocation,
} from "react-router-dom";
import _ from "lodash";
import logo from "./Checkly.png";
import { Path } from "../Router/utils";
import LanguageToggle from "../LanguageToggle";
import useAuthViewModel from "../../auth/viewModel/authViewModel";
export interface ContentWrapperProps {
  children?: JSX.Element[] | JSX.Element | string;
}

export interface MenuProps extends ContentWrapperProps {
  hideOnStartsWith: string[];
  hideOnEndsWith: string[];
}

export function Menu(props: MenuProps) {
  const location = useLocation();
  if (
    _.chain(props.hideOnStartsWith)
      .map((hideOnPath) => location.pathname.startsWith(hideOnPath))
      .some()
      .value() ||
    _.chain(props.hideOnEndsWith)
      .map((hideOnPath) =>
        location.pathname.replace(/\/+$/, "").endsWith(hideOnPath)
      )
      .some()
      .value()
  ) {
    return <></>;
  }
  return (
    <div className={styles.sidebarwrapper}>
      <div className={styles.sidebar}>{props.children}</div>
    </div>
  );
}

export function Layout(props: ContentWrapperProps) {
  return <div className={styles.wrapper}>{props.children}</div>;
}

export function ContentArea(props: ContentWrapperProps) {
  return <div className={styles.contentarea}>{props.children}</div>;
}

export function CompanyDetails() {
  const { user } = useAuthViewModel();

  return (
    <div className={styles.personal}>
      {user && user.profile && (
        <>
          <div className={styles.company}>
            {_.get(user, "profile.company_logo.xsmall") && (
              <img
                className={styles.companyLogo}
                alt={"" + user.profile.company}
                src={user.profile.company_logo.xsmall}
              />
            )}
            <p className={styles.companyName}>{user.profile.company}</p>
          </div>
          <p>{user.email}</p>
          <LanguageToggle />
        </>
      )}
    </div>
  );
}

export function MenuBody(props: ContentWrapperProps) {
  const history = useHistory();
  const goHome = () => history.push(Path.BASE);
  return (
    <div className={styles.menubody}>
      <img src={logo} className={styles.logo} alt="checkly" onClick={goHome} />
      {props.children}
    </div>
  );
}

export function MenuFooter(props: ContentWrapperProps) {
  return (
    <div className={styles.menufooter}>
      {props.children}
      <CompanyDetails />
    </div>
  );
}

export interface ToObject {
  pathname: string;
  search?: string;
  hash?: string;
  state?: any;
}

export interface LinkProps extends ContentWrapperProps {
  to: string | ToObject;
  onClick?: () => void;
}

export function Link(props: LinkProps) {
  const location = useLocation();
  if (!props.onClick) {
    const linkTo = typeof props.to === "object" ? props.to.pathname : props.to;
    const isActive = location.pathname.startsWith(linkTo);
    const className = styles.link + (isActive ? " " + styles.active : "");
    return (
      <ReactRouterLink {...props} className={className}>
        {props.children}
      </ReactRouterLink>
    );
  } else {
    return (
      <ReactRouterLink {...props} className={styles.link}>
        {props.children}
      </ReactRouterLink>
    );
  }
}
