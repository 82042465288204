import Axios from "axios";
import buildRESTUrl, {
  RESTUriBuilderParams,
} from "../../Common/ApiGateway/buildRESTUrl";
import makeRESTListService from "../../Common/Services/ListService/RESTListService";
import { CarcheckListEntry } from "../../Config/datamodels/interfaces";

const tinyUrlBuilder = (args: RESTUriBuilderParams) => {
  return buildRESTUrl({ ...args, action: "tiny" });
};

const listService = makeRESTListService<CarcheckListEntry>(
  "carchecks",
  tinyUrlBuilder,
  Axios
);
export default listService;
