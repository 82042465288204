import { Model } from "../../../Config/datamodels/interfaces";

export const LOADING_IMPORT = "LOADING_IMPORT";
export const IMPORT_SUCCESS = "IMPORT_SUCCESS";
export const IMPORT_ERROR = "IMPORT_ERROR";

export interface ImportActionBase {
  modelName: string;
}

export interface ImportLoading extends ImportActionBase {
  type: typeof LOADING_IMPORT;
}

export interface ImportSuccess extends ImportActionBase {
  type: typeof IMPORT_SUCCESS;
  payload: any;
}

export interface ImportError extends ImportActionBase {
  type: typeof IMPORT_ERROR;
  payload: any;
}

export type ImportAction = ImportLoading | ImportSuccess | ImportError;

export interface ImportState<ModelType extends Model> {
  loading: boolean;
  error: any;
  success: boolean;
}

export const initialState = <ModelType extends Model>(): ImportState<
  ModelType
> => ({
  loading: false,
  error: null,
  success: false,
});

export default <ModelType extends Model>(modelName: string) => (
  state: any = initialState<ModelType>(),
  action: ImportAction
): ImportState<ModelType> => {
  if (action.modelName !== modelName) return state;
  switch (action.type) {
    case LOADING_IMPORT:
      return { ...state, loading: true, error: null, success: false };
    case IMPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        success: true,
      };
    case IMPORT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      };
    default:
      return state;
  }
};
