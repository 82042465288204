import {
  FormRequestInterceptor,
  FormResponseInterceptor,
} from "../../Common/ViewModels/makeUseFormViewModel";
import { Vehicle } from "../../Config/datamodels/interfaces";
import _ from "lodash";

export const requestInterceptor: FormRequestInterceptor<Vehicle> = (
  body: any
) => {
  body.type = body.type.value;
  body.vin = body.vin.toUpperCase();
  body.driver = _.get(body, "driver.value", null);
  body.owning_partner = body?.owning_partner?.value;
  body.department = body?.department?.value;
  return body;
};

export const responseInterceptor: FormResponseInterceptor<Vehicle> = (
  body: any
) => {
  return Promise.resolve({
    vin: body?.vin,
    plate: body?.plate,
    brand: body?.brand,
    model: body?.model,
    year: body?.year,
    leasing_start_date: body?.leasing_start_date,
    leasing_end_date: body?.leasing_end_date,
    km_contract: body?.km_contract,
    owning_partner: {
      label: body?.owning_partner?.name,
      value: body?.owning_partner?.id,
    },
    department: {
      label: body?.department?.name,
      value: body?.department?.id,
    },
    type: { label: body?.type?.name, value: body?.type?.id },
    driver: { label: body?.driver?.email, value: body?.driver?.id },
  });
};
