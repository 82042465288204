import makeUseImportViewModel from "../../Common/ViewModels/makeUseImportViewModel";
import importService from "../services/ImportService";
import { useImportReducer } from "../store/importReducer";

const importViewModel = makeUseImportViewModel(
  importService,
  useImportReducer,
  "users"
);
export default importViewModel;
