import { Validators } from "@lemoncode/fonk";
import { createFinalFormValidation } from "@lemoncode/fonk-final-form";
import { rangeDate } from "@lemoncode/fonk-range-date-validator";
import { useTranslation } from "react-i18next";

const useValidation = () => {
  const { t } = useTranslation();

  const fields = {
    appointment_date: [
      {
        validator: Validators.required.validator,
        message: t("Please fill in this mandatory field"),
      },
      {
        validator: rangeDate.validator,
        customArgs: {
          parseStringToDateFn: (value: string) => new Date(value),
          min: { value: new Date().setDate(new Date().getDate() - 1) },
          max: { value: new Date("3000-01-01") },
        },
        message: t("The date cannot be before today"),
      },
    ],
    carcheck_type: [
      {
        validator: Validators.required.validator,
        message: t("Please fill in this mandatory field"),
      },
    ],
    user: [],
  };
  // @ts-ignore
  return createFinalFormValidation({ field: fields });
};

export default useValidation;
