import _ from "lodash";
import { FieldValueType, InputType } from "../../../Config/datamodels/types";
import { Filter } from "../../Filters";

export type CustomInputType = "searchSelect" | "timeRules" | "selectMulti";

export interface ChoiceField {
  label: string;
  value: any;
}

export interface FormField {
  name: string;
  value: FieldValueType;
  type: CustomInputType | InputType;
  placeholder: string;
  label?: string | undefined;
  errorPath?: string;
  disable?: boolean;
  choices?: ChoiceField[];
  min?: number;
  max?: number;
  modelName?: string;
  searchOnField?: string;
  primaryKey?: string;
  default?: any;
}

export interface OptionsInterface {
  type?: CustomInputType | InputType;
  errorPath?: string;
  disable?: boolean;
  required?: boolean;
  extraFilters?: Filter[];
  default?: any;
}

type Options = OptionsInterface | undefined;

export type FormFieldMap<FieldsName extends string> = {
  [key in FieldsName]: FormField;
};

export type UpdateFieldMap<FieldsName extends string> = {
  [key in FieldsName]: any;
};

export abstract class BaseFormField implements FormField {
  name: string;
  value: FieldValueType;
  placeholder: string;
  label?: string;
  errorPath?: string;
  disable?: boolean;
  type: CustomInputType | InputType;

  constructor(
    name: string,
    placeholder: string,
    label: string,
    options: Options = undefined
  ) {
    this.value = undefined;
    this.name = name;
    this.placeholder = placeholder;
    this.label = label;
    this.disable = _.get(options, "disable", false);
    this.errorPath = _.get(options, "errorPath")
      ? _.get(options, "errorPath")
      : name;

    if (_.get(options, "required")) {
      this.label = `${this.label} *`;
    }
  }
}

export class NumberField extends BaseFormField {
  min?: number;
  max?: number;

  constructor(
    name: string,
    placeholder: string,
    label: string,
    min: number | undefined = undefined,
    max: number | undefined = undefined,
    options: Options = undefined
  ) {
    super(name, placeholder, label, options);
    this.type = "number";
    this.min = min;
    this.max = max;

    if (this.min && this.max && this.min > this.max) {
      throw new Error(
        `min value ${this.min} should be lower than max value ${this.max} in NumberField ${this.name}`
      );
    }
  }
}

export class SelectField extends BaseFormField {
  choices: ChoiceField[];
  constructor(
    name: string,
    placeholder: string,
    label: string,
    choices: ChoiceField[],
    options: Options = undefined
  ) {
    super(name, placeholder, label, options);
    this.type = "select";
    this.choices = choices;
    this.value = _.get(options, "default", undefined);
  }
}

export class ImageField extends BaseFormField {
  singleImage: boolean;
  constructor(
    name: string,
    placeholder: string,
    label: string,
    options: { singleImage: boolean }
  ) {
    super(name, placeholder, label);
    this.type = "image";
    this.singleImage = _.get(options, "singleImage", false);
  }
}

export class SelectMulti extends SelectField {
  constructor(
    name: string,
    placeholder: string,
    label: string,
    choices: ChoiceField[],
    options: Options = undefined
  ) {
    super(name, placeholder, label, choices, options);
    this.type = "selectMulti";
  }
}

export class SearchSelectField extends BaseFormField {
  modelName: string;
  searchOnField: string;
  primaryKey?: string;
  extraFilters?: Filter[];

  constructor(
    name: string,
    placeholder: string,
    label: string,
    modelName: string,
    searchOnField: string,
    primaryKey: string | undefined = undefined,
    options: Options = undefined
  ) {
    super(name, placeholder, label, options);
    this.type = "searchSelect";
    this.modelName = modelName;
    this.searchOnField = searchOnField;
    this.primaryKey = primaryKey ? primaryKey : searchOnField;
    this.extraFilters = _.get(options, "extraFilters");
  }
}

export class TimeRulesField extends BaseFormField {
  choices: ChoiceField[];
  constructor(
    name: string,
    placeholder: string,
    label: string,
    choices: ChoiceField[],
    options: Options = undefined
  ) {
    super(name, placeholder, label, options);
    this.type = "timeRules";
    this.choices = choices;
  }
}

export class TextField extends BaseFormField {
  constructor(
    name: string,
    placeholder: string,
    label: string,
    options: Options = undefined
  ) {
    super(name, placeholder, label, options);
    this.type = "text";
  }
}

export class DateField extends BaseFormField {
  constructor(
    name: string,
    placeholder: string,
    label: string,
    options: Options = undefined
  ) {
    super(name, placeholder, label, options);
    this.type = "date";
  }
}

export class DateTimeField extends BaseFormField {
  constructor(
    name: string,
    placeholder: string,
    label: string,
    options: Options = undefined
  ) {
    super(name, placeholder, label, options);
    this.type = "datetime-local";
  }
}

export class EmailField extends BaseFormField {
  constructor(
    name: string,
    placeholder: string,
    label: string,
    options: Options = undefined
  ) {
    super(name, placeholder, label, options);
    this.type = "email";
  }
}

export class PhoneField extends BaseFormField {
  constructor(
    name: string,
    placeholder: string,
    label: string,
    options: Options = undefined
  ) {
    super(name, placeholder, label, options);
    this.type = "tel";
  }
}
