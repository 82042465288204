// *---- Imports ----
import React from "react";
import { useTranslation } from "react-i18next";
import KeyValueText from "../../../../Common/Components/KeyValueText";
import SquareThumbnail from "../../../../Common/Components/SquareThumbnail";
import { ThumbnailSize } from "../../../../Common/Components/Thumbnail";
import { Vehicle } from "../../../../Config/datamodels/interfaces";
import { formatDate } from "../../../../Utils/utils";
import style from "./VehicleSectionContent.module.css";

export interface VehicleSectionContentProps {
  vehicle: Vehicle;
  mileage: number;
}

export default function VehicleSectionContent(
  props: VehicleSectionContentProps
) {
  const { t } = useTranslation();

  const leasing: string =
    formatDate(props.vehicle.leasing_start_date) +
    " " +
    formatDate(props.vehicle.leasing_end_date);

  return (
    <>
      <SquareThumbnail
        src={props.vehicle.picture?.xsmall}
        size={ThumbnailSize.smallmedium}
      />
      <div className={style.attributewrapper}>
        <div className={style.attributerow}>
          <div className={style.attribute}>
            <KeyValueText title={t("Plate")}>
              {props.vehicle.plate || t("N/A")}
            </KeyValueText>
          </div>
          <div className={style.attribute}>
            <KeyValueText title={t("Vin")}>{props.vehicle.vin}</KeyValueText>
          </div>
          <div className={style.attribute}>
            <KeyValueText title={t("Brand")}>
              {props.vehicle.brand}
            </KeyValueText>
          </div>
          <div className={style.attribute}>
            <KeyValueText title={t("Model")}>
              {props.vehicle.model}
            </KeyValueText>
          </div>
        </div>
        <div className={style.attributerow}>
          <div className={style.attribute}>
            <KeyValueText title={t("Mileage")}>{props.mileage}</KeyValueText>
          </div>
          <div className={style.attribute}>
            <KeyValueText title={t("Leasing")}>{leasing}</KeyValueText>
          </div>
          <div className={style.attribute}>
            <KeyValueText title={t("Owning Company")}>
              {props.vehicle.owning_partner
                ? props.vehicle.owning_partner.name
                : t("N/A")}
            </KeyValueText>
          </div>
          <div className={style.attribute} />
        </div>
      </div>
    </>
  );
}
