import Axios from "axios";
import buildRESTUrl, {
  RESTQueryBuilder,
  RESTUriBuilderParams,
} from "../../Common/ApiGateway/buildRESTUrl";
import { HTTPPostService } from "../../Common/Services/types";

const makeSendReminderService = (
  modelName: string,
  urlBuilder: RESTQueryBuilder,
  postService: HTTPPostService<any>
) => async (body: { id: number | string }) => {
  const url = urlBuilder({ resource: modelName });
  const response = await postService.post(url, body);
  return response.data;
};

const sendReminderUlBuilder = (args: RESTUriBuilderParams) => {
  return buildRESTUrl({ ...args, action: "remind" });
};

const sendReminderService = makeSendReminderService(
  "requests",
  sendReminderUlBuilder,
  Axios
);
export default sendReminderService;
