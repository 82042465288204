import React from "react";
import { useTranslation } from "react-i18next";
import CompanyLogo from "../../../../auth/components/CompanyLogo";
import { Carcheck } from "../../../../Config/datamodels/interfaces";
import { formatDate, getCarcheckType } from "../../../../Utils/utils";
import styles from "./Header.module.css";

export interface HeaderProps {
  carcheck: Carcheck;
  title: string;
}

export default function Header(props: HeaderProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.header}>
      <CompanyLogo />
      <div className={styles.title}>
        <p>{props.title}</p>
        <p className={styles.type}>
          {getCarcheckType(t, props.carcheck.carcheck_type)}
        </p>
      </div>
      <div className={styles.date}>
        {formatDate(props.carcheck.done_datetime)}
      </div>
    </div>
  );
}
