import { useDispatch, useSelector } from "react-redux";
import FormReducer from "../../Common/Store/FormReducer";
import makeUseFormReducer from "../../Common/Store/FormReducer/useFormReducer";

export default FormReducer("departments");
const useFormReducer = makeUseFormReducer(
  "departments",
  useSelector,
  useDispatch
);
export { useFormReducer };
