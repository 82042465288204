import React from "react";

export interface ArrowProps {
  className: string;
}

export function Arrow(props: ArrowProps) {
  return (
    <svg
      className={props.className}
      width="25"
      height="8"
      viewBox="0 0 25 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.6012 4.35355C24.7964 4.15829 24.7964 3.84171 24.6012 3.64645L21.4192 0.464466C21.2239 0.269204 20.9073 0.269204 20.7121 0.464466C20.5168 0.659728 20.5168 0.976311 20.7121 1.17157L23.5405 4L20.7121 6.82843C20.5168 7.02369 20.5168 7.34027 20.7121 7.53553C20.9073 7.7308 21.2239 7.7308 21.4192 7.53553L24.6012 4.35355ZM0.0551758 4.5H24.2476V3.5H0.0551758V4.5Z"
        fill="black"
        fillOpacity="0.5"
      />
    </svg>
  );
}
