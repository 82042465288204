export interface Model {}

export interface Rules {}
export interface Credentials {
  email: string;
  password: string;
}

export interface ConfirmPassword {
  password: string;
  confirmPassword: string;
}

export interface ResetPassword {
  email: string;
}

export interface PK extends Model {}

export interface User extends Model {
  email: string;
  id: number;
  is_active: boolean;
  profile: Profile;
  username: string;
  first_name: string;
  last_name: string;
  groups: Group[];
  vehicle_set: Vehicle[];
  last_carcheck: Carcheck | null;
}

export interface Company extends Model {
  name: string;
  should_send_signup_mail: boolean;
  notification_email: string;
  logo?: Image;
  logo_image?: Image;
}

export interface Partner extends Model {
  name: string;
  id: number;
}

export interface Image extends Model {
  xsmall: string;
  small: string;
  medium: string;
  image: string;
}

export interface Department extends Model {
  id: number;
  name: string;
  company: PK;
}

export interface VehicleType extends Model {
  name: string;
  id: number;
  company: string;
}

export interface Vehicle extends Model {
  id: number;
  created: Date;
  last_updated: Date;
  vin: string;
  plate: string;
  brand: string;
  model: string;
  year: number;
  leasing_start_date: string;
  leasing_end_date: string;
  km_contract: number;
  mileage: number;
  owning_partner: Partner;
  department: Department;
  type: VehicleType;
  picture: Image | null;
  driver: {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
  };
  carpart_set?: Part[];
}

export enum Languages {
  FR = "fr",
  NL = "nl",
  EN = "en",
}

export interface Profile extends Model {
  id: number;
  company: Company | PK;
  company_logo: Image;
  phone?: string;
  contract_end_date?: string;
  profile_picture?: Image;
  department?: Department;
  created: string;
  language: Languages;
}

export interface PartWithDamage extends Part {
  damages: DamageInPart[];
}

export interface DamageInPart extends Damage {
  position: number;
}

export enum CarcheckType {
  EXHAUSTIVE = "exhaustive",
  PERIODIC = "periodic",
  TYRES_CHECK = "tyres check",
  DAMAGE_CHECK = "damage check",
  POOL_CHECK = "pool check",
  RENTA = "renta",
  RECEPTION = "reception",
}

export interface Carcheck extends Model {
  id: number;
  mileage: number;
  carcheck_type: string;
  dashboard_picture: Image | null;
  done_datetime: string;
  exterior_cleanliness_picture: Image | null;
  is_exterior_clean: boolean | null;
  is_exterior_clean_request: boolean;
  interior_cleanliness_picture: Image | null;
  legal_document_picture: Image | null;
  is_interior_clean: boolean | null;
  is_interior_clean_request: boolean;
  fuel_level: number;
  key_count: number;
  vehicle: Vehicle;
  is_front_left_state_good: TyreState;
  is_front_right_state_good: TyreState;
  is_back_left_state_good: TyreState;
  is_back_right_state_good: TyreState;
  front_tyres_type: TyreType;
  back_tyres_type: TyreType;
  damage_set: Damage[];
  inventorypresence_set?: InventoryPresence[];
  participation_set: Participation[];
  damage_per_part?: PartWithDamage[];
  freeze_update?: boolean;
}

export interface CarcheckImages {
  image_urls: string[];
}

export interface CarcheckListEntry {
  id: number;
  mileage: number;
  carcheck_type: string;
  fuel_level: number;
  done_datetime: string;
  vehicle: { id: number; vin: string; plate: string };
  damage_count: number;
  budget: number;
  tyres: TyreType;
}

export interface InventoryPresence {
  id: number;
  presence: boolean | null;
  inventory_item: Inventory;
}

export interface Inventory {
  category_en: string;
  category_fr: string;
  category_nl: string;
  created: Date;
  id: number;
  last_updated: Date;
  name_en: string;
  name_fr: string;
  name_nl: string;
  vehicle: number;
  position: number;
}

export interface Damage {
  id: number;
  created: Date;
  last_updated: Date;
  is_acceptable: boolean;
  is_repaired: boolean;
  checkly_cost_estimate: string;
  leasing_cost_estimate: string;
  carfixer_cost_estimate: string;
  part: Part;
  type: string | DamageType;
  original_carcheck: number;
  carcheck_presence: number[];
  pictures: Image[];
  participation: Participation[];
  freeze_update?: boolean;
}

export interface DamageType {
  name_fr: string;
  name_en: string;
  name_nl: string;
  type: string;
  position: number;
}

export interface Part {
  id: number;
  damagetype_set: DamageType[];
  created: Date;
  last_updated: Date;
  name_fr: string;
  name_en: string;
  name_nl: string;
  category_fr: string;
  category_en: string;
  category_nl: string;
  vehicle: number;
}

export interface Participation {
  signature: Image;
  user: User;
  comment: string;
}

export interface CarcheckRule extends Model {
  id: number;
  cron: string | null;
  cron_base_date: string | null;
  time_before_end_contract_vehicle: string | null;
  time_before_end_contract_employee: string | null;
  company: string;
  filter: string;
  last_triggered: string;
  carcheck_type: string;
  vehicle_type: VehicleType | null;
  owning_partner: Company | null;
}

export interface CarcheckRequest {
  id: number;
  user_language: string;
  created: string;
  last_updated: string;
  carcheck_type: string;
  date: string;
  vehicle: Vehicle;
  user: User;
  carcheck: number | null;
  status: Status;
  meeting_status: MeetingStatus;
  is_reminder_sent: boolean;
  appointment_date: string;
  cron_rule?: number;
  is_contradictory_request?: boolean;
}

export enum Group {
  DRIVERS = "drivers",
  HR_OFFICERS = "hr_officers",
  CARCHECKERS = "carcheckers",
  QUOTERS = "quoters",
  FLEET_MANAGERS = "fleet_managers",
  BACKOFFICE_USERS = "backoffice_users",
}

export enum TyreType {
  WINTER = "WINTER",
  SUMMER = "SUMMER",
  FOURSEASONS = "FOURSEASONS",
}

export enum TyreState {
  GOOD = "GOOD",
  BAD = "BAD",
  TO_MONITOR = "TO_MONITOR",
}

export enum Status {
  PENDING = "PENDING",
  DONE = "DONE",
  NOT_SENT = "NOT_SENT",
  OVERDUE = "OVERDUE",
}

export enum MeetingStatus {
  NOT_MEETING_REQUIRED = "not_meeting_required",
  WAITING_VALIDATOR_SCHEDULE = "waiting_validator_schedule",
  WAITING_DRIVER_DATE_SELECTION = "waiting_driver_date_selection",
  MEETING_SCHEDULED = "meeting_scheduled",
}

export enum Language {
  FR = "fr",
  EN = "en",
  NL = "nl",
}

export const availableLanguages = [Language.FR, Language.EN, Language.NL];

export enum RuleTypes {
  CRON = "cron",
  END_VEHICLE_CONTRACT = "time_before_end_contract_vehicle",
  END_EMPLOYEE_CONTRACT = "time_before_end_contract_employee",
}

export enum Resources {
  USERS = "users",
}
