import { useTranslation } from "react-i18next";
import { DateFilter, TextFilter } from "../../../Common/Filters";
import {
  DoneFilter,
  NotSentFilter,
  OverdueFilter,
  PendingFilter,
} from "../../viewModel/Filters";

const useFields = () => {
  const { t } = useTranslation();

  const filterableFields = [
    new TextFilter("carcheck_type", t("Type")),
    new DateFilter("appointment_date", t("Date")),
    new TextFilter("user__email", t("User")),
    new TextFilter("vehicle__vin", t("Vehicle")),
    new TextFilter("vehicle__plate", t("Vehicle Plate")),
    new DoneFilter(t("Done")),
    new OverdueFilter(t("Overdue")),
    new NotSentFilter(t("Not sent")),
    new PendingFilter(t("Pending")),
  ];

  const orderableFields = [
    { name: "", display: t("None") },
    { name: "created", display: t("Created") },
    { name: "type", display: t("Type") },
    { name: "appointment_date", display: t("Date") },
    { name: "vehicle__vin", display: t("Vehicle (vin)") },
    { name: "user__email", display: t("User") },
  ];

  return { filterableFields, orderableFields };
};

export default useFields;
