import React, { PropsWithChildren } from "react";
import { Carcheck } from "../../../../Config/datamodels/interfaces";
import Header from "../Header";
import styles from "./Page.module.css";

export interface PageProps extends PropsWithChildren<{}> {
  title: string;
  carcheck: Carcheck;
  index: number;
  total: number;
  displayPrices?: boolean;
  totalAcceptableCost?: number;
  totalNonAcceptableCost?: number;
}

export default function Page(props: PageProps) {
  return (
    <div className={styles.page}>
      <Header carcheck={props.carcheck} title={props.title} />
      <div className={styles.pageBody}>{props.children}</div>
      <div className={styles.pageNumber}>
        {"" + (props.index + 1) + "/" + props.total}
      </div>
    </div>
  );
}
