import {
  Carcheck,
  CarcheckType,
} from "../../../../../Config/datamodels/interfaces";
import { useTranslation } from "react-i18next";
import Selectors from "../../../../../Common/Components/Selectors";
import { getTyreStateOptions } from "../../../../../Utils/utils";
import React from "react";
import { IfCarcheck } from "../../../../../Common/Components/IfCarcheck";

interface TyresStateSectionProps {
  onChange: (fieldName: string, value: any) => void;
  carcheck: Carcheck;
  disable?: boolean;
}

export function TyresStateSection(props: TyresStateSectionProps) {
  const { t } = useTranslation();
  return (
    <IfCarcheck
      types={[
        CarcheckType.EXHAUSTIVE,
        CarcheckType.PERIODIC,
        CarcheckType.TYRES_CHECK,
        CarcheckType.RENTA,
      ]}
      currentType={props.carcheck.carcheck_type}
    >
      {props.carcheck?.is_back_left_state_good !== null && (
        <Selectors
          disabled={props.carcheck.freeze_update || props.disable}
          shouldWrap
          title={t("Tyres Conditions")}
          onChange={(event) =>
            props.onChange(event.target.name, event.target.value)
          }
          selectors={[
            {
              name: "is_front_left_state_good",
              label: t("Front Left Tyre"),
              value: props.carcheck.is_front_left_state_good,
              options: getTyreStateOptions(t),
            },
            {
              name: "is_front_right_state_good",
              label: t("Front Right Tyre"),
              value: props.carcheck.is_front_right_state_good,
              options: getTyreStateOptions(t),
            },
            {
              name: "is_back_left_state_good",
              label: t("Back Left Tyre"),
              value: props.carcheck.is_back_left_state_good,
              options: getTyreStateOptions(t),
            },
            {
              name: "is_back_right_state_good",
              label: t("Back Right Tyre"),
              value: props.carcheck.is_back_right_state_good,
              options: getTyreStateOptions(t),
            },
          ]}
        />
      )}
    </IfCarcheck>
  );
}
