import Axios from "axios";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";
import ToastService from "../../Common/Services/ToastService";
import makeImageUploadService, {
  waitForImageToBeAvailable,
} from "../../Common/Services/ImageService";
import makeUseFormViewModel from "../../Common/ViewModels/makeUseFormViewModel";
import { Company } from "../../Config/datamodels/interfaces";
import createService from "../services/createService";
import detailService from "../services/detailService";
import updateService from "../services/updateService";
import { useFormReducer } from "../store/formReducer";
import { CompanyUpdateFormFields } from "./types";
import useFormFields from "./useFormFields";

const requestInterceptor = async (formValue: any) => {
  const imageService = makeImageUploadService(buildRESTUrl, Axios);

  if (formValue?.logo?.id) {
    formValue.logo = formValue.logo.id;
  } else if (formValue?.logo && formValue.logo?.length > 0) {
    const file = formValue.logo[0];
    const image = await imageService(file);
    await waitForImageToBeAvailable(image);
    formValue.logo = image.id;
  }

  return Promise.resolve(formValue);
};

const responseInterceptor = (model: Company) => {
  model.logo = model.logo_image;
  return Promise.resolve(model);
};

const useFormViewModel = makeUseFormViewModel<Company, CompanyUpdateFormFields>(
  createService,
  updateService,
  detailService,
  useFormReducer,
  useFormFields,
  ToastService
);
export default useFormViewModel;
export { requestInterceptor, responseInterceptor };
