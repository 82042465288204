// *---- Imports ----
import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

// *---- Style ----

// *---- Components ----

export interface ComponentProps {}
export default function HeaderMeta<Props extends ComponentProps>(props: Props) {
  const { t } = useTranslation();
  return (
    <Helmet>
      <title>{t("Checkly | Fleet Status Check")}</title>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff"></meta>
    </Helmet>
  );
}
