// *---- Imports ----
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { types } from "../../auth/store/reducer";
import { FORM_CLEAR_ALL } from "../../Common/Store/FormReducer";

import { availableLanguages, Language } from "../datamodels/interfaces";
// *---- Style ----
import styles from "./LanguageToggle.module.css";
// *---- Components ----

export interface ComponentProps {}
export default function LanguageToggle<Props extends ComponentProps>(
  props: Props
) {
  const { t } = useTranslation();
  const currentLangugage = useSelector((state: any) => state.auth.language);
  const dispatch = useDispatch();
  const changeLanguage = (event: any) => {
    const lng: Language = event.target.value;
    i18n.changeLanguage(lng);
    dispatch({ type: types.AUTH_SET_LANGUAGE, payload: lng });
    dispatch({ type: FORM_CLEAR_ALL });
  };
  return (
    <span className={styles.wrapper}>
      {t("Language")}
      <select
        value={currentLangugage}
        onChange={changeLanguage}
        className={styles.select}
      >
        {availableLanguages.map((lang, index) => (
          <option key={index} value={lang}>
            {lang}
          </option>
        ))}
      </select>
    </span>
  );
}
