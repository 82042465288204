import React from "react";
import styles from "./Header.module.css";
import BackButton from "../BackButton";
interface FormHeaderProps extends React.PropsWithChildren<any> {
  title: string;
  shouldGoBack?: boolean;
}

export default function Header<Props extends FormHeaderProps>(props: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.goBack}>
        {props.shouldGoBack && <BackButton />}
      </div>
      <h1 className={styles.title}>{props.title}</h1>
      <div className={styles.children}>{props.children}</div>
    </div>
  );
}
