import React, { useState } from "react";
import CarcheckRequestForm from "../../../requests/screens/FormScreen";
import { Button, Modal, ModalHeader, ModalFooter } from "reactstrap";
import { useTranslation } from "react-i18next";
import styles from "./RequestFormModal.module.css";
import { X } from "react-bootstrap-icons";

export interface RequestFormModalProps {
  vehicle: number;
}

export default function RequestFormModal(props: RequestFormModalProps) {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const { t } = useTranslation();
  const afterSubmit = async () => {
    toggle();
    return Promise.resolve();
  };

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered={true}
        size="lg"
        contentClassName={styles.body}
      >
        <ModalHeader>
          <X className={styles.close} onClick={toggle}>
            {"X"}
          </X>
        </ModalHeader>
        <CarcheckRequestForm
          vehicleId={props.vehicle}
          afterSubmit={afterSubmit}
        />
        <div className={styles.spacer}></div>
        <ModalFooter />
      </Modal>
      <Button color="primary" onClick={toggle}>
        <b>+</b>
        {t("Request a carcheck")}
      </Button>
    </>
  );
}
