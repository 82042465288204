import React from "react";
import { Path } from "../../../Config/Router/utils";
import { ResetPassword } from "../../../Config/datamodels/interfaces";
import { useTranslation } from "react-i18next";
import validation from "./validation";

// *---- Imported Components ----
import DoubleInputForm, { FormField } from "../../components/DoubleInputForm";
import { Button } from "reactstrap";

import ErrorHandler from "../../components/ErrorHandler";

// *---- Styles ----
import styles from "./ResetPasswordScreen.module.css";
import useAuthViewModel from "../../viewModel/authViewModel";

// ---- Constant ----

const FIELDS: FormField[] = [
  { name: "email", type: undefined, placeholder: "email" },
];

export default function ResetPasswordScreen() {
  // *---- Setup ----
  const { error, resetPassword } = useAuthViewModel();
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {t("Enter your email to reset your password")}
      </div>
      <div className={styles.formWrapper}>
        <ErrorHandler>{error}</ErrorHandler>
        <DoubleInputForm
          validate={(values: any) => validation.validateForm(values)}
          onsubmit={async (inputValues: ResetPassword) => {
            await resetPassword(inputValues.email.toLowerCase());
          }}
          fields={FIELDS}
        >
          <div className={styles.buttonsContainer}>
            <Button type="submit" className={styles.button} color="primary">
              {t("Reset Password")}
            </Button>
            <a className={styles.link} href={Path.LOGIN}>
              {t("return to login")}
            </a>
          </div>
        </DoubleInputForm>
      </div>
    </div>
  );
}
