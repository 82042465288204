import React from "react";
import {
  Group,
  Image,
  CarcheckListEntry,
  TyreType,
} from "../../../Config/datamodels/interfaces";
import IfGroup from "../../../auth/components/IfGroup";
import Thumbnail, { ThumbnailSize } from "../../../Common/Components/Thumbnail";
import DocumentLink from "../../../Common/Components/DocumentLink";
import Highlight from "../../../Common/Components/Highlight";
import Emphasize from "../../../Common/Components/Emphasize";
import { useTranslation } from "react-i18next";
import { getFuelLevel, getTyreTypes } from "../../../Utils/utils";

export interface CleanRequestProps {
  picture?: Image | null;
}

export function CleanRequest(props: CleanRequestProps) {
  const { t } = useTranslation();
  if (!props.picture) {
    return <p>{t("Not requested")}</p>;
  }
  return (
    <div className="d-flex align-items-center">
      <Thumbnail
        src={props.picture?.xsmall}
        size={ThumbnailSize.xsmall}
      ></Thumbnail>
    </div>
  );
}

export interface TyreProps {
  back: TyreType;
}

export function Tyres(props: TyreProps) {
  const { t } = useTranslation();
  return (
    <span className="d-flex">
      <Emphasize>{t("Tyres type")}</Emphasize>
      <span className="ml-3"></span>
      <Highlight>{getTyreTypes(t, props?.back)}</Highlight>
    </span>
  );
}

export default function (carcheck: CarcheckListEntry) {
  const { t } = useTranslation();

  return (
    <tr key={carcheck.id}>
      <IfGroup groups={[Group.BACKOFFICE_USERS]}>
        <td>{carcheck.id}</td>
      </IfGroup>
      <td>
        <DocumentLink resource="carchecks" id={carcheck.id}>
          <Highlight>{carcheck?.carcheck_type}</Highlight>
        </DocumentLink>
      </td>
      <td>{new Date(carcheck.done_datetime).toLocaleDateString()}</td>
      <td>
        <DocumentLink resource="vehicles" id={carcheck.vehicle.id}>
          <Highlight>{carcheck.vehicle.plate}</Highlight>
        </DocumentLink>
      </td>
      <td>
        <DocumentLink resource="vehicles" id={carcheck.vehicle.id}>
          <Highlight>{carcheck.vehicle.vin}</Highlight>
        </DocumentLink>
      </td>
      <td>
        <DocumentLink resource="carchecks" id={carcheck.id}>
          {"" + carcheck.mileage}
        </DocumentLink>
      </td>
      <td>
        <DocumentLink resource="carchecks" id={carcheck.id}>
          {"" + getFuelLevel(t, carcheck.fuel_level)}
        </DocumentLink>
      </td>
      <td>
        <DocumentLink resource="carchecks" id={carcheck.id}>
          {"" + carcheck.damage_count}
        </DocumentLink>
      </td>
      <td>
        <DocumentLink resource="carchecks" id={carcheck.id}>
          {"" + carcheck.budget + "€"}
        </DocumentLink>
      </td>
      <td>
        <Tyres back={carcheck.tyres} />
      </td>
    </tr>
  );
}
