// *---- Imports ----
import React from "react";
import { ButtonGroup, Button } from "reactstrap";

export interface SwitchButton {
  label: string;
}

export interface ToggleButtonProps {
  onChange: (index: number) => void;
  switchButtons: SwitchButton[];
  activeButton: number;
}
export default function ToggleButton<Props extends ToggleButtonProps>({
  onChange,
  switchButtons,
  activeButton,
}: Props) {
  return (
    <ButtonGroup style={{ width: "100%" }} vertical>
      {switchButtons.map((button, index) => (
        <Button
          style={{ fontSize: 12 }}
          className="text-left"
          color="primary"
          outline={index !== activeButton}
          key={index}
          onClick={() => onChange(index)}
        >
          {button.label}
        </Button>
      ))}
    </ButtonGroup>
  );
}
