import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { types } from "./reducer";
import { UseSelector, UseDispatch } from "../../Common/Store/types";
import { Group, User } from "../../Config/datamodels/interfaces";

export interface UseAuthReducerOutput {
  user: User;
  id: number | string;
  loading: boolean;
  error: any;
  token: string;
  redirectUrl: string;
  groups: Group[];
  language: string;
  company: string;
  setLoading: () => void;
  setToken: (token: string) => void;
  setError: (err: any) => void;
  setUser: (user: User) => void;
  setLanguage: (language: string) => void;
  logout: (redirectUrl?: string) => void;
  setActivate: () => void;
  setActivationToken: (payload: string) => void;
}
export type UseAuthReducer = () => UseAuthReducerOutput;

const EMPTY_GROUPS: any[] = [];

const makeUseAuthReducer = (
  selectorHook: UseSelector<any>,
  dispatchHook: UseDispatch
) => () => {
  const dispatch = dispatchHook();

  const user = selectorHook((state) => _.get(state, "auth.user", null));
  const id = selectorHook((state) => _.get(state, "auth.user.id", null));
  const loading = selectorHook((state) => _.get(state, "auth.loading", false));
  const error = selectorHook((state) => _.get(state, "auth.error.data", false));
  const token = selectorHook((state) => _.get(state, "auth.jwtToken"));
  const redirectUrl = selectorHook((state) => _.get(state, "auth.redirectUrl"));
  const groups = selectorHook((state) =>
    _.get(state, "auth.user.groups", EMPTY_GROUPS)
  );
  const language = selectorHook((state) => _.get(state, "auth.language"));
  const company = selectorHook((state) =>
    _.get(state, "auth.user.profile.company")
  );

  const setLoading = () => dispatch({ type: types.AUTH_LOADING });
  const setToken = (payload: string) =>
    dispatch({ type: types.AUTH_LOGIN, payload });
  const setError = (payload: Error) =>
    dispatch({ type: types.AUTH_ERROR, payload });
  const setUser = (payload: User) =>
    dispatch({ type: types.AUTH_GET_USER, payload });
  const setLanguage = (payload: string) =>
    dispatch({ type: types.AUTH_SET_LANGUAGE, payload });
  const logout = (redirectUrl?: string) =>
    dispatch({ type: types.AUTH_LOGOUT, redirectUrl });
  const setActivate = () => dispatch({ type: types.AUTH_SUCCESS });
  const setActivationToken = (payload: string) =>
    dispatch({ type: types.AUTH_SET_ACTIVATION_TOKEN, payload });

  return {
    user,
    id,
    loading,
    error,
    token,
    redirectUrl,
    groups,
    language,
    company,
    setLoading,
    setToken,
    setError,
    setUser,
    setLanguage,
    logout,
    setActivate,
    setActivationToken,
  };
};

export { makeUseAuthReducer };
export default makeUseAuthReducer(useSelector, useDispatch);
