// *---- Imports ----
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Carcheck } from "../../../Config/datamodels/interfaces";
// *---- Style ----
import styles from "./DetailScreen.module.css";

// *---- Components ----
import Range from "./Components/Range";
import { Button } from "reactstrap";
import Header from "../../../Common/Components/Header";
import SelectUser from "./Components/SelectUser";
import SwitchScreen from "./Components/SwitchScreen";
import DeltaScreen from "./Components/DeltaScreen";
import GraphicScreen from "./Components/GraphicScreen";
import useSelectorViewModel from "../../viewModel/selectorViewModel";
import { Path } from "../../../Config/Router/utils";

export interface ComponentProps {}
export default function DocumentScreen<Props extends ComponentProps>(
  props: Props
) {
  const { t } = useTranslation();
  const { vehicleId } = useParams<any>();
  const [participant, setParticipant] = useState({ label: "", value: null });
  const { load, data, isLoading } = useSelectorViewModel(
    vehicleId,
    participant.label
  );
  const [selectedRange, setSelectedRange] = useState([0, -1]);
  const [defaultRange, setDefaultRange] = useState<number[] | undefined>();

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleId, participant.label]);

  useEffect(() => {
    // initialize the range and fetch in synch, otherwise the range component is not defaulting correctly
    if (data && data.length > 0) {
      const defaultRange = getSafeSelectRange([
        data.length - 2,
        data.length - 1,
      ]);
      setDefaultRange(defaultRange);
      setSelectedRange(defaultRange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const carcheckIds: number[] = useMemo(() => {
    //need to make sure carcheck are sorted correctly
    // seems weird but required as some time data doesn't come sorted from backend
    const sorted_data = _.sortBy(data, [(carcheck) => carcheck.id]);
    return sorted_data.map((carcheck) => carcheck.id);
  }, [data]);

  const sortedData: Carcheck[] = useMemo(() => {
    //need to make sure carcheck are sorted correctly
    // seems weird but required as some time data doesn't come sorted from backend
    return _.sortBy(data, [(carcheck) => carcheck.id]);
  }, [data]);
  // use this range to ignore the initial situation where the range is 0, 0
  const getSafeSelectRange = (range: number[]) => [
    range[0] < 0 ? Math.max(data.length - 1, 0) : range[0],
    range[1] < 0 ? Math.max(data.length - 1, 0) : range[1],
  ];

  function selectRangeMarks() {
    if (!!sortedData && sortedData.length) {
      // rc-slider requirements to have a key value map with int as key
      let result: any = {};
      for (let i = 0; i < sortedData.length; i++) {
        const carcheck: Carcheck = sortedData[i];
        result[i] = {
          style: {},
          label: new Date(carcheck.done_datetime).toLocaleDateString(),
        };
      }
      return result;
    } else {
      return {};
    }
  }

  return (
    <div className={styles.container}>
      <Header shouldGoBack title={t("Reporting")}>
        <Button
          className={styles.reportingButton}
          onClick={() => {
            const win = window.open(
              `${Path.REPORTING}/${
                carcheckIds[getSafeSelectRange(selectedRange)[0]]
              }/${carcheckIds[getSafeSelectRange(selectedRange)[1]]}/print`,
              "_blank"
            );
            if (win) {
              win.focus();
            }
          }}
          color="secondary"
        >
          {t("Print Report")}
        </Button>
      </Header>
      {!isLoading && (
        <div className={styles.innerWrapper}>
          <div className={styles.left}>
            <SelectUser value={participant} onChange={setParticipant} />
            {defaultRange && (
              <Range
                defaultRangePositions={defaultRange}
                onChange={(newRange: number[]) => {
                  setSelectedRange(newRange);
                }}
                max={data.length - 1}
                marks={selectRangeMarks()}
              />
            )}
          </div>
          <div className={styles.right}>
            <SwitchScreen
              titles={[{ label: t("Delta") }, { label: t("Graphic") }]}
            >
              {data && data.length ? (
                <DeltaScreen
                  leftCarcheckId={
                    carcheckIds[getSafeSelectRange(selectedRange)[0]]
                  }
                  rightCarcheckId={
                    carcheckIds[getSafeSelectRange(selectedRange)[1]]
                  }
                  buttonTitle={t("Delta")}
                />
              ) : (
                <></>
              )}
              {data && data.length ? (
                <GraphicScreen
                  carcheckIds={carcheckIds}
                  range={[
                    getSafeSelectRange(selectedRange)[0],
                    getSafeSelectRange(selectedRange)[1],
                  ]}
                  buttonTitle={t("Graphic")}
                />
              ) : (
                <></>
              )}
            </SwitchScreen>
          </div>
        </div>
      )}
    </div>
  );
}
