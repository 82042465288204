import Axios from "axios";
import buildRESTUrl, {
  RESTUriBuilderParams,
} from "../../Common/ApiGateway/buildRESTUrl";
import makeRESTListService from "../../Common/Services/ListService/RESTListService";
import { ListServiceArguments } from "../../Common/Services/types";
import { Carcheck } from "../../Config/datamodels/interfaces";

const reportingSelectorUrlBuilder = (args: RESTUriBuilderParams) =>
  buildRESTUrl({ ...args, action: "timeline" });

const selectorListService = makeRESTListService<Carcheck>(
  "carchecks",
  reportingSelectorUrlBuilder,
  Axios
);

export default (args: ListServiceArguments) => {
  return selectorListService({ ...args, limit: 1000 });
};
