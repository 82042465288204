import { createFinalFormValidation } from "@lemoncode/fonk-final-form";

const useValidation = () => {
  const fields = {
    logo: [],
    notification_email: [],
  };

  return createFinalFormValidation({ field: fields });
};

export default useValidation;
