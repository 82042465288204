import _ from "lodash";
import { FormGroup, Input, Label } from "reactstrap";
import React from "react";
import styles from "./Selector.module.css";
import { ChoiceField } from "../../Store/FormReducer/Fields";
import { InputType } from "../../../Config/datamodels/types";

export interface SelectorValues {
  label: string;
  options?: ChoiceField[];
  value: any;
  inputType?: InputType;
  name?: string;
  style?: any;
  onClick?: () => void;
  hideOnPrint?: boolean;
}

export interface SelectorProps {
  selector: SelectorValues;
  shouldWrap?: boolean;
  disabled?: boolean;
  onChange: (event: any) => void;
  hideLabel?: boolean;
}

export function Selector({
  selector,
  onChange,
  shouldWrap,
  disabled,
  hideLabel,
}: SelectorProps) {
  const { name, label, options, value, inputType } = selector;
  const style = _.get(selector, "style", {});
  const onClick = _.get(selector, "onClick", () => {});
  const FormGroupClass = shouldWrap
    ? `${styles.selectorContainer} ${styles.selectorWrapping}`
    : styles.selectorContainer;

  return (
    <FormGroup className={FormGroupClass}>
      {!hideLabel && <Label className={styles.selectorLabel}>{label}</Label>}
      <Input
        name={name}
        style={style}
        value={value}
        onClick={onClick}
        type={inputType || "select"}
        onChange={onChange}
        className={styles.selectorInput}
        disabled={disabled}
      >
        {options &&
          options.map((option, index) => {
            return (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            );
          })}
      </Input>
    </FormGroup>
  );
}
