import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";
import axios from "axios";

export const deleteService = async (
  id: number,
  notify?: boolean
): Promise<void> => {
  const querystring = new URLSearchParams({
    notify: notify ? "true" : "false",
  }).toString();
  const url = buildRESTUrl({ resource: "requests", id: id, querystring });
  await axios.delete(url);
};
