// *---- Imports ----
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import PrintScreen from "../../../carchecks/screens/PrintScreen/PrintScreen";
import usePrintViewModel from "../../viewModel/printViewmodel";

export default function ReportingPrintScreen() {
  const { leftCarcheckId, rightCarcheckId } = useParams<any>();
  const { t } = useTranslation();
  const { data, isLoading, totalPageCount } = usePrintViewModel(
    leftCarcheckId,
    rightCarcheckId
  );

  if (isLoading || !data) return <Spinner />;

  return (
    <div>
      <PrintScreen
        documentTitle={
          t("Carcheck Delta") + " " + leftCarcheckId + " - " + rightCarcheckId
        }
        data={data.carcheck}
        isLoading={false}
        totalPageCount={totalPageCount}
        perPageDamages={data.damagePerPage}
      />
    </div>
  );
}
