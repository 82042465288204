// *---- Imports ----
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CarcheckType, Group } from "../../../Config/datamodels/interfaces";
// *---- Style ----
import styles from "./DocumentScreen.module.css";

// *---- Components ----
import { Button } from "reactstrap";
import VehiclePictureInfoCard from "../../../Common/Components/VehiclePictureInfoCard";
import ElementWrapper from "../../../Common/Components/ElementWrapper";
import Participants from "./Components/Participants";
import Courtoisie from "./Components/Courtoisie";
import Damages from "./Components/Damages";
import InventoryPresenceComponent from "./Components/InventoryPresence";
import DocumentScreenBody from "../../../Common/Components/DocumentScreenBody";
import Header from "../../../Common/Components/Header";
import IfGroup from "../../../auth/components/IfGroup";
import HideOnPrint from "../../../Common/Components/HideOnPrint";
import { Path } from "../../../Config/Router/utils";
import useDetailViewModel from "../../viewModel/useDetailViewModel";
import useVehicleDetailViewModel from "../../../vehicles/viewModel/detailViewModel";
import getTotalDamageCost from "../../converters/getTotalDamageCost";
import TotalCosts from "./Components/TotalCosts";
import ImageDownloadProgress from "./Components/ImageDownloadProgess";
import { clusterPartsByPositionAndCategory } from "../../converters/clusterPartsPerPositionAndCategory";
import { clusterInventoryPresenceByPositionAndCategory } from "../../converters/clusterInventoryPerPositionAndCategory";
import { IfCarcheck } from "../../../Common/Components/IfCarcheck";

export default function DocumentScreen() {
  // * ---- setup ----
  const { t } = useTranslation();
  const { id } = useParams<any>();
  const {
    get,
    data,
    isLoading,
    isImageDownloading,
    imageDownloadPercentage,
    updateCarcheck,
    updateDamage,
    updateDamageLocally,
    freezeCarcheck,
    transferCarcheck,
    downloadImageZip,
    updateInventoryPresence,
    deleteDamage,
    transferDamage,
  } = useDetailViewModel(id);

  const { get: getVehicle, data: vehicle } = useVehicleDetailViewModel(
    data?.vehicle.id
  );

  const {
    getPartMaxAcceptableDamageTotal,
    getPartMaxNonAcceptableDamageCost,
    getTotalAcceptableDamageCost,
    getTotalNonAcceptableDamageCost,
  } = getTotalDamageCost(data, parseInt(id));

  useEffect(() => {
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (data) {
      getVehicle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function updateDamages(key: string, value: any, id: number, partId: number) {
    updateDamageLocally(id, { [key]: value }, partId);
    updateDamage(id, { [key]: value }, partId);
  }

  return (
    <>
      <HideOnPrint>
        <Header title={t("Carcheck")}>
          <IfGroup
            groups={[Group.FLEET_MANAGERS, Group.HR_OFFICERS, Group.QUOTERS]}
          >
            <Button
              className={styles.reportingButton}
              onClick={() => {
                const win = window.open(
                  `${Path.CARCHECKS}/${id}/print`,
                  "_blank"
                );
                if (win) {
                  win.focus();
                }
              }}
              color="secondary"
            >
              {t("Print Report")}
            </Button>
            <IfGroup groups={[Group.FLEET_MANAGERS]}>
              <>
                {!data?.freeze_update && (
                  <Button
                    onClick={() => freezeCarcheck(id)}
                    className={styles.reportingButton}
                    color="primary"
                  >
                    {t("Freeze Carcheck Report")}
                  </Button>
                )}
                {data?.freeze_update && (
                  <Button
                    onClick={() => transferCarcheck(id)}
                    className={styles.reportingButton}
                    color="danger"
                  >
                    {t("Transfer to Repairnet")}
                  </Button>
                )}
              </>
            </IfGroup>
            <Button
              onClick={() => downloadImageZip()}
              className={styles.reportingButton}
              color="primary"
            >
              {t("Download Images")}
            </Button>
          </IfGroup>
        </Header>
      </HideOnPrint>
      <DocumentScreenBody>
        <div className={styles.container}>
          {data && (
            <>
              <ElementWrapper
                title={`${t("Participants")} (${
                  data.participation_set.length
                })`}
              >
                <Participants participants={data.participation_set} />
              </ElementWrapper>

              <ElementWrapper title={t("Vehicle Information")}>
                <VehiclePictureInfoCard
                  classname={styles.spacer}
                  vehicle={data.vehicle}
                />
              </ElementWrapper>

              <ElementWrapper title={t("General Carcheck Information")}>
                <Courtoisie
                  updateCarcheck={updateCarcheck}
                  carcheck={data}
                  disable={isLoading}
                />
              </ElementWrapper>

              <IfCarcheck
                types={[
                  CarcheckType.EXHAUSTIVE,
                  CarcheckType.PERIODIC,
                  CarcheckType.DAMAGE_CHECK,
                  CarcheckType.RENTA,
                  CarcheckType.POOL_CHECK,
                  CarcheckType.RECEPTION,
                ]}
                currentType={data.carcheck_type}
              >
                <TotalCosts
                  acceptableCost={getTotalAcceptableDamageCost()}
                  nonAcceptable={getTotalNonAcceptableDamageCost()}
                />

                <ElementWrapper
                  title={`${t("Damages")} (${data.damage_set?.length})`}
                >
                  {data.damage_per_part && data?.damage_per_part.length > 0 ? (
                    <Damages
                      disable={isLoading}
                      availableParts={vehicle?.carpart_set || []}
                      deleteDamage={deleteDamage}
                      getTotalAcceptableCost={getPartMaxAcceptableDamageTotal}
                      getTotalNonAcceptableCost={
                        getPartMaxNonAcceptableDamageCost
                      }
                      onChange={updateDamages}
                      parts={clusterPartsByPositionAndCategory(
                        data.damage_per_part
                      )}
                      carcheckId={data.id}
                      transferDamage={transferDamage}
                    />
                  ) : (
                    <></>
                  )}
                </ElementWrapper>
              </IfCarcheck>
              {data.inventorypresence_set &&
              data.inventorypresence_set.length ? (
                <ElementWrapper title={t("Vehicle inventory")}>
                  <InventoryPresenceComponent
                    inventoryPresence={clusterInventoryPresenceByPositionAndCategory(
                      data.inventorypresence_set
                    )}
                    updateIsDisabled={data.freeze_update}
                    update={updateInventoryPresence}
                  />
                </ElementWrapper>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </DocumentScreenBody>
      <ImageDownloadProgress
        isOpen={isImageDownloading}
        percentage={imageDownloadPercentage}
        headerTitle={t("Downloading images progress")}
      />
    </>
  );
}
