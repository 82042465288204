import Axios from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";
import defaultToastService from "../../Common/Services/ToastService";
import { ToastService } from "../../Common/Services/types";
import {
  ConfirmPassword,
  Credentials,
} from "../../Config/datamodels/interfaces";
import { validRedirection } from "../../Config/Router/utils";
import makeActivateService, {
  ActivateService,
} from "../services/activateService";
import makeGetMeService, { GetMeService } from "../services/getMeService";
import makeLoginService, { LoginService } from "../services/loginService";
import makeResetPasswordService, {
  ResetPasswordService,
} from "../services/resetPasswordService";
import { makeUseAuthReducer, UseAuthReducer } from "../store/useAuthReducer";
import { useCallback, useMemo } from "react";

const makeAuthViewModel = (
  useAuthReducer: UseAuthReducer,
  activateService: ActivateService,
  getMeService: GetMeService,
  loginService: LoginService,
  resetPasswordService: ResetPasswordService,
  toastService: ToastService
) => () => {
  const {
    groups,
    user,
    loading,
    error,
    language,
    redirectUrl,
    setActivationToken,
    setUser,
    setError,
    setLoading,
    setActivate,
    setToken,
    logout,
    setLanguage,
  } = useAuthReducer();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const getMeForActivation = useCallback(
    async (token: string) => {
      setLoading();
      setActivationToken(token);
      try {
        const me = await getMeService();
        setUser(me);
        return me;
      } catch (err) {
        setError({ data: "Invalid Credentials" });
      }
    },
    [setActivationToken, setError, setLoading, setUser]
  );

  const refreshUser = useCallback(async () => {
    try {
      const me = await getMeService();
      setUser(me);
    } catch (err) {
      setError({ data: "Invalid Credentials" });
    }
  }, [setError, setUser]);

  const activate = useCallback(
    async (passwords: ConfirmPassword) => {
      setLoading();
      try {
        await activateService(passwords);
        setActivate();
      } catch (err) {
        setError({ data: "Invalid Credentials" });
      }
    },
    [setActivate, setError, setLoading]
  );

  const login = useCallback(
    async (credentials: Credentials) => {
      setLoading();
      try {
        const access = await loginService(credentials);
        setToken(access);
        const me = await getMeService();
        setUser(me);
        history.push(validRedirection(redirectUrl));
      } catch (err) {
        setError({ data: "Invalid Credentials" });
      }
    },
    [history, redirectUrl, setError, setLoading, setToken, setUser]
  );

  const resetPassword = useCallback(
    async (email: string) => {
      setLoading();
      try {
        await resetPasswordService(email);
        toastService.success(
          t("We have sent you an email to reset your password")
        );
      } catch (err) {
        console.log(err);
        setError({ data: t("Invalid email") });
      }
    },
    [setError, setLoading, t]
  );

  const setLang = useCallback(
    (language?: string) => {
      i18n.changeLanguage(language || "en");
      setLanguage(language || "en");
    },
    [i18n, setLanguage]
  );

  return useMemo(
    () => ({
      groups,
      user,
      loading,
      error,
      language,
      setActivate,
      getMeForActivation,
      getMeService,
      refreshUser,
      activate,
      login,
      logout,
      resetPassword,
      setLang,
    }),
    [
      activate,
      error,
      getMeForActivation,
      groups,
      language,
      loading,
      login,
      logout,
      refreshUser,
      resetPassword,
      setActivate,
      setLang,
      user,
    ]
  );
};

export { makeAuthViewModel };
const useAuthViewModel = makeAuthViewModel(
  makeUseAuthReducer(useSelector, useDispatch),
  makeActivateService(buildRESTUrl, Axios),
  makeGetMeService(buildRESTUrl, Axios),
  makeLoginService(buildRESTUrl, Axios),
  makeResetPasswordService(buildRESTUrl, Axios),
  defaultToastService
);
export default useAuthViewModel;
