import Axios from "axios";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";
import makeRESTListService from "../../Common/Services/ListService/RESTListService";
import { Department } from "../../Config/datamodels/interfaces";

const listService = makeRESTListService<Department>(
  "departments",
  buildRESTUrl,
  Axios
);
export default listService;
