import { InventoryPresence } from "../../../../Config/datamodels/interfaces";

export type ClusteredInventoryPresence = Record<string, InventoryPresence[]>;

export default function clusterify(
  items: InventoryPresence[]
): ClusteredInventoryPresence {
  const clustered: ClusteredInventoryPresence = {};
  for (const item of items) {
    const category = item.inventory_item.category_en;
    if (category in clustered) {
      clustered[category].push(item);
    } else {
      clustered[category] = [item];
    }
  }
  return clustered;
}
