import React from "react";
import useAuthViewModel from "../../viewModel/authViewModel";
import styles from "./CompanyLogo.module.css";
import checklyLogo from "./Checkly.png";

export interface IfGroupProps {
  groups: string[];
  children: JSX.Element[] | JSX.Element | string;
}

export default function CompanyLogo() {
  const { user } = useAuthViewModel();
  let logo;
  if (user.profile.company_logo) {
    logo = user.profile.company_logo.medium;
  } else {
    logo = checklyLogo;
  }
  return <img src={logo} className={styles.logo} alt="Company Logo" />;
}
