import _ from "lodash";
import { InventoryPresence } from "../../Config/datamodels/interfaces";

export function clusterInventoryPresenceByPositionAndCategory(
  inventories: InventoryPresence[] | undefined
) {
  if (!inventories || inventories.length === 0) return [];
  const defaultLang = "en";
  if (areInventoriesWithPosition(inventories)) {
    return _.sortBy(inventories, [
      "inventory_item.position",
      `inventory_item.category_${defaultLang}`,
    ]);
  }
  return _.sortBy(inventories, [`inventory_item.category_${defaultLang}`]);
}

export function areInventoriesWithPosition(inventories: InventoryPresence[]) {
  return (
    _.reduce(
      inventories,
      (sum, inventory) => sum + inventory.inventory_item?.position,
      inventories[0].inventory_item?.position
    ) > inventories[0].inventory_item?.position
  );
}
