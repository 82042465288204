// *---- Imports ----
import React from "react";

// *---- Style ----
import styles from "./CardSeparator.module.css";
// *---- Components ----

export default function CardSeparator() {
  return <div className={styles.separator} />;
}
