import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Path } from "../../../Config/Router/utils";
import useAuthViewModel from "../../viewModel/authViewModel";

export default function Logout() {
  const { logout } = useAuthViewModel();
  const history = useHistory();
  useEffect(() => {
    logout(window.location.href);
    history.push(Path.LOGIN);
  });
  return <></>;
}
