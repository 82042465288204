import React from "react";
import { Department, Group } from "../../../Config/datamodels/interfaces";
import ListView from "../../../Common/Components/ListView";
import { Path } from "../../../Config/Router/utils";
import ListItem from "./ListItem";
import { useTranslation } from "react-i18next";
import useAuthViewModel from "../../../auth/viewModel/authViewModel";
import listViewModel from "../../viewModel/listViewModel";
import useFields from "./useFields";

export default function ListScreen() {
  const listData = listViewModel();
  const { t } = useTranslation();
  const { filterableFields, orderableFields } = useFields();
  const { groups } = useAuthViewModel();
  const headers = [t("Name"), ""];
  if (groups.includes(Group.BACKOFFICE_USERS)) {
    headers.splice(0, 0, "id");
  }
  return (
    <ListView<Department>
      title={t("Departments")}
      basePath={Path.DEPARTMENTS}
      emptyListTitle={t("Add you first department")}
      emptyListDescription={t("Your company departments will be shown here")}
      navigationFormButtonLabel={t("Create New Department")}
      listData={listData}
      headers={headers}
      renderLine={(department: Department) => (
        <ListItem
          department={department}
          onDelete={(id: number | string) => listData.deleteById(id)}
        />
      )}
      availableFilters={filterableFields}
      orderableFields={orderableFields}
    />
  );
}
