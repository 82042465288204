import React from "react";
import { PrinterFill } from "react-bootstrap-icons";
import styles from "./PrintButton.module.css";

export default function PrintButton() {
  return (
    <div className={styles.printer} onClick={() => window.print()}>
      <PrinterFill />
    </div>
  );
}
