import React from "react";
import FormView from "../../../Common/Components/FormView";
import { Vehicle } from "../../../Config/datamodels/interfaces";
import useFormViewModel from "../../viewModel/formViewModel";
import { VehicleFormFieldsName } from "../../viewModel/types";
import useValidation from "./useValidation";
import {
  requestInterceptor,
  responseInterceptor,
} from "../../viewModel/formInterceptors";
import { useTranslation } from "react-i18next";

export default function () {
  const { t } = useTranslation();
  const formData = useFormViewModel(
    undefined,
    requestInterceptor,
    responseInterceptor
  );
  const validation = useValidation();
  return (
    <FormView<Vehicle, VehicleFormFieldsName>
      shouldGoBack
      formData={formData}
      validateButtonText={t("Register Vehicle")}
      title={t("New Vehicle")}
      description={t(
        "To create a new Vehicle, be sure to provide the correct vin number, model and brand of the vehicle. All the information entered can be updated later on, except from the vin number"
      )}
      validation={validation}
      modelName={t("vehicles")}
    />
  );
}
