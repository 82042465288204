import Axios from "axios";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";
import makeRESTUpdateService from "../../Common/Services/UpdateService";
import { Partner } from "../../Config/datamodels/interfaces";

const updateService = makeRESTUpdateService<Partner>(
  "partners",
  buildRESTUrl,
  Axios
);
export default updateService;
