import React from "react";
import styles from "./Emphasize.module.css";

export interface EmphasizeProps {
  children: JSX.Element[] | JSX.Element | string;
}

export default function Emphasize(props: EmphasizeProps) {
  return <span className={styles.emphasize}>{props.children}</span>;
}
