import { useTranslation } from "react-i18next";
import {
  DateField,
  NumberField,
  SearchSelectField,
  SelectField,
  TextField,
} from "../../Common/Store/FormReducer/Fields";

const getDecrementalYearsOption = (maxVehicleAge = 10) => {
  const currentYear = new Date().getFullYear();
  const choices = [];
  for (let i = currentYear; i > currentYear - maxVehicleAge; i--) {
    choices.push({ label: i.toString(), value: i });
  }
  return choices;
};

const useFormFields = (id?: number | string) => {
  const { t } = useTranslation();

  return {
    vin: new TextField("vin", t("Vehicle vin number"), t("Vehicle Vin"), {
      required: !id,
      disable: !!id,
    }),

    plate: new TextField(
      "plate",
      t("Vehicle plate number"),
      t("Vehicle plate"),
      { required: false }
    ),

    brand: new TextField("brand", t("Vehicle brand"), t("Vehicle Brand"), {
      required: !id,
    }),
    model: new TextField("model", t("Vehicle model"), t("Vehicle Model"), {
      required: !id,
    }),

    year: new SelectField(
      "year",
      t("Vehicle year"),
      t("Vehicle Year"),
      getDecrementalYearsOption(15),
      { required: !id }
    ),

    leasing_start_date: new DateField(
      "leasing_start_date",
      t("Start date"),
      t("Vehicle Leasing Start Date"),
      { required: !id }
    ),
    leasing_end_date: new DateField(
      "leasing_end_date",
      t("End date"),
      t("Vehicle Leasing End Date"),
      { required: !id }
    ),

    km_contract: new NumberField(
      "km_contract",
      t("In Km"),
      t("Km limit in the contract"),
      0,
      Infinity,
      { required: !id }
    ),
    owning_partner: new SearchSelectField(
      "owning_partner",
      t("Name of the Vehicle owner company"),
      t("Vehicle Owner"),
      "partners",
      "name",
      undefined,
      { required: !id }
    ),

    type: new SearchSelectField(
      "type",
      t("Vehicle category"),
      t("Vehicle Category"),
      "vehicle_types",
      "name",
      undefined,
      { required: !id }
    ),

    department: new SearchSelectField(
      "department",
      t("Department"),
      t("Department"),
      "departments",
      "name",
      undefined,
      { required: false }
    ),

    driver: new SearchSelectField(
      "driver",
      t("Email of the vehicle driver"),
      t("Vehicle Driver Email"),
      "users",
      "email",
      undefined
    ),
  };
};

export default useFormFields;
