import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import enTranscription from "./languages/en.json";
import frTranscription from "./languages/fr.json";
import nlTranscription from "./languages/nl.json";

const resources = {
  en: {
    translation: enTranscription,
  },
  fr: {
    translation: frTranscription,
  },
  nl: {
    translation: nlTranscription,
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
    saveMissing: true,
    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
