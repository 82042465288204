import { useTranslation } from "react-i18next";
import {
  DateField,
  EmailField,
  PhoneField,
  SelectField,
  SearchSelectField,
  SelectMulti,
  TextField,
} from "../../Common/Store/FormReducer/Fields";
import { Languages } from "../../Config/datamodels/interfaces";
import { groupChoices } from "../../Utils/utils";

const useFormField = (id?: string | number) => {
  const { t } = useTranslation();
  return {
    first_name: new TextField(
      "first_name",
      t("User first name"),
      t("First Name"),
      { required: id ? false : true }
    ),
    last_name: new TextField("last_name", t("User last name"), t("Last Name"), {
      required: id ? false : true,
    }),
    email: new EmailField("email", t("User company email"), t("Email"), {
      required: id ? false : true,
      errorPath: "username",
      disable: id ? true : false,
    }),
    groups: new SelectMulti(
      "groups",
      t("User category"),
      t("Groups"),
      groupChoices(),
      { required: id ? false : true }
    ),

    department: new SearchSelectField(
      "department",
      t("Department"),
      t("Department"),
      "departments",
      "name",
      undefined,
      { required: !id }
    ),

    phone: new PhoneField(
      "phone",
      t("+32478000000"),
      t("Phone Number (with prefix)"),
      {
        errorPath: "profile.phone",
      }
    ),

    contract_end_date: new DateField(
      "contract_end_date",
      t("User contract end date"),
      t("Contract End Date"),
      { errorPath: "profile.contract_end_date" }
    ),

    language: new SelectField(
      "language",
      t("Language"),
      t("Language"),
      [
        { label: t("french"), value: Languages.FR },
        { label: t("dutch"), value: Languages.NL },
        { label: t("english"), value: Languages.EN },
      ],
      { required: false }
    ),
  };
};

export default useFormField;
