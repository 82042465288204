import { RESTQueryBuilder } from "../../ApiGateway/buildRESTUrl";
import { HTTPPostService } from "../types";

export class InvalidImportFileType extends Error {}

const makeRESTImportService = (
  urlBuilder: RESTQueryBuilder,
  postService: HTTPPostService<void>
) => async (file: any, modelName: string) => {
  if (!file.name.endsWith(".xlsx")) throw new InvalidImportFileType(file.name);
  const url = urlBuilder({ resource: "imports", action: "xls" });
  const data = new FormData();
  data.set("file", file);
  data.set("model", modelName);
  await postService.post(url, data);
};

export default makeRESTImportService;
