import React from "react";
import { Department, Group } from "../../../Config/datamodels/interfaces";
import IfGroup from "../../../auth/components/IfGroup";
import _ from "lodash";
import RowOptionButton from "../../../Common/Components/RowOptionButton";
import { useTranslation } from "react-i18next";

export default function (props: {
  department: Department;
  onDelete: (id: number | string) => void;
}) {
  const { department } = props;
  const { t } = useTranslation();

  return (
    <tr key={"" + department.id}>
      <IfGroup groups={[Group.BACKOFFICE_USERS]}>
        <td>{department.id}</td>
      </IfGroup>
      <td>{_.capitalize(department.name)}</td>
      <td className="align-middle">
        <RowOptionButton
          id={department.id}
          modelName={"departments"}
          extraOptions={[
            {
              label: t("delete"),
              onClick: () => {
                props.onDelete(props.department.id);
              },
            },
          ]}
        />
      </td>
    </tr>
  );
}
