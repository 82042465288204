import React from "react";
import { useTranslation } from "react-i18next";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

export interface ConfirmationModalProps {
  message: string;
  isOpen: boolean;
  onConfirm: () => void;
  close: () => void;
}

export default function ConfirmationModal(props: ConfirmationModalProps) {
  const { message, isOpen, onConfirm, close } = props;
  const { t } = useTranslation();

  return (
    <div>
      <Modal isOpen={isOpen}>
        <ModalHeader toggle={close}>{t("Are you sure ?")}</ModalHeader>
        <ModalBody>{message}</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={onConfirm}>
            {t("Continue")}
          </Button>{" "}
          <Button color="secondary" onClick={close}>
            {t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
