import { useEffect, useRef } from "react";

function usePrevious(dependencies: any[]) {
  const value = useRef<any>(null);
  useEffect(() => {
    value.current = dependencies;
  });
  return value.current;
}

export const useDependenciesDebugger = (dependencies: any[]) => {
  const previousDeps = usePrevious(dependencies);

  const changedDeps = dependencies.reduce((accum, dependency, index) => {
    if (previousDeps && dependency !== previousDeps[index]) {
      return {
        ...accum,
        [index]: {
          before: previousDeps[index],
          after: dependency,
        },
      };
    }

    return accum;
  }, {});

  if (Object.keys(changedDeps).length) {
    console.log("[dependencies-debugger] ", changedDeps);
  }
};
