import { useDispatch, useSelector } from "react-redux";
import ListReducer from "../../Common/Store/ListReducer";
import makeUseListReducer from "../../Common/Store/ListReducer/useListReducer";

const deparmentListReducer = ListReducer("departments");

export default deparmentListReducer;
const useListReducer = makeUseListReducer(
  "departments",
  useSelector,
  useDispatch
);
export { useListReducer };
