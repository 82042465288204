export type ClassNameConditional = [boolean, string];
export type ClassNameTernary = [boolean, string, string];
export type ClassNameOrConditional =
  | string
  | ClassNameTernary
  | ClassNameConditional;

function isConditional(
  component: ClassNameOrConditional
): component is ClassNameConditional {
  return Array.isArray(component) && component.length === 2;
}

function isTernary(
  component: ClassNameOrConditional
): component is ClassNameTernary {
  return Array.isArray(component) && component.length === 3;
}

export function classes(...classComponent: ClassNameOrConditional[]) {
  const asStrings: string[] = classComponent.map((component) => {
    if (isConditional(component)) {
      return component[0] ? component[1] : "";
    }
    if (isTernary(component)) {
      return component[0] ? component[1] : component[2];
    }
    return component;
  });
  return asStrings.join(" ");
}
