import ToastService from "../../Common/Services/ToastService";
import makeUseFormViewModel from "../../Common/ViewModels/makeUseFormViewModel";
import { Partner } from "../../Config/datamodels/interfaces";
import createService from "../services/createService";
import detailService from "../services/detailService";
import updateService from "../services/updateService";
import { useFormReducer } from "../store/formReducer";
import { PartnerFieldName } from "./types";
import useFormFields from "./useFormFields";

const useFormViewModel = makeUseFormViewModel<Partner, PartnerFieldName>(
  createService,
  updateService,
  detailService,
  useFormReducer,
  useFormFields,
  ToastService
);
export default useFormViewModel;
