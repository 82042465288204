import Axios from "axios";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";
import makeRESTDetailService from "../../Common/Services/DetailService/RESTDetailService";
import { Carcheck } from "../../Config/datamodels/interfaces";

const deltaCarcheckService = makeRESTDetailService<Carcheck>(
  "carchecks",
  buildRESTUrl,
  Axios
);
export default deltaCarcheckService;
