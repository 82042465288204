import _ from "lodash";
import { selectGraphicDamagesPerParts } from "../../Common/Converters/isDamageRepaired";
import { Carcheck, Part } from "../../Config/datamodels/interfaces";

export function mapPartDamagesTo2DimensionArray(
  carchecks: Carcheck[],
  range: number[],
  lang: any
) {
  const selectedCarchecks = carchecks.slice(range[0], range[1] + 1);
  const damagedPartsPerCarcheck = selectedCarchecks.map((carcheck) =>
    selectGraphicDamagesPerParts(carcheck.damage_set, carcheck.id)
  );

  let newData = [];
  let partsMap = getPartsMap(damagedPartsPerCarcheck, lang);
  const partsName = _.values(partsMap);
  const newXlabels = selectedCarchecks.map((carcheck: Carcheck) =>
    new Date(carcheck.done_datetime).toLocaleDateString()
  );

  for (let id of _.keys(partsMap)) {
    let initialValues = new Array(damagedPartsPerCarcheck.length).fill(0);
    for (let i = 0; i < damagedPartsPerCarcheck.length; i++) {
      let parts = damagedPartsPerCarcheck[i];
      let ids = parts.map((part: any) => part.id);
      if (ids.indexOf(_.toInteger(id)) > -1) {
        initialValues[i] = parts[ids.indexOf(_.toInteger(id))].damages.length;
      } else {
        initialValues[i] = 0;
      }
    }
    newData.push(initialValues);
  }

  return {
    data: newData,
    xLabels: newXlabels,
    yLabels: partsName,
  };
}

export function getPartsMap(damagedPartsPerCarcheck: Part[][], lang: any) {
  const result: any = {};
  for (let parts of damagedPartsPerCarcheck) {
    for (let part of parts) {
      result[part.id] = lang.getSecureTranslation(part);
    }
  }
  return result;
}
