export const DETAIL_LOADING = "detail_loading";
export const DETAIL_SUCCESS = "detail_success";
export const DETAIL_ERROR = "detail_error";
export const DETAIL_CLEAR = "detail_clear";
export const DETAIL_STOP_LOADING_SUCCESS = "detail_stop_loading_success";

export interface detailActionBase {
  modelName: string;
}

export interface detailLoading extends detailActionBase {
  type: typeof DETAIL_LOADING;
}

export interface detailLoadingSuccess extends detailActionBase {
  type: typeof DETAIL_STOP_LOADING_SUCCESS;
}

export interface detailSuccess extends detailActionBase {
  type: typeof DETAIL_SUCCESS;
  payload: any;
}

export interface detailError extends detailActionBase {
  type: typeof DETAIL_ERROR;
  payload: Error;
}

export interface detailClear extends detailActionBase {
  type: typeof DETAIL_CLEAR;
}

export type DetailAction =
  | detailLoading
  | detailSuccess
  | detailSuccess
  | detailError
  | detailClear
  | detailLoadingSuccess;

export interface detailState {
  data: any;
  success: boolean;
  error: Error | null;
  loading: boolean;
}

export const initialState: detailState = {
  data: null,
  success: false,
  error: null,
  loading: false,
};

export default (modelName: string) => (
  state: detailState = initialState,
  action: DetailAction
) => {
  if (action.modelName !== modelName) {
    return state;
  }
  switch (action.type) {
    case DETAIL_LOADING:
      return { ...state, success: false, error: false, loading: true };
    case DETAIL_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        loading: false,
        data: action.payload,
      };
    case DETAIL_ERROR:
      return {
        ...state,
        success: false,
        error: action.payload,
        loading: false,
      };

    case DETAIL_CLEAR:
      return initialState;

    case DETAIL_STOP_LOADING_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
      };

    default:
      return state;
  }
};
