import React from "react";
import { User, Vehicle, Group } from "../../../Config/datamodels/interfaces";
import Thumbnail, { ThumbnailSize } from "../../../Common/Components/Thumbnail";
import Highlight from "../../../Common/Components/Highlight";
import DocumentLink from "../../../Common/Components/DocumentLink";
import IfGroup from "../../../auth/components/IfGroup";
import { Container, Row } from "reactstrap";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import RowOptionButton from "../../../Common/Components/RowOptionButton";
import { getOneGroupTranslation } from "../../../Utils/utils";
import { useHistory } from "react-router-dom";

export function Picture(props: { user: User }) {
  return props.user.profile.profile_picture ? (
    <DocumentLink resource="users" id={props.user.id}>
      <Thumbnail
        src={props.user.profile?.profile_picture?.xsmall}
        size={ThumbnailSize.xsmall}
      />
    </DocumentLink>
  ) : null;
}

export function Name(props: { user: User }) {
  return (
    <DocumentLink resource="users" id={props.user.id}>
      <Container>
        <Row>
          <Highlight>
            {_.upperFirst(props.user.first_name)}{" "}
            {_.upperFirst(props.user.last_name)}
          </Highlight>
        </Row>
        <Row>{props.user.email}</Row>
      </Container>
    </DocumentLink>
  );
}

export function LastCarcheck(props: { user: User }) {
  const { t } = useTranslation();
  if (!props.user.last_carcheck) {
    return null;
  }
  const last_carcheck_date = new Date(props.user.last_carcheck.done_datetime);
  return (
    <Container>
      <Row>
        <span className="mr-1 text-center">{t("Done on ")}</span>
        <Highlight className="text-center">
          {last_carcheck_date.toLocaleDateString()}
        </Highlight>
      </Row>
      <Row>
        <span className="mr-1 text-center">{t("at")}</span>
        <Highlight className="text-center">
          {last_carcheck_date.toLocaleTimeString()}
        </Highlight>
      </Row>
    </Container>
  );
}

export function Company(props: { user: User }) {
  return (
    <Highlight>
      {_.upperFirst(_.toLower("" + props.user.profile.company))}
    </Highlight>
  );
}

export function Phone(props: { user: User }) {
  return <Highlight>{props.user.profile.phone}</Highlight>;
}

export function Department(props: { user: User }) {
  return <Highlight>{props.user.profile?.department?.name}</Highlight>;
}

export function CurrentVehicle(props: { user: User }) {
  if (!props.user.vehicle_set.length) {
    return null;
  }
  const lastVehicle: Vehicle =
    props.user.vehicle_set[props.user.vehicle_set.length - 1];
  return (
    <DocumentLink resource="vehicles" id={lastVehicle.id}>
      <Highlight>{lastVehicle.brand}</Highlight>
      <>
        {" |"} {lastVehicle.model}
      </>
    </DocumentLink>
  );
}

export function Roles(props: { user: User }) {
  return (
    <Highlight>
      {props.user.groups
        .map((group) => getOneGroupTranslation(group))
        .join(" ,")}
    </Highlight>
  );
}

export default function (props: {
  user: User;
  modelName: string;
  onDelete: (id: number) => void;
}) {
  const { user, modelName } = props;
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <tr key={user.username}>
      <IfGroup groups={[Group.BACKOFFICE_USERS]}>
        <td>{user.id}</td>
      </IfGroup>
      <td className="align-middle">
        <Picture user={user} />
      </td>
      <td className="align-middle">
        <Name user={user} />
      </td>
      <IfGroup groups={[Group.BACKOFFICE_USERS]}>
        <td className="align-middle">
          <Company user={user} />
        </td>
      </IfGroup>
      <td className="align-middle">
        <Department user={user} />
      </td>
      <td className="align-middle">
        <Roles user={user} />
      </td>
      <td className="align-middle">
        <Phone user={user} />
      </td>
      <td className="align-middle">
        <CurrentVehicle user={user} />
      </td>
      <td className="align-middle">
        <IfGroup groups={[Group.BACKOFFICE_USERS, Group.FLEET_MANAGERS]}>
          <RowOptionButton
            id={user.id}
            modelName={modelName}
            extraOptions={[
              {
                label: t("edit"),
                onClick: () => history.push(`/${modelName}/${user.id}/form`),
              },
              {
                label: t("delete"),
                onClick: () => props.onDelete(user.id),
              },
            ]}
          />
        </IfGroup>
      </td>
    </tr>
  );
}
