// *---- Imports ----
import React from "react";
import { capitalize, safeToUppercase } from "../../../Utils/utils";
// *---- Style ----
import styles from "./KeyValueText.module.css";

export interface KeyValueTextProps {
  title: string;
  shouldWrap?: boolean;
  hide?: boolean;
  capitalized?: boolean;
  uppercased?: boolean;
}

export default function KeyValueText<
  Props extends React.PropsWithChildren<KeyValueTextProps>
>({ title, children, shouldWrap, hide, uppercased, capitalized }: Props) {
  function getTextStyle(value: string) {
    if (uppercased) {
      return safeToUppercase(value);
    }
    if (capitalized) {
      return capitalize(value);
    } else {
      return value;
    }
  }

  if (hide) {
    return null;
  }
  const containerClassName = shouldWrap
    ? `${styles.container} ${styles.flexWrap}`
    : styles.container;
  return (
    <div className={containerClassName}>
      <p className={styles.title}>{title}</p>
      <p className={styles.value}>
        {/* @ts-ignore */}
        {typeof children === "string" ? getTextStyle(children) : children}
      </p>
    </div>
  );
}
