import { RESTQueryBuilder } from "../../Common/ApiGateway/buildRESTUrl";
import { HTTPGETService } from "../../Common/Services/types";
import Axios from "axios";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";

export const makeImageDownloadUrl = (
  urlBuilder: RESTQueryBuilder,
  carcheckId: number
) => {
  return urlBuilder({
    resource: "carchecks",
    action: `${carcheckId}/images`,
  });
};

export const makeCarcheckImageDownloadService = (
  urlBuilder: RESTQueryBuilder,
  getService: HTTPGETService<any>
) => {
  const getImagesService = async (carcheckId: number) => {
    const url = makeImageDownloadUrl(urlBuilder, carcheckId);
    return await getService.get(url);
  };

  return getImagesService;
};

export default makeCarcheckImageDownloadService(buildRESTUrl, Axios);
