import _ from "lodash";
import makeUseFormViewModel from "../../Common/ViewModels/makeUseFormViewModel";
import { User } from "../../Config/datamodels/interfaces";
import createService from "../services/CreateService";
import detailService from "../services/DetailService";
import updateService from "../services/UpdateService";
import { useFormReducer } from "../store/formReducer";
import { fieldProfile } from "./interfaces";
import { ProfileField, UserFormFieldsName } from "./types";
import useFormField from "./useFormFields";
import store from "../../Config/store";
import ToastService from "../../Common/Services/ToastService";

const requestInterceptor = (formValue: any) => {
  const profileFields: ProfileField[] = ["contract_end_date"];
  let profile: fieldProfile = _.pick(formValue, profileFields);
  profile["company"] = _.get(store.getState(), "auth.user.profile.company");
  if (_.get(formValue, "phone")) {
    profile["phone"] = _.get(formValue, "phone");
  }
  profile["department"] = _.get(formValue, "department.value");
  profile["language"] = _.get(formValue, "language");
  formValue["username"] = formValue["email"];
  formValue["groups"] = formValue["groups"].map(
    (g: { label: string; value: string }) => g.value
  );
  formValue["profile"] = profile;

  return Promise.resolve(formValue);
};

const responseInterceptor = (model: User) => {
  return Promise.resolve({
    first_name: model?.first_name,
    last_name: model?.last_name,
    email: model?.email,
    groups: model?.groups.map((group: string) => ({
      label: group,
      value: group,
    })),
    department: {
      label: model?.profile?.department?.name,
      value: model?.profile?.department?.id,
    },
    phone: model?.profile?.phone,
    contract_end_date: model?.profile?.contract_end_date,
    language: model?.profile?.language,
  });
};

const useFormViewModel = makeUseFormViewModel<User, UserFormFieldsName>(
  createService,
  updateService,
  detailService,
  useFormReducer,
  useFormField,
  ToastService
);
export default useFormViewModel;
export { requestInterceptor, responseInterceptor };
