import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Model } from "../../Config/datamodels/interfaces";
import { FieldValueType } from "../../Config/datamodels/types";
import { LanguageContext } from "../../Config/i18n/LanguageGate";
import { hydrateFields } from "../Converters/hydrateFields";
import {
  CreateService,
  DetailService,
  ToastService,
  UpdateService,
} from "../Services/types";
import { FormFieldMap } from "../Store/FormReducer/Fields";
import { UseFormReducer } from "../Store/FormReducer/useFormReducer";
import { useDebouncedCallback } from "use-debounce";

export type FormViewModelReturn<
  ModelType extends Model,
  FieldsName extends string
> = {
  clear: () => void;
  upsert: (
    body: ModelType,
    afterSubmit?: (response: ModelType) => void
  ) => void;
  setField: (
    fieldName: string,
    fieldValue: FieldValueType,
    fieldArgument?: string
  ) => void;
  formFields: FormFieldMap<FieldsName>;
  formErrors: Error | null;
  formIsLoading: boolean;
};

export type FormRequestInterceptor<ModelType> = (
  body: ModelType
) => Promise<ModelType>;

export type FormResponseInterceptor<ModelType> = (
  response: ModelType
) => Promise<any>;

const applyRequestInterceptor = async <ModelType>(
  body: ModelType,
  interceptor?: FormRequestInterceptor<ModelType>
) => {
  if (interceptor) return await interceptor(body);
  return body;
};

const applyResponseInterceptor = async <ModelType>(
  body: ModelType,
  interceptor?: FormRequestInterceptor<ModelType>
) => {
  if (interceptor) return await interceptor(body);
  return body;
};

const makeUseFormViewModel = <
  ModelType extends Model,
  FieldsName extends string
>(
  createService: CreateService<ModelType>,
  updateService: UpdateService<ModelType>,
  detailService: DetailService<ModelType>,
  useFormReducer: UseFormReducer<FieldsName>,
  useInitialFields: (id?: string | number) => FormFieldMap<FieldsName>,
  toastService: ToastService
) => (
  id?: number | string,
  requestInterceptor?: FormRequestInterceptor<ModelType>,
  responseInterceptor?: FormResponseInterceptor<ModelType>
) => {
  const reducer = useFormReducer();
  const { t } = useTranslation();
  const history = useHistory();
  const {
    initFields,
    formFields,
    setIsLoading,
    success,
    error,
    setField,
    formErrors,
    formIsLoading,
  } = reducer;
  const initialFields = useInitialFields(id);
  const { lang } = useContext(LanguageContext);

  const clear = () => {
    reducer.clear();
    initFields(initialFields);
  };

  const clearWithoutInitFields = () => {
    reducer.clear();
  };

  const upsert = async (
    body: ModelType,
    afterSubmit?: (response: ModelType) => void
  ) => {
    const service = id
      ? async (body: ModelType) => await updateService(body, id)
      : createService;
    setIsLoading();
    try {
      const newBody = await applyRequestInterceptor(body, requestInterceptor);
      const response = await service(newBody);
      success();
      clear();
      toastService.success(
        id ? t("Update successfull") : t("Creation Sucessfull")
      );
      if (afterSubmit) {
        afterSubmit(response);
      } else {
        history.goBack();
      }
    } catch (err) {
      error(err);
      toastService.error(t("There was an issue"));
    }
  };

  useEffect(() => {
    (async () => {
      let fields = initialFields;

      if (id) {
        try {
          setIsLoading();
          const body = await detailService(id);
          success();
          const transformedBody = await applyResponseInterceptor(
            body,
            responseInterceptor
          );
          fields = hydrateFields(fields, transformedBody);
          initFields(fields);
        } catch (err) {
          error(err);
        }
      } else if (!formFields) initFields(fields);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, lang]);

  useEffect(() => {
    return () => {
      if (id) clearWithoutInitFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const debouncedUpsert = useDebouncedCallback(upsert);

  return {
    clear,
    upsert: debouncedUpsert.callback,
    setField,
    formFields,
    formErrors,
    formIsLoading,
  };
};

export default makeUseFormViewModel;
