// *---- Imports ----
import React from "react";
import { useTranslation } from "react-i18next";
import { TyreState } from "../../../../../Config/datamodels/interfaces";
import { getTyreState, tyreStateColor } from "../../../../../Utils/utils";

// *---- Style ----
import style from "./SingleTyreWidget.module.css";

// *---- Components ----

export type Anchor = {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
};
export interface SingleTyreWidgetProps {
  anchor: Anchor;
  state?: TyreState;
}

export default function SingleTyreWidget(props: SingleTyreWidgetProps) {
  const { t } = useTranslation();

  return (
    <span
      className={style.status}
      style={{
        ...props.anchor,
        color: tyreStateColor(props?.state),
      }}
    >
      {getTyreState(t, props?.state)}
    </span>
  );
}
