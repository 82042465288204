import { Validators } from "@lemoncode/fonk";
import { createFinalFormValidation } from "@lemoncode/fonk-final-form";

const validationSchema = {
  field: {
    email: [
      {
        validator: Validators.required.validator,
        message: "Please fill in this mandatory field",
      },
    ],
  },
};
export default createFinalFormValidation(validationSchema);
