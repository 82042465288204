// *---- Imports ----
import React from "react";
import { useTranslation } from "react-i18next";
import SquareThumbnail from "../../../../Common/Components/SquareThumbnail";
import Thumbnail, {
  ThumbnailSize,
} from "../../../../Common/Components/Thumbnail";
import { Participation } from "../../../../Config/datamodels/interfaces";
import styles from "./ParticipationSectionContent.module.css";

export interface ParticipationSectionContentProps {
  participation: Participation;
}

export default function ParticipationSectionContent(
  props: ParticipationSectionContentProps
) {
  const { t } = useTranslation();
  const user = props.participation.user;

  return (
    <>
      <Thumbnail
        src={props.participation.user.profile.profile_picture?.small}
        size={ThumbnailSize.small}
      />
      <div className={styles.textWrapper}>
        <div className={styles.userInfoWrapper}>
          <div>
            <span className={styles.firstname}>{user.first_name + " "}</span>
            <span className={styles.lastname}>{user.last_name}</span>
          </div>
          <div>{user.email}</div>
          <div>{user.profile.phone || t("No phone")}</div>
        </div>
        <div className={styles.commentWrapper}>
          {props.participation.comment.length
            ? props.participation.comment
            : t("No Comment")}
        </div>
      </div>
      <SquareThumbnail
        src={props.participation.signature.small}
        size={ThumbnailSize.small}
      />
    </>
  );
}
