import React from "react";
import styles from "./ElementWrapper.module.css";

export interface ElementWrapper {
  title: string;
  children: JSX.Element | JSX.Element[];
}

export default function ElementWrapper<Props extends ElementWrapper>({
  title,
  children,
}: Props) {
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{title}</p>
      {children}
    </div>
  );
}
