import { Badge } from "reactstrap";
import { classes } from "../../../../../Utils/classes";
import styles from "./PresenceBadge.module.css";
import { Check, Dash, X } from "react-bootstrap-icons";
import React from "react";

interface BadgeParams {
  onClick?: () => void;
  disabled?: boolean;
}

export function NotKnownBadge(props: BadgeParams) {
  return (
    <>
      <Badge
        color="secondary"
        pill
        onClick={props.onClick}
        className={classes([!props.disabled, styles.clickable])}
      >
        <Dash size={16} color="white" />
      </Badge>
    </>
  );
}

export function IsPresentBadge(props: BadgeParams) {
  return (
    <>
      <Badge
        color="success"
        pill
        onClick={props.onClick}
        className={classes([!props.disabled, styles.clickable])}
      >
        <Check size={16} color="white" />
      </Badge>
    </>
  );
}

export function MissingBadge(props: BadgeParams) {
  return (
    <>
      <Badge
        color="danger"
        pill
        onClick={props.onClick}
        className={classes([!props.disabled, styles.clickable])}
      >
        <X size={16} color="white" />
      </Badge>
    </>
  );
}

interface BadgeActionBarProps {
  onClick(value: boolean | null): void;
}

export function BadgeActionBar(props: BadgeActionBarProps) {
  return (
    <>
      <MissingBadge onClick={() => props.onClick(false)} />
      <NotKnownBadge onClick={() => props.onClick(null)} />
      <IsPresentBadge onClick={() => props.onClick(true)} />
    </>
  );
}
