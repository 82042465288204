import React, { useContext } from "react";
import {
  Carcheck,
  PartWithDamage,
} from "../../../../Config/datamodels/interfaces";
import style from "./PriceSummary.module.css";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../../../Config/i18n/LanguageGate";

const PART_PER_TABLE = 35;

export interface PriceSummaryProps {
  carcheck: Carcheck;
  getPartMaxAcceptableDamageTotal: (id: number) => number;
  getPartMaxNonAcceptableDamageCost: (id: number) => number;
}

export default function PriceSummary(props: PriceSummaryProps) {
  const firstPart = props.carcheck.damage_per_part?.slice(0, PART_PER_TABLE);
  const secondPart = props.carcheck.damage_per_part?.slice(PART_PER_TABLE);

  return (
    <>
      {firstPart && firstPart.length ? (
        <CostSummaryTable {...props} parts={firstPart} />
      ) : null}
      {secondPart && secondPart.length ? (
        <CostSummaryTable {...props} parts={secondPart} />
      ) : null}
    </>
  );
}

export interface CostSummaryTableProps extends PriceSummaryProps {
  parts: PartWithDamage[];
}

export function CostSummaryTable(props: CostSummaryTableProps) {
  const { t } = useTranslation();
  const { lang } = useContext(LanguageContext);

  return (
    <table className={style.table}>
      <tbody>
        <tr>
          <th>{t("Part")}</th>
          <th>{t("# Damages")}</th>
          <th>{t("Acceptable costs")}</th>
          <th>{t("Non acceptable costs")}</th>
        </tr>
        {props.parts.map((part, index) => (
          <tr key={"1-" + index}>
            <td>{lang.getCustomTranslation("name", part)}</td>
            <td>{part.damages.length}</td>
            <td>{props.getPartMaxAcceptableDamageTotal(part.id)} €</td>
            <td>{props.getPartMaxNonAcceptableDamageCost(part.id)} €</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
