import React from "react";
import ImportView from "../../../Common/Components/ImportView";
import { User } from "../../../Config/datamodels/interfaces";
import { useTranslation } from "react-i18next";
import importViewModel from "../../viewModel/importViewModel";

export default function () {
  const { t } = useTranslation();
  const importData = importViewModel();
  return (
    <ImportView<User>
      title={t("User file upload")}
      description={t("Upload your excel file to import multiple users at once")}
      importData={importData}
      exampleFilePath="/examples/users.xlsx"
    />
  );
}
