// *---- Imports ----
import React from "react";
import { useTranslation } from "react-i18next";
import { Participation } from "../../../../../Config/datamodels/interfaces";
import { capitalize } from "../../../../../Utils/utils";

// *---- Style ----
import styles from "./Participants.module.css";

// *---- Components ----
import KeyValueText from "../../../../../Common/Components/KeyValueText";
import Card from "../../../../../Common/Components/Card";

interface ParticipantsProps {
  participants: Participation[];
}

export interface ParticipantProps {
  participant: Participation;
}

export default function Participants<Props extends ParticipantsProps>(
  props: Props
) {
  return (
    <>
      {props.participants.map((participant: Participation, index: number) => {
        return <ParticipantComponent participant={participant} key={index} />;
      })}
    </>
  );
}

export function ParticipantComponent({ participant }: ParticipantProps) {
  const { t } = useTranslation();
  return (
    <Card>
      <div className={styles.container}>
        <PictureAndSignature participant={participant} />
        <div className={styles.detailsContainer}>
          <div className={styles.userInfo}>
            <KeyValueText shouldWrap title={t("Company")}>
              {participant.user.profile.company as string}
            </KeyValueText>
            <KeyValueText shouldWrap title={t("Department")}>
              {participant.user.profile.department?.name as string}
            </KeyValueText>
            <KeyValueText shouldWrap title={t("Email")}>
              {participant.user.email}
            </KeyValueText>
            <KeyValueText shouldWrap title={t("Phone")}>
              {participant.user.profile.phone as string}
            </KeyValueText>
          </div>
          <CommentZone title={t("Comment")}>{participant.comment}</CommentZone>
        </div>
      </div>
    </Card>
  );
}

export function CommentZone({
  title,
  children,
}: {
  title: string;
  children: string;
}) {
  return (
    <div className={styles.commentContainer}>
      <p className={styles.commentTitle}>{title}</p>
      <p className={styles.commentText}>{capitalize(children)}</p>
    </div>
  );
}

export function PictureAndSignature({ participant }: ParticipantProps) {
  const { t } = useTranslation();
  return (
    <div className={styles.profileContainer}>
      {participant.user?.profile?.profile_picture?.medium && (
        <img
          alt={t("User Profile Picture")}
          className={styles.userProfilePicture}
          src={participant.user?.profile?.profile_picture?.medium}
        ></img>
      )}
      <div className={styles.nameContainer}>
        <span className={styles.firstName}>
          {" "}
          {capitalize(participant.user.first_name)}
        </span>

        <span className={styles.lastName}>
          {capitalize(participant.user.last_name)}
        </span>
      </div>
      <img
        alt={t("User Signature")}
        className={styles.userSignature}
        src={participant.signature.small}
      ></img>
    </div>
  );
}
