// *---- Imports ----
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import usePrintViewModel from "../../viewModel/usePrintViewModel";
import PrintScreen from "./PrintScreen";

export default function CarcheckPrintScreen() {
  // * ---- setup ----
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const {
    data,
    get,
    isLoading,
    totalPageCount,
    perPageDamages,
  } = usePrintViewModel(id);

  useEffect(() => {
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <PrintScreen
      documentTitle={t("Carcheck report") + " #" + id}
      data={data}
      isLoading={isLoading}
      perPageDamages={perPageDamages}
      totalPageCount={totalPageCount}
    />
  );
}
