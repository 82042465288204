import React from "react";
import { useTranslation } from "react-i18next";
import KeyValueText from "../../../../Common/Components/KeyValueText";
import { Carcheck } from "../../../../Config/datamodels/interfaces";
import { getFuelLevel } from "../../../../Utils/utils";
import styles from "./VehicleDetailsContent.module.css";

export interface VehicleDetailsContentProps {
  carcheck: Carcheck;
}

export default function VehicleDetailsContent(
  props: VehicleDetailsContentProps
) {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.columnWrapper}>
        <KeyValueText title={t("Fuel level")}>
          {"" + getFuelLevel(t, props.carcheck.fuel_level)}
        </KeyValueText>
      </div>
      <div className={styles.columnWrapper}>
        <KeyValueText title={t("Number of keys")}>
          {"" + props.carcheck.key_count}
        </KeyValueText>
      </div>
      <div className={styles.columnWrapper}>
        <KeyValueText title={t("Interior clean?")}>
          {props.carcheck.is_interior_clean ? t("yes") : t("no")}
        </KeyValueText>
      </div>
      <div className={styles.columnWrapper}>
        <KeyValueText title={t("Exterior clean?")}>
          {props.carcheck.is_exterior_clean ? t("yes") : t("no")}
        </KeyValueText>
      </div>
    </>
  );
}
