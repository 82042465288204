import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import _ from "lodash";
import { ArrowDown, ArrowUp } from "react-bootstrap-icons";
import styles from "./ListOrder.module.css";

export interface ListOrderProps {
  currentOrdering: string;
  currentDescending: boolean;
  fields: { name: string; display: string }[];
  onOrderBy: (name: string, descending: boolean) => void;
}

export default function ListOrder(props: ListOrderProps) {
  const { t } = useTranslation();
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const currentDisplay = _.find(
    props.fields,
    (field) => field.name === props.currentOrdering
  );
  const onFieldClick = (name: string) => (ev: any) => {
    ev.preventDefault();
    props.onOrderBy(name, props.currentDescending);
  };
  const onDescendingClick = (ev: any) => {
    ev.preventDefault();
    props.onOrderBy(props.currentOrdering, !props.currentDescending);
  };

  return (
    <div>
      <span>{t("Order by")}</span>
      <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle color="none" caret>
          {currentDisplay?.display}
        </DropdownToggle>
        <DropdownMenu>
          {props.fields.map(({ name, display }) => (
            <DropdownItem key={name} onClick={onFieldClick(name)}>
              {display}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </ButtonDropdown>
      <span className={styles.descending}>
        {props.currentDescending ? (
          <ArrowDown
            onClick={onDescendingClick}
            data-testid="change-order-descending"
          />
        ) : (
          <ArrowUp
            onClick={onDescendingClick}
            data-testid="change-order-descending"
          />
        )}
      </span>
    </div>
  );
}
