import { useTranslation } from "react-i18next";
import {
  DateFilter,
  IntFilter,
  IsPresentFilter,
  TextFilter,
} from "../../../Common/Filters";

const useFields = () => {
  const { t } = useTranslation();

  const filterableFields = [
    new TextFilter("plate", t("Plate")),
    new TextFilter("driver__email", t("Driver")),
    new TextFilter("vin", "Vin"),
    new TextFilter("brand", t("Brand")),
    new TextFilter("model", t("Model")),
    new IntFilter("km_contract", t("Contract")),
    new IntFilter("mileage", t("Mileage")),
    new IntFilter("year", t("Year")),
    new TextFilter("type__name", t("Type")),
    new TextFilter("owning_partner__name", t("Owner")),
    new DateFilter("leasing_start_date", t("Leasing start date")),
    new DateFilter("leasing_end_date", t("Leasing end date")),
    new TextFilter("department__name", t("Department")),
    new IsPresentFilter("driver", t("With driver"), "False"), // using isnull_b so the value must be False if we want vehicle with drivers
  ];

  const orderableFields = [
    { name: "", display: t("None") },
    { name: "created", display: t("Created") },
    { name: "driver__email", display: t("Driver") },
    { name: "vin", display: "Vin" },
    { name: "brand", display: t("Brand") },
    { name: "model", display: t("Model") },
    { name: "km_contract", display: t("Contract Mileage") },
    { name: "mileage", display: t("Mileage") },
    { name: "year", display: t("Year") },
    { name: "type__name", display: t("Type") },
    { name: "owning_partner__name", display: t("Owner") },
    { name: "leasing_start_date", display: t("Leasing start date") },
    { name: "leasing_end_date", display: t("Leasing end date") },
  ];

  return { filterableFields, orderableFields };
};

export default useFields;
