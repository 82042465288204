import { useDispatch, useSelector } from "react-redux";
import makeListReducer from "../../Common/Store/ListReducer";
import makeUseListReducer from "../../Common/Store/ListReducer/useListReducer";

const selectorReducer = makeListReducer("reporting-selector");
export default selectorReducer;
const useSelectorReducer = makeUseListReducer(
  "reporting-selector",
  useSelector,
  useDispatch
);
export { useSelectorReducer };
