import React from "react";
import { useTranslation } from "react-i18next";
import { CarcheckRule, Group } from "../../../Config/datamodels/interfaces";
import DocumentLink from "../../../Common/Components/DocumentLink";
import Emphasize from "../../../Common/Components/Emphasize";
import Highlight from "../../../Common/Components/Highlight";
import IfGroup from "../../../auth/components/IfGroup";
import _ from "lodash";
import RowOptionButton from "../../../Common/Components/RowOptionButton";

export function cronToString(cron: string, t: (code: string) => string) {
  const durations = [t("days"), t("weeks"), t("months"), t("years")];
  const cleanSplit = cron
    .split(",")
    .map((num) => num.trim())
    .map((num) => (num === "*" ? 0 : num));
  const asPairs = _.zip(cleanSplit, durations).filter(([qty]) => qty !== 0);
  return asPairs.map(([qty, duration]) => `${qty} ${duration}`).join(" and ");
}

export function Cron(props: { carcheckRule: CarcheckRule }) {
  const { t } = useTranslation();
  const { carcheckRule } = props;
  let cronExpr = "";
  let extraDescription = "";
  if (carcheckRule.cron) {
    cronExpr = cronToString(carcheckRule.cron, t);
  } else if (carcheckRule.time_before_end_contract_employee) {
    cronExpr = cronToString(carcheckRule.time_before_end_contract_employee, t);
    extraDescription = t(" before the end of an employee contract");
  } else if (carcheckRule.time_before_end_contract_vehicle) {
    cronExpr = cronToString(carcheckRule.time_before_end_contract_vehicle, t);
    extraDescription = t(" before the end of a vehicle contract");
  } else {
    throw new Error("never get here");
  }

  return (
    <p>
      {t("Every ")}
      <Highlight>{cronExpr}</Highlight>
      <Emphasize>{extraDescription}</Emphasize>
    </p>
  );
}

export function OwningCompany(props: { carcheckRule: CarcheckRule }) {
  const { carcheckRule } = props;
  if (!carcheckRule.owning_partner) {
    return <p></p>;
  } else {
    return (
      <DocumentLink resource="companies" id={carcheckRule.owning_partner.name}>
        <p>{_.capitalize(carcheckRule.owning_partner.name)}</p>
      </DocumentLink>
    );
  }
}

export function VehicleType(props: { carcheckRule: CarcheckRule }) {
  const { carcheckRule } = props;
  if (!carcheckRule.vehicle_type) {
    return <p></p>;
  } else {
    return (
      <DocumentLink resource="vehicle_types" id={carcheckRule.vehicle_type.id}>
        <p>{_.capitalize(carcheckRule.vehicle_type.name)}</p>
      </DocumentLink>
    );
  }
}

export default function CarcheckRuleItem({
  carcheckRule,
  modelName,
  onDelete,
}: {
  carcheckRule: CarcheckRule;
  modelName: string;
  onDelete: (id: number | string) => void;
}) {
  const { t } = useTranslation();
  const deleteLabel = t("Delete");
  return (
    <tr key={"" + carcheckRule.id}>
      <IfGroup groups={[Group.BACKOFFICE_USERS]}>
        <td>{carcheckRule.id}</td>
        <td>{_.capitalize(carcheckRule.company)}</td>
      </IfGroup>
      <td>{_.capitalize(carcheckRule.carcheck_type)}</td>
      <td>
        <Cron carcheckRule={carcheckRule} />
      </td>
      <td>
        <VehicleType carcheckRule={carcheckRule} />
      </td>
      <td>
        <OwningCompany carcheckRule={carcheckRule} />
      </td>
      <td className="align-middle">
        <RowOptionButton
          id={carcheckRule.id}
          modelName={modelName}
          extraOptions={[
            {
              label: deleteLabel,
              onClick: () => onDelete(carcheckRule.id),
            },
          ]}
        />
      </td>
    </tr>
  );
}
