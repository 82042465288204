import React from "react";
import { User, Group } from "../../../Config/datamodels/interfaces";
import ListView from "../../../Common/Components/ListView";
import { Path } from "../../../Config/Router/utils";
import ListItem from "./ListItem";
import { useTranslation } from "react-i18next";
import useAuthViewModel from "../../../auth/viewModel/authViewModel";
import useListViewModel from "../../viewModel/listViewModel";
import useFields from "./useFields";

export default function ListScreen() {
  const listData = useListViewModel();
  const { filterableFields, orderableFields } = useFields();
  const { t } = useTranslation();
  const { groups } = useAuthViewModel();
  const headers = [
    "",
    t("Name"),
    t("Department"),
    t("Roles"),
    t("Mobile phone"),
    t("Vehicle"),
    "",
  ];
  if (groups.includes(Group.BACKOFFICE_USERS)) {
    headers.splice(0, 0, "id");
    headers.splice(3, 0, t("Company"));
  }
  return (
    <>
      <ListView<User>
        title={t("Users")}
        basePath={Path.USERS}
        navigationFormButtonLabel={t("Add New User")}
        hasImport={true}
        emptyListTitle={t("You haven't create any users yet")}
        emptyListDescription={t(
          "All users will be listed here once you have created them"
        )}
        listData={listData}
        headers={headers}
        renderLine={(user: User) => (
          <ListItem
            user={user}
            modelName="users"
            onDelete={listData.deleteById}
          />
        )}
        availableFilters={filterableFields}
        orderableFields={orderableFields}
      />
    </>
  );
}
