import makeListViewModel from "../../Common/ViewModels/makeUseListViewModel";
import { CarcheckRule } from "../../Config/datamodels/interfaces";
import deleteService from "../services/deleteService";
import listService from "../services/listService";
import { useListReducer } from "../store/listReducer";

const listViewModel = makeListViewModel<CarcheckRule>(
  listService,
  useListReducer,
  deleteService
);
export default listViewModel;
