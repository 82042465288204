import { createFinalFormValidation } from "@lemoncode/fonk-final-form";
import { Validators } from "@lemoncode/fonk";
import { matchField } from "@lemoncode/fonk-match-field-validator";
import i18n from "../../../Config/i18n";

const activateValidationSchema = {
  field: {
    password: [
      {
        validator: Validators.required.validator,
        message: "Please fill in this mandatory field",
      },
    ],
    confirmPassword: [
      {
        validator: Validators.required.validator,
        message: "Please fill in this mandatory field",
      },
      {
        validator: matchField.validator,
        customArgs: { field: "password" },
        message: i18n.t("Different password"),
      },
    ],
  },
};

const activateValidation = createFinalFormValidation(activateValidationSchema);
export default activateValidation;
