import _ from "lodash";
import store from "../store";
import { types } from "../../auth/store/reducer";

const dispatchResetAndRedirection = _.throttle(
  () => {
    const currentPathName = window.location.pathname;
    store.dispatch({ type: types.AUTH_LOGOUT });
    store.dispatch({
      type: types.AUTH_SET_REDIRECT_URL,
      payload: currentPathName,
    });
  },
  5000,
  { trailing: false }
);

export default function (axios: any) {
  axios.interceptors.response.use(
    function (response: any) {
      return response;
    },
    function (error: any) {
      if (_.get(error, "response.status") === 401) {
        dispatchResetAndRedirection();
      }
      return Promise.reject(error);
    }
  );
}
