import { combineReducers } from "redux";
import auth from "../../auth/store/reducer";
import userListReducer from "../../users/store/listReducer";
import userFormReducer from "../../users/store/formReducer";
import userDetailReducer from "../../users/store/detailReducer";
import userImportReducer from "../../users/store/importReducer";
import vehiclesListReducer from "../../vehicles/store/listReducer";
import vehiclesFormReducer from "../../vehicles/store/formReducer";
import vehicleDetailReducer from "../../vehicles/store/detailReducer";
import vehicleImportReducer from "../../vehicles/store/importReducer";
import rulesFormReducer from "../../rules/store/formReducer";
import rulesListReducer from "../../rules/store/listReducer";
import requestsListReducer from "../../requests/store/listReducer";
import requestsFormReducer from "../../requests/store/formReducer";
import carchecksDetailReducer from "../../carchecks/store/useDetailReducer";
import carchecksListReducer from "../../carchecks/store/useListReducer";
import partnerFormReducer from "../../partners/store/formReducer";
import partnerListReducer from "../../partners/store/listReducer";
import departmentFormReducer from "../../departments/store/formReducer";
import departmentListReducer from "../../departments/store/listReducer";
import selectorReducer from "../../reporting/store/selectorReducer";
import deltaReducer from "../../reporting/store/deltaReducer";
import heatmapReducer from "../../reporting/store/heatmapReducer";
import companyFormReducer from "../../company/store/formReducer";

export default combineReducers({
  auth,
  users: userListReducer,
  usersForm: userFormReducer,
  usersDetail: userDetailReducer,
  usersImport: userImportReducer,
  vehicles: vehiclesListReducer,
  vehiclesForm: vehiclesFormReducer,
  vehiclesDetail: vehicleDetailReducer,
  vehiclesImport: vehicleImportReducer,
  rules: rulesListReducer,
  rulesForm: rulesFormReducer,
  requests: requestsListReducer,
  requestsForm: requestsFormReducer,
  carchecks: carchecksListReducer,
  carchecksDetail: carchecksDetailReducer,
  partners: partnerListReducer,
  partnersForm: partnerFormReducer,
  departments: departmentListReducer,
  departmentsForm: departmentFormReducer,
  "reporting-selector": selectorReducer,
  deltaDetail: deltaReducer,
  heatmap: heatmapReducer,
  companiesForm: companyFormReducer,
});
