import makeListViewModel from "../../Common/ViewModels/makeUseListViewModel";
import listService from "../services/listService";
import sendReminderService from "../services/sendReminderService";
import { useListReducer } from "../store/listReducer";
import { useCallback, useMemo, useState } from "react";
import _ from "lodash";
import { deleteService } from "../services/deleteService";

const baseListViewModel = makeListViewModel(listService, useListReducer);

const useListViewModel = () => {
  const { setLoading, setError } = useListReducer();
  const { list, data } = baseListViewModel();
  const [requestToDeleteId, setRequestToDeleteId] = useState<number | null>(
    null
  );
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);

  const sendReminderEmail = async (requestId: number | string) => {
    setLoading();
    try {
      await sendReminderService({ id: requestId });
      await list();
    } catch (err) {
      setError(err);
    }
  };

  const isDeleteModalOpen = useMemo(() => requestToDeleteId !== null, [
    requestToDeleteId,
  ]);
  const closeDeleteModal = useCallback(() => setRequestToDeleteId(null), [
    setRequestToDeleteId,
  ]);
  const openDeleteModal = useCallback(
    (id: number) => setRequestToDeleteId(id),
    [setRequestToDeleteId]
  );
  const requestToDelete = useMemo(
    () => _.find(data, (item) => item.id === requestToDeleteId),
    [requestToDeleteId, data]
  );
  const confirmDelete = useCallback(
    async (notify?: boolean) => {
      setDeleteIsLoading(true);
      try {
        if (requestToDeleteId) {
          await deleteService(requestToDeleteId, notify);
          list();
        }
      } finally {
        setDeleteIsLoading(false);
        setRequestToDeleteId(null);
      }
    },
    [list, requestToDeleteId, setDeleteIsLoading]
  );

  return {
    ...baseListViewModel(),
    sendReminderEmail,
    isDeleteModalOpen,
    closeDeleteModal,
    openDeleteModal,
    requestToDelete,
    confirmDelete,
    deleteIsLoading,
  };
};

export default useListViewModel;
