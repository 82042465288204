import moment from "moment";

export interface Choice {
  label: string;
  value: any;
}

export enum FilterType {
  TEXT = "TEXT",
  DATE = "DATE",
  DATETIME = "DATETIME",
  INTEGER = "INTEGER",
}

export enum FilterCondition {
  EQ = "=",
  EQEQ = "==",
  GT = ">",
  LT = "<",
  GTE = ">=",
  LTE = "<=",
  CONTAINS = "~=",
  IN = "[]",
  ISNULL = "!",
}

export type InputType =
  | "number"
  | "datetime-local"
  | "date"
  | "text"
  | "select"
  | "checkbox";

export type CustomInputType = InputType | "search-and-select";

export abstract class Filter {
  protected _name: string;
  protected _display: string;
  protected _value: any;
  protected _condition?: FilterCondition;
  protected _possibleConditions: FilterCondition[];
  protected _isEditable: boolean;
  protected _choice: Choice[] | undefined;

  constructor(
    name: string,
    display: string,
    value: any,
    condition: FilterCondition | undefined,
    possibleConditions: FilterCondition[],
    choice: Choice[] | undefined = undefined
  ) {
    this._name = name;
    this._display = display;
    this._value = value;
    this._condition = condition;
    this._possibleConditions = possibleConditions;
    this._isEditable = true;
    this._choice = choice || undefined;
  }
  public get name() {
    return this._name;
  }
  public get display() {
    return this._display;
  }
  public get value() {
    return this._value;
  }
  public get condition(): FilterCondition | undefined {
    return this._condition;
  }
  public set value(value: any) {
    this._value = value;
  }
  public set condition(condition: FilterCondition | undefined) {
    this._condition = condition;
  }
  public get possibleConditions() {
    return this._possibleConditions;
  }
  public get isEditable() {
    return this._isEditable;
  }

  public get choice() {
    return this._choice;
  }
  protected get queryCondition(): string {
    switch (this.condition) {
      case FilterCondition.CONTAINS:
        return "icontains";
      case FilterCondition.EQ:
        return "iexact";
      case FilterCondition.EQEQ:
        return "exact";
      case FilterCondition.GT:
        return "gt";
      case FilterCondition.LT:
        return "lt";
      case FilterCondition.GTE:
        return "gte";

      case FilterCondition.ISNULL:
        return "isnull";

      case FilterCondition.IN:
        return "in";
      default:
        return "lte";
    }
  }
  public get queryString(): string[] {
    const leftHand = `${this.name}__${this.queryCondition}__${this.queryType}`;
    const rightHand = this.queryValue;
    return [leftHand, rightHand];
  }

  public abstract get inputType(): InputType | CustomInputType;
  protected abstract get queryType(): string;
  protected abstract get queryValue(): string;
  public abstract get type(): FilterType;
  public abstract clone(): Filter;
}

export class DateFilter extends Filter {
  constructor(name: string, display: string) {
    super(name, display, "", FilterCondition.CONTAINS, [
      FilterCondition.CONTAINS,
      FilterCondition.EQEQ,
      FilterCondition.GT,
      FilterCondition.GTE,
      FilterCondition.LT,
      FilterCondition.LTE,
    ]);
  }

  public get inputType(): InputType {
    return "date";
  }
  protected get queryType(): string {
    return "s";
  }
  protected get queryValue(): string {
    return moment(this.value).format("YYYY-MM-DD");
  }
  public get type(): FilterType {
    return FilterType.DATE;
  }
  public clone() {
    return new DateFilter(this._name, this._display);
  }
}
export class IntFilter extends Filter {
  constructor(name: string, display: string) {
    super(name, display, "", FilterCondition.EQEQ, [
      FilterCondition.EQEQ,
      FilterCondition.GT,
      FilterCondition.GTE,
      FilterCondition.LT,
      FilterCondition.LTE,
    ]);
  }

  public get inputType(): InputType {
    return "number";
  }
  protected get queryType(): string {
    return "i";
  }
  protected get queryValue(): string {
    return this.value;
  }
  public get type(): FilterType {
    return FilterType.INTEGER;
  }
  public clone() {
    return new IntFilter(this._name, this._display);
  }
}

export class TextFilter extends Filter {
  constructor(name: string, display: string) {
    super(name, display, "", FilterCondition.CONTAINS, [
      FilterCondition.EQ,
      FilterCondition.CONTAINS,
    ]);
  }

  public get inputType(): InputType {
    return "text";
  }
  protected get queryType(): string {
    return "s";
  }
  protected get queryValue(): string {
    return this.value;
  }
  public get type(): FilterType {
    return FilterType.TEXT;
  }
  public clone() {
    return new TextFilter(this._name, this._display);
  }
}

export class IntListFilter extends Filter {
  constructor(name: string, display: string) {
    super(name, display, "", FilterCondition.IN, [FilterCondition.IN]);
  }

  public get inputType(): InputType {
    return "number";
  }
  protected get queryType(): string {
    return "li";
  }
  protected get queryValue(): string {
    return this.value;
  }
  public get type(): FilterType {
    return FilterType.INTEGER;
  }
  public clone() {
    return new IntListFilter(this._name, this._display);
  }
}

export class StringListFilter extends Filter {
  constructor(name: string, display: string, value: string = "") {
    super(name, display, value, FilterCondition.IN, [FilterCondition.IN]);
  }

  public get inputType(): InputType {
    return "text";
  }
  protected get queryType(): string {
    return "ls";
  }
  protected get queryValue(): string {
    return this.value;
  }
  public get type(): FilterType {
    return FilterType.TEXT;
  }
  public clone() {
    return new StringListFilter(this._name, this._display);
  }
}

export class StringSelectFilter extends Filter {
  constructor(
    name: string,
    display: string,
    value: string = "",
    choice: Choice[] | undefined
  ) {
    super(
      name,
      display,
      value,
      FilterCondition.IN,
      [FilterCondition.IN],
      choice
    );
  }
  public get inputType(): InputType {
    return "select";
  }
  protected get queryType(): string {
    return "ls";
  }
  protected get queryValue(): string {
    return this.value;
  }
  public get type(): FilterType {
    return FilterType.TEXT;
  }
  public clone() {
    return new StringSelectFilter(
      this._name,
      this._display,
      this._value,
      this._choice
    );
  }
}

export class SearchSelectFilter extends Filter {
  searchFilter: TextFilter;
  searchOnModel: string;
  selectFieldLabel: string;
  selectFieldValue: string;
  constructor(
    name: string,
    display: string,
    value: string = "",
    searchFilter: TextFilter,
    searchOnModel: string,
    selectFieldLabel: string,
    selectFieldValue: string
  ) {
    super(name, display, value, FilterCondition.IN, [FilterCondition.IN]);
    this.searchFilter = searchFilter;
    this.searchOnModel = searchOnModel;
    this.selectFieldLabel = selectFieldLabel;
    this.selectFieldValue = selectFieldValue;
  }

  public get inputType(): CustomInputType {
    return "search-and-select";
  }
  protected get queryType(): string {
    return "ls";
  }
  protected get queryValue(): string {
    return this.value;
  }
  public get type(): FilterType {
    return FilterType.TEXT;
  }
  public clone() {
    return new SearchSelectFilter(
      this._name,
      this._display,
      this._value,
      this.searchFilter,
      this.searchOnModel,
      this.selectFieldLabel,
      this.selectFieldValue
    );
  }
}

export class IsPresentFilter extends Filter {
  constructor(name: string, display: string, value: string) {
    super(name, display, value, FilterCondition.ISNULL, []);
    this._choice = [
      {
        value: "False", // the condition is isnull, so in most case we will ask, is present ? Yes ? then put isnull to false
        label: "✅",
      },
      {
        value: "True",
        label: "❌",
      },
    ];
  }

  public get inputType(): InputType {
    return "select";
  }
  protected get queryType(): string {
    return "b";
  }
  protected get queryValue(): string {
    return this.value;
  }
  public get type(): FilterType {
    return FilterType.TEXT;
  }
  public clone() {
    return new IsPresentFilter(this._name, this._display, this._value);
  }
}

export function cloneFilter(filter: Filter): Filter {
  return filter.clone();
}

export function cloneFilterWithValue(filter: Filter, value: any): Filter {
  const newFilter = cloneFilter(filter);
  newFilter.value = value;
  newFilter.condition = filter.condition;
  return newFilter;
}

export function cloneFilterWithCondition(
  filter: Filter,
  condition: FilterCondition
): Filter {
  const newFilter = cloneFilter(filter);
  newFilter.condition = condition;
  newFilter.value = filter.value;
  return newFilter;
}
