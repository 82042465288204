import { useTranslation } from "react-i18next";
import { InvalidImportFileType } from "../Services/ImportService";
import { ImportService } from "../Services/types";
import { UseImportReducer } from "../Store/ImportReducer/useImportReducer";

export type ImportViewModelReturn = {
  importErrors: any;
  success: boolean;
  upload: (file: any) => Promise<void>;
  isLoading: boolean;
};

// 1 mb
const MAX_IMPORT_FILE_SIZE = 1000000;

const makeUseImportViewModel = (
  importService: ImportService,
  useImportReducer: UseImportReducer,
  modelName: string
) => () => {
  const { t } = useTranslation();
  const {
    importErrors,
    success,
    isLoading,
    setIsLoading,
    onSuccess,
    onError,
  } = useImportReducer();

  const upload = async (file: any) => {
    setIsLoading();
    try {
      if (file.size <= MAX_IMPORT_FILE_SIZE) {
        await importService(file, modelName);
        onSuccess();
      } else {
        onError(
          t("File is too big, max is ") + MAX_IMPORT_FILE_SIZE / 1000 + "kb"
        );
      }
    } catch (err) {
      if (err instanceof InvalidImportFileType) {
        onError(t("Wrong file format"));
      } else if (err.response && err.response.data && err.response.data.msg) {
        onError(err.response.data);
      } else {
        onError(t("Unknown error"));
      }
    }
  };

  return {
    importErrors,
    success,
    isLoading,
    upload,
  };
};

export default makeUseImportViewModel;
