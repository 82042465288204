// *---- Imports ----
import React from "react";
import { X } from "react-bootstrap-icons";

// *---- Style ----
import style from "./CloseTabButton.module.css";

// *---- Components ----

export interface CloseTabButtonProps {}
export default function CloseTabButton(props: CloseTabButtonProps) {
  return (
    <div className={style.wrapper} onClick={() => window.close()}>
      <X />
    </div>
  );
}
