import Axios from "axios";
import buildRESTUrl from "../../Common/ApiGateway/buildRESTUrl";
import { IntListFilter } from "../../Common/Filters";
import makeRESTListService from "../../Common/Services/ListService/RESTListService";
import { Damage } from "../../Config/datamodels/interfaces";

const baseDeltaDamageService = makeRESTListService<Damage>(
  "damages",
  buildRESTUrl,
  Axios
);
const deltaDamageService = async (carcheckid: number | string) => {
  const carcheckFilter = new IntListFilter("carcheck_presence", "");
  carcheckFilter.value = carcheckid;
  const response = await baseDeltaDamageService({
    offset: 0,
    limit: 200,
    filters: [carcheckFilter],
    orderBy: "",
    search: "",
    descending: false,
  });
  return response.results;
};

export default deltaDamageService;
