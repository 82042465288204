import { Carcheck } from "../../Config/datamodels/interfaces";

export function updatePresenceInCarcheckLocally(
  carcheck: Carcheck,
  inventoryPresenceId: number,
  newState: boolean | null
): Carcheck {
  const newCarcheck = { ...carcheck };
  newCarcheck.inventorypresence_set = [
    ...(newCarcheck.inventorypresence_set || []),
  ];
  const itemIndex = newCarcheck.inventorypresence_set.findIndex(
    (item) => item.id === inventoryPresenceId
  );
  if (itemIndex !== -1) {
    newCarcheck.inventorypresence_set[itemIndex] = {
      ...newCarcheck.inventorypresence_set[itemIndex],
      presence: newState,
    };
  }
  return newCarcheck;
}
