// *---- Imports ----
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
// *---- Styles ----
import styles from "../../DetailScreen.module.css";
// *---- Components ----
import VehiclePictureInfo from "../../../../../Common/Components/VehiclePictureInfoCard";
import ElementWrapper from "../../../../../Common/Components/ElementWrapper";
import Courtoisie from "../../../../../carchecks/screens/DocumentScreen/Components/Courtoisie";
import Damages from "../../../../../carchecks/screens/DocumentScreen/Components/Damages";
import DocumentScreenBody from "../../../../../Common/Components/DocumentScreenBody";
import InventoryPresenceComponent from "../../../../../carchecks/screens/DocumentScreen/Components/InventoryPresence";
import useDeltaViewModel from "../../../../viewModel/deltaViewModel";
import { Spinner } from "reactstrap";
import getTotalDamageCost from "../../../../../carchecks/converters/getTotalDamageCost";
import TotalCosts from "../../../../../carchecks/screens/DocumentScreen/Components/TotalCosts";
import { clusterPartsByPositionAndCategory } from "../../../../../carchecks/converters/clusterPartsPerPositionAndCategory";
import { clusterInventoryPresenceByPositionAndCategory } from "../../../../../carchecks/converters/clusterInventoryPerPositionAndCategory";

export interface ComponentProps {
  leftCarcheckId: number | string;
  rightCarcheckId: number | string;
}
export default function DeltaScreen<Props extends ComponentProps>({
  leftCarcheckId,
  rightCarcheckId,
}: Props) {
  const { t } = useTranslation();
  const { data, isLoading, get } = useDeltaViewModel(
    leftCarcheckId,
    rightCarcheckId
  );

  useEffect(() => {
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leftCarcheckId, rightCarcheckId]);

  const {
    getPartMaxAcceptableDamageTotal,
    getPartMaxNonAcceptableDamageCost,
    getTotalAcceptableDamageCost,
    getTotalNonAcceptableDamageCost,
  } = getTotalDamageCost(data, rightCarcheckId as number);

  if (isLoading || !data) {
    return <Spinner />;
  }

  return (
    <>
      <DocumentScreenBody>
        <ElementWrapper title={t("Vehicle Information")}>
          <VehiclePictureInfo
            classname={styles.spacer}
            vehicle={data.vehicle}
          />
        </ElementWrapper>

        <ElementWrapper title={t("General Carcheck Information")}>
          <Courtoisie disable={true} carcheck={data} />
        </ElementWrapper>
        <TotalCosts
          acceptableCost={getTotalAcceptableDamageCost()}
          nonAcceptable={getTotalNonAcceptableDamageCost()}
        />
        {data.damage_per_part && data.damage_per_part.length ? (
          <ElementWrapper title={`${t("New Damages")}`}>
            <Damages
              disable={true}
              availableParts={[]}
              getTotalAcceptableCost={getPartMaxAcceptableDamageTotal}
              getTotalNonAcceptableCost={getPartMaxNonAcceptableDamageCost}
              carcheckId={data.id}
              parts={clusterPartsByPositionAndCategory(data.damage_per_part)}
            />
          </ElementWrapper>
        ) : (
          <></>
        )}
        {data.inventorypresence_set &&
        data.inventorypresence_set?.length > 0 ? (
          <ElementWrapper title={t("Inventory Changes")}>
            <InventoryPresenceComponent
              updateIsDisabled={true}
              inventoryPresence={clusterInventoryPresenceByPositionAndCategory(
                data.inventorypresence_set
              )}
            />
          </ElementWrapper>
        ) : (
          <></>
        )}
      </DocumentScreenBody>
    </>
  );
}
