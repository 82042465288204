export enum Path {
  PRIVACY = "/privacy",
  LOGIN = "/login",
  LOGOUT = "/logout",
  ACTIVATE = "/activate",
  USERS = "/users",
  VEHICLES = "/vehicles",
  RULES = "/rules",
  CARCHECKS = "/carchecks",
  REQUESTS = "/requests",
  REPORTING = "/reporting",
  PARTNERS = "/partners",
  DEPARTMENTS = "/departments",
  COMPANIES = "/companies",
  BASE = "/",
  RESET = "/password_reset",
}

export const existingPath = [
  Path.LOGIN,
  Path.RESET,
  Path.LOGOUT,
  Path.ACTIVATE,
  Path.USERS,
  Path.RULES,
  Path.VEHICLES,
  Path.CARCHECKS,
  Path.BASE,
  Path.REPORTING,
  Path.REQUESTS,
  Path.DEPARTMENTS,
  Path.COMPANIES,
];

export const invalidRedirectionPath = [
  Path.PRIVACY,
  Path.LOGIN,
  Path.LOGOUT,
  Path.ACTIVATE,
  Path.RESET,
];

export const validRedirection = (redirectUrl: string): string => {
  try {
    const redirectBase = `/${redirectUrl.split("/")[1]}`;
    // @ts-ignore
    if (existingPath.indexOf(redirectBase) > -1) {
      // @ts-ignore
      if (!(invalidRedirectionPath.indexOf(redirectBase) > -1)) {
        return redirectUrl;
      }
    }
    return Path.BASE;
  } catch (error) {
    console.log("error happened", redirectUrl);
    return Path.BASE;
  }
};
