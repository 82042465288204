import React from "react";
import { useParams } from "react-router-dom";
import FormView from "../../../Common/Components/FormView";
import { Vehicle } from "../../../Config/datamodels/interfaces";
import useFormViewModel from "../../viewModel/formViewModel";
import { VehicleFormFieldsName } from "../../viewModel/types";
import useValidation from "../FormScreen/useValidation";
import { useTranslation } from "react-i18next";
import {
  requestInterceptor,
  responseInterceptor,
} from "../../viewModel/formInterceptors";

export default function () {
  const { id } = useParams<any>();
  const { t } = useTranslation();
  const formData = useFormViewModel(
    id,
    requestInterceptor,
    responseInterceptor
  );
  const validation = useValidation();
  return (
    <FormView<Vehicle, VehicleFormFieldsName>
      shouldGoBack
      shouldHideReset
      formData={formData}
      validateButtonText={t("Update vehicle")}
      title={t("Update Vehicle")}
      validation={validation}
      description=""
      modelName="vehicles"
    />
  );
}
