// *---- Imports ----
import React, { createContext, useEffect } from "react";
import _ from "lodash";
import i18next from "i18next";
import useAuthViewModel from "../../../auth/viewModel/authViewModel";
import { useTranslation } from "react-i18next";

// *---- Style ----

// *---- Components ----

export class Gate {
  public getSecureTranslation(
    multiLanguageObject: MultiLanguageObject
  ): string {
    // not best practice here but at least it is centralized
    switch (i18next.language) {
      case "fr":
        return multiLanguageObject.name_fr;

      case "nl":
        return multiLanguageObject.name_nl;

      default:
        return multiLanguageObject.name_en;
    }
  }

  public getCustomTranslation(
    field: string,
    object: Record<string, any>
  ): string {
    return _.get(object, `${field}_${i18next.language}`, "field");
  }
}

export const LanguageContext = createContext({ lang: new Gate() });

export interface LanguageGateProps {
  children: JSX.Element | JSX.Element[];
}
export default function LanguageGate<Props extends LanguageGateProps>(
  props: Props
) {
  const { i18n } = useTranslation();
  const { language, setLang } = useAuthViewModel();

  useEffect(() => {
    i18n.changeLanguage(language);
    if (!language) setLang();
    //eslint-disable-next-line
  }, []);

  return (
    <LanguageContext.Provider value={{ lang: new Gate() }}>
      {props.children}
    </LanguageContext.Provider>
  );
}

interface MultiLanguageObject {
  name_en: string;
  name_fr: string;
  name_nl: string;
}
