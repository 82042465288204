import React from "react";
import { Image } from "../../../Config/datamodels/interfaces";
import { useTranslation } from "react-i18next";
import ModalImage from "react-modal-image";

import styles from "./ModalImage.module.css";

export interface ImageProps {
  image: Image;
}

export default function ModalImageComponent<Props extends ImageProps>({
  image,
}: Props) {
  const { t } = useTranslation();
  return (
    <div className={styles.imageContainer}>
      <ModalImage
        showRotate
        className={styles.image}
        alt={t("Vehicle Parts")}
        small={image.small}
        medium={image.medium}
        large={image.image}
      />
    </div>
  );
}
