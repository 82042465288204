import _ from "lodash";
import {
  Carcheck,
  Damage,
  DamageInPart,
  PartWithDamage,
} from "../../Config/datamodels/interfaces";

export interface PartsMap {
  [key: number]: PartWithDamage;
}
export default function addDamagePerPartToCarcheck(
  carcheck: Partial<Carcheck>,
  damages: Partial<Damage>[]
): Carcheck {
  // @ts-ignore
  return { ...carcheck, damage_per_part: damageListToPartList(damages) };
}

function damageListToPartList(damages: Damage[]): PartWithDamage[] {
  let partsMap: PartsMap = {};
  for (let index = 0; index < damages.length; index++) {
    _registerOrUpdatePartInMap(partsMap, damages[index], index);
  }
  return _.values(partsMap);
}

function _registerOrUpdatePartInMap(
  partsMap: PartsMap,
  damage: Damage,
  damageIndex: number
) {
  const part = damage.part;
  let addedDamage = {
    ...damage,
    position: damageIndex,
  } as DamageInPart;
  if (part.id in partsMap) {
    partsMap[part.id]["damages"].push(addedDamage);
  } else {
    partsMap[part.id] = { ...part, damages: [addedDamage] };
  }
}
