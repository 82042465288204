import React from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
export interface ReminderButtonProps {
  onClick: () => void;
}

export default function ReminderButton({ onClick }: ReminderButtonProps) {
  const { t } = useTranslation();
  return (
    <Button onClick={onClick} color="primary">
      {t("Re Send Request")}
    </Button>
  );
}
