import _ from "lodash";
import {
  Damage,
  DamageInPart,
  PartWithDamage,
} from "../../Config/datamodels/interfaces";
import { toBoolean } from "../../Utils/utils";

export interface DataWithDamagePerParts {
  damage_per_part?: PartWithDamage[];
}

const returnZeroIfNaNOrNoneOrUndefined = (cost: any) => {
  if (isNaN(cost)) {
    return 0;
  }
  return cost;
};

const getTotalDamageCost = (
  data: DataWithDamagePerParts,
  carcheckId: number
) => {
  const singlePartOrNone = (partId: number) => {
    const carcheckData = data;
    const partIndex = _.findIndex(
      carcheckData.damage_per_part,
      (part: PartWithDamage) => part.id === partId
    );
    if (partIndex !== -1 && carcheckData.damage_per_part) {
      return carcheckData.damage_per_part[partIndex];
    }
    return null;
  };

  const isDamageRepairedInCurrentCarcheck = (damage: Damage) => {
    if (toBoolean(damage.is_repaired)) {
      return Math.max(...damage.carcheck_presence) === carcheckId;
    }
    return false;
  };

  const getPartMaxNonAcceptableDamageCost = (partId: number) => {
    const part = singlePartOrNone(partId);
    if (part) {
      const totalSum = _.reduce(
        part.damages,
        function (max: number, damage: DamageInPart) {
          if (
            toBoolean(damage.is_acceptable) ||
            isDamageRepairedInCurrentCarcheck(damage)
          ) {
            return max;
          }
          const newCost = returnZeroIfNaNOrNoneOrUndefined(
            parseFloat(_.get(damage, "checkly_cost_estimate", "0"))
          );
          return newCost > max ? newCost : max;
        },
        0
      );
      return _.ceil(totalSum, 2);
    }
    return 0;
  };

  const getPartMaxAcceptableDamageTotal = (partId: number) => {
    const part = singlePartOrNone(partId);
    if (part) {
      const totalSum = _.reduce(
        part.damages,
        function (max: number, damage: DamageInPart) {
          if (
            toBoolean(damage.is_acceptable) &&
            !isDamageRepairedInCurrentCarcheck(damage)
          ) {
            const newCost = returnZeroIfNaNOrNoneOrUndefined(
              parseFloat(_.get(damage, "checkly_cost_estimate", "0"))
            );
            return newCost > max ? newCost : max;
          }
          return max;
        },
        0
      );
      return _.ceil(totalSum, 2);
    }
    return 0;
  };

  const getTotalNonAcceptableDamageCost = () => {
    const carcheckData = data;
    const total = _.reduce(
      carcheckData.damage_per_part,
      function (sum: number, part: PartWithDamage) {
        return sum + getPartMaxNonAcceptableDamageCost(part.id);
      },
      0
    );
    return _.ceil(total, 2);
  };

  const getTotalAcceptableDamageCost = () => {
    const carcheckData = data;
    const total = _.reduce(
      carcheckData.damage_per_part,
      function (sum: number, part: PartWithDamage) {
        return sum + getPartMaxAcceptableDamageTotal(part.id);
      },
      0
    );
    return _.ceil(total, 2);
  };

  return {
    getPartMaxNonAcceptableDamageCost,
    getPartMaxAcceptableDamageTotal,
    getTotalNonAcceptableDamageCost,
    getTotalAcceptableDamageCost,
  };
};

export default getTotalDamageCost;
