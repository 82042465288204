import React from "react";
import FormView from "../../../Common/Components/FormView";
import { useTranslation } from "react-i18next";
import useValidation from "./useValidation";
import useFormViewModel from "../../viewModel/formViewModel";
import { Partner } from "../../../Config/datamodels/interfaces";
import { PartnerFieldName } from "../../viewModel/types";

export default function () {
  const formData = useFormViewModel();
  const { t } = useTranslation();
  const validation = useValidation();
  return (
    <FormView<Partner, PartnerFieldName>
      shouldGoBack
      formData={formData}
      validateButtonText={t("Register Partner")}
      title={t("New Partner")}
      description={t("Create partners that own some vehicles in your fleet")}
      validation={validation}
      modelName={"partners"}
    />
  );
}
