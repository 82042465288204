import React from "react";
import { Modal, ModalBody, ModalHeader, Progress } from "reactstrap";

export interface ImageDownloadProgress {
  isOpen: boolean;
  percentage: string;
  headerTitle: string;
}

export default function ImageDownloadProgress({
  isOpen,
  percentage,
  headerTitle,
}: ImageDownloadProgress) {
  return (
    <>
      <Modal isOpen={isOpen} centered={true} size="lg">
        <ModalHeader>{headerTitle}</ModalHeader>
        <ModalBody>
          <div className="text-center">{`${percentage}%`}</div>
          <Progress value={percentage} />
        </ModalBody>
      </Modal>
    </>
  );
}
