import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import BackArrowIcon from "./BackArrowIcon";

export interface BackButtonProps {}

export default function BackButton() {
  const history = useHistory();
  return (
    <Button onClick={() => history.goBack()} outline>
      <BackArrowIcon />
    </Button>
  );
}
