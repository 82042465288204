// *---- Imports ----
import _ from "lodash";
import React, { useContext, useState } from "react";
import { Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import Section from "./Section";
import SubSection from "./SubSection";
import VehicleSectionContent from "./VehicleSectionContent";
import ParticipationSectionContent from "./ParticipationSectionContent";
import VehiclePicturesContent from "./VehiclePicturesContent";
import VehicleDetailsContent from "./VehicleDetailsContent";
import TyreSectionContent from "./TyreSectionContent";
import { LanguageContext } from "../../../Config/i18n/LanguageGate";
import SingleDamageSectionContent from "./SingleDamageSectionContent";
import InventorySectionContent from "./InventorySectionContent";
import Page from "./Page";
import {
  DamagePerPage,
  GroupedDamage,
} from "../../converters/clusterDamageAndPartsByPage";
import PrintControl from "./PrintControl";
import { Carcheck, DamageType } from "../../../Config/datamodels/interfaces";
import PartSection from "./PartSection";
import getTotalDamageCost from "../../converters/getTotalDamageCost";
import PriceSummary from "./PriceSummary";
import Summary from "./Summary";
import PriceOverview from "./PriceOverview";
import { clusterInventoryPresenceByPositionAndCategory } from "../../converters/clusterInventoryPerPositionAndCategory";

export interface PrintScreenProps {
  documentTitle: string;
  data: Carcheck;
  isLoading: boolean;
  totalPageCount: number;
  perPageDamages: DamagePerPage;
}

export default function PrintScreen(props: PrintScreenProps) {
  // * ---- setup ----
  const { t } = useTranslation();
  const { lang } = useContext(LanguageContext);
  const [displayPrices, setDisplayPrices] = useState<boolean>(false);

  const getPartDamageTitle = (group: GroupedDamage) => {
    const damages = t("Damages");
    const partName = lang.getCustomTranslation("name", group.part);
    let title = `${damages} : ${partName} (${group.damageCount})`;
    if (group.isContinued) {
      title += " " + t("...continuation");
    }
    return title;
  };

  if (props.isLoading || !props.data) {
    return <Spinner type="grow" />;
  }

  const {
    getPartMaxAcceptableDamageTotal,
    getPartMaxNonAcceptableDamageCost,
    getTotalAcceptableDamageCost,
    getTotalNonAcceptableDamageCost,
  } = getTotalDamageCost(props.data, props.data.id);
  // data is an actual carcheck
  return (
    <>
      <PrintControl
        displayPrices={displayPrices}
        setDisplayPrices={setDisplayPrices}
        documentTitle={props.documentTitle}
      />
      {/* First page: sumary of costs */}
      {displayPrices && (
        <Page
          title={props.documentTitle}
          carcheck={props.data}
          index={0}
          total={props.totalPageCount}
        >
          <Section title={t("Summary")}>
            <SubSection>
              <Summary carcheck={props.data} />
            </SubSection>
            <SubSection>
              <PriceOverview
                totalAcceptableCosts={getTotalAcceptableDamageCost()}
                totalNotAcceptableCostst={getTotalNonAcceptableDamageCost()}
              />
            </SubSection>
            <SubSection>
              <PriceSummary
                carcheck={props.data}
                getPartMaxAcceptableDamageTotal={
                  getPartMaxAcceptableDamageTotal
                }
                getPartMaxNonAcceptableDamageCost={
                  getPartMaxNonAcceptableDamageCost
                }
              />
            </SubSection>
          </Section>
        </Page>
      )}
      {/* Second page: general info + participants + vehicle */}
      <Page
        title={props.documentTitle}
        carcheck={props.data}
        index={displayPrices ? 1 : 0}
        total={props.totalPageCount - (displayPrices ? 0 : 1)}
      >
        <Section title={t("Vehicle")}>
          <SubSection>
            <VehicleSectionContent
              vehicle={props.data.vehicle}
              mileage={props.data.mileage}
            />
          </SubSection>
        </Section>
        {props.data.participation_set && props.data.participation_set.length ? (
          <Section
            title={`${t("Participants")} (${
              props.data.participation_set.length
            })`}
          >
            {props.data.participation_set.map((participation) => (
              <SubSection key={participation.user.id}>
                <ParticipationSectionContent participation={participation} />
              </SubSection>
            ))}
          </Section>
        ) : (
          <></>
        )}
        <Section title={t("Vehicle State and Documents")}>
          <SubSection>
            <VehiclePicturesContent carcheck={props.data} />
          </SubSection>
          <SubSection>
            <VehicleDetailsContent carcheck={props.data} />
          </SubSection>
          <SubSection>
            <TyreSectionContent carcheck={props.data} />
          </SubSection>
        </Section>
      </Page>
      {/* 2...n pages: damages */}
      {props.perPageDamages.map((damagePage, pageCount) => (
        <Page
          title={props.documentTitle}
          carcheck={props.data}
          key={pageCount}
          index={(displayPrices ? 2 : 1) + pageCount}
          total={props.totalPageCount - (displayPrices ? 0 : 1)}
        >
          {damagePage.map((damageSection) => (
            <PartSection
              title={getPartDamageTitle(damageSection)}
              key={damageSection.part.id + " " + pageCount}
              acceptableCost={getPartMaxAcceptableDamageTotal(
                damageSection.part.id
              )}
              nonAcceptableCost={getPartMaxNonAcceptableDamageCost(
                damageSection.part.id
              )}
              displayPrices={displayPrices}
            >
              {damageSection.damages.map((damage, index) => (
                <SubSection key={index}>
                  <SingleDamageSectionContent
                    damageType={
                      _.find(
                        damageSection.part.damagetype_set,
                        (damageType) => damageType.type === damage.type
                      ) as DamageType
                    }
                    damage={damage}
                    displayPrices={displayPrices}
                    carcheckId={props.data.id}
                  />
                </SubSection>
              ))}
            </PartSection>
          ))}
        </Page>
      ))}
      {/* n+1 page: Inventory */}
      {props.data.inventorypresence_set &&
      props.data.inventorypresence_set.length ? (
        <Page
          title={props.documentTitle}
          carcheck={props.data}
          index={props.totalPageCount - (displayPrices ? 1 : 2)}
          total={props.totalPageCount - (displayPrices ? 0 : 1)}
        >
          <Section title={t("Inventory")}>
            <InventorySectionContent
              inventory={clusterInventoryPresenceByPositionAndCategory(
                props.data.inventorypresence_set
              )}
            />
          </Section>
        </Page>
      ) : null}
    </>
  );
}
