// *---- Imports ----
import React, { PropsWithChildren } from "react";

// *---- Style ----
import styles from "./CardSeparator.module.css";
// *---- Components ----

export interface ComponentProps {
  children: string;
}
export default function index({ children }: PropsWithChildren<{}>) {
  return <div className={styles.title}>{children}</div>;
}
