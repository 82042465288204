import _ from "lodash";
import { useEffect, useMemo } from "react";

const useMousePositionTracker = (
  callback: (ev: any) => void,
  deps: any[],
  throttle: number = 100
) => {
  const memoCallback = useMemo(() => _.throttle(callback, throttle), [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...deps,
    callback,
    throttle,
  ]);

  useEffect(() => {
    document.addEventListener("mousemove", memoCallback);
    document.addEventListener("mousedown", memoCallback);
    document.addEventListener("mouseup", memoCallback);
    return () => {
      document.removeEventListener("mousemove", memoCallback);
      document.removeEventListener("mousedown", memoCallback);
      document.removeEventListener("mouseup", memoCallback);
    };
  }, [memoCallback]);
};

export default useMousePositionTracker;
