import * as Sentry from "@sentry/react";
import _ from "lodash";

export default function (axios: any) {
  axios.interceptors.request.use(
    function (config: any) {
      Sentry.setContext("axios-request", formatRequest(config));
      return config;
    },
    function (error: Error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response: any) {
      Sentry.setContext("axios-response", formatResponse(response));
      return response;
    },
    function (error: any) {
      Sentry.setContext("axios-response", formatResponse(error.response));
      if (_.get(error, "response.status") === 500) {
        Sentry.captureException(error);
      }
      return Promise.reject(error);
    }
  );
}

function formatRequest(config: any) {
  const { method, headers, data, url } = config;
  return {
    url,
    method,
    headers: JSON.stringify(headers[method]),
    data: data ? JSON.stringify(data) : data,
  };
}

function formatResponse(error: any) {
  const { data, headers, statusText, status } = error;
  return {
    headers,
    statusText,
    status,
    data: data ? JSON.stringify(data) : data,
  };
}
