import React from "react";
import { Table } from "reactstrap";
import { Model } from "../../../../Config/datamodels/interfaces";
import styles from "./ListBody.module.css";

interface ListBodyProps<ModelType extends Model> {
  headers: string[];
  data: ModelType[];
  renderLine: (data: ModelType) => JSX.Element;
}

export default function ListBody<ModelType extends Model>(
  props: ListBodyProps<ModelType>
) {
  const { headers, data, renderLine } = props;
  return (
    <Table className={styles.list}>
      <thead>
        <tr className={styles.header + " align-middle"}>
          {headers.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody className={styles.body}>
        {data.map((item, index) => (
          <React.Fragment key={"" + index}>
            {React.createElement(renderLine, { ...item })}
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  );
}
