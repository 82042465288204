import React from "react";
import style from "./TyreCarWidget.module.css";
import carOutline from "./car-outline.png";
import SingleTyreWidget from "./SingleTyreWidget";
import { TyreState } from "../../../../Config/datamodels/interfaces";

export interface TyreCarWidgetProps {
  frontLeftState: TyreState;
  frontRightState: TyreState;
  backLeftState: TyreState;
  backRightState: TyreState;
}

const OUTLINE_HEIGHT = 150;
const OUTLINE_IMAGE_RATIO = 1.99;
const OUTLINE_MARGINS = OUTLINE_HEIGHT * 0.2;
const RIGHT_STATE_TOP_OFFSET = 0;
const LEFT_STATE_DOWN_OFFSET = 0;
const FRONT_STATE_LEFT_OFFSET = OUTLINE_HEIGHT * OUTLINE_IMAGE_RATIO * 0.2;
const BACK_STATE_RIGHT_OFFSET = OUTLINE_HEIGHT * OUTLINE_IMAGE_RATIO * 0.2;

export default function TyreCarWidget(props: TyreCarWidgetProps) {
  const carMargins = `${OUTLINE_MARGINS}px ${OUTLINE_MARGINS}px`;

  return (
    <div className={style.wrapper} style={{ height: OUTLINE_HEIGHT + 56 }}>
      <div className={style.carWrapper} style={{ padding: carMargins }}>
        <div className={style.state}>
          <SingleTyreWidget
            anchor={{
              top: RIGHT_STATE_TOP_OFFSET,
              left: FRONT_STATE_LEFT_OFFSET,
            }}
            state={props.frontRightState}
          />
          <SingleTyreWidget
            anchor={{
              top: RIGHT_STATE_TOP_OFFSET,
              right: BACK_STATE_RIGHT_OFFSET,
            }}
            state={props.backRightState}
          />
        </div>
        <img
          src={carOutline}
          style={{ height: OUTLINE_HEIGHT }}
          alt="vehicle outline"
        />
        <div className={style.state}>
          <SingleTyreWidget
            anchor={{
              bottom: LEFT_STATE_DOWN_OFFSET,
              left: FRONT_STATE_LEFT_OFFSET,
            }}
            state={props.frontLeftState}
          />
          <SingleTyreWidget
            anchor={{
              bottom: LEFT_STATE_DOWN_OFFSET,
              right: BACK_STATE_RIGHT_OFFSET,
            }}
            state={props.backLeftState}
          />
        </div>
      </div>
    </div>
  );
}
